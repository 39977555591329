import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ShellComponent} from './shell/shell.component';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {Action, ActionReducer, StoreModule} from '@ngrx/store';

import {reducer} from './store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import Swal from 'sweetalert2';
import {AuthInterceptor} from './core/auth.interceptor';
import {AuthContainer} from './routes/user/auth/auth.container';
import {LoginRoute} from './routes/user/auth/login/login.route';
import {RegistrationRoute} from './routes/user/auth/registration/registration.route';
import {EffectsModule} from '@ngrx/effects';
import {UserService} from './features/user/user.service';
import {MatButtonModule} from '@angular/material/button';
import {StoreEffects} from './store/effects';
import {NameRoute} from './routes/user/auth/registration/name/name.route';
import {PinRoute} from './routes/user/auth/registration/pin/pin.route';
import {TelephoneIdentityRoute} from './routes/user/auth/registration/telephone-identity/telephone-identity.route';
import {ConfirmationCodeRoute} from './routes/user/auth/registration/confimation-code/confirmation-code.route';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {RegistrationSuccessfulRoute} from './routes/user/auth/registration/registration-successful/registration-successful.route';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {ClinicsModule} from './features/clinics/clinics.module';
import {NotificationsModule} from './features/notifications/notifications.module';
import {UserModule} from './features/user/user.module';
import {CallModule} from './features/chat-and-call/call/call.module';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {CodeInputModule} from 'angular-code-input';
import * as moment from 'moment';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { OnboardingChildrenComponent } from './routes/user/onboarding-children/onboarding-children.component';
import { AskInviteRoute } from './routes/user/onboarding-children/ask-invite/ask-invite.route';
import { ChildrenContentRoute } from './routes/user/onboarding-children/children-content/children-content.route';
import { InlineSVGModule } from 'ng-inline-svg';
import { paymentReducer } from './store';
import { TermsOfServiceComponent } from './routes/user/auth/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './routes/user/auth/privacy-policy/privacy-policy.component';
import { ForgotPinComponent } from './routes/user/auth/forgot-pin/forgot-pin.component';
import { InvitationCodeRoute } from './routes/user/auth/registration/invitation-code/invitation-code.route';
import { MatRadioModule } from '@angular/material/radio';
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {AppConstants} from './app.constants';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import {OnlyNumbersModule} from "./shared/directives/only-numbers/only-numbers.directive.module";

const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

function getFromStorage() {
  const result: { [key: string]: string } = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    result[key] = localStorage.getItem(key);
  }

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    result[key] = sessionStorage.getItem(key);
  }
  return result;
}

// function saveToStorage(store: Object) {
//   const storage = getFromStorage();
//   if (storage['auth'] !== store['state']['authToken']) {
//     localStorage.setItem('auth', store['state']['authToken']);
//   }
// }

export function metaReducer<S, A extends Action = Action>(reducer: ActionReducer<any, A>): ActionReducer<any, A> {
  let onInit = true;
  return function (state: S, action: A) {
    const nextState = reducer(state, action);
    let newState: S;
    if (onInit) {
      onInit = false;
      const localStorageData = getFromStorage();
      newState = {
        ...nextState,
        state: {
          ...nextState.state,
          authToken: localStorageData.auth || '',
          lastHeartbeat: +localStorageData.last_heartbeat || '',
        }
      };

      if (nextState.user) {
        newState = {
          ...nextState,
          user: {
            ...nextState.user,
            questionnaireSkipLastDate: localStorageData.questionnaireSkipLastDate || ''
          }
        }
      }
    } else {
      const currentTimeStamp = moment().valueOf();
      newState = {
        ...nextState,
        lastHeartbeat: currentTimeStamp
      }
      localStorage.setItem('last_heartbeat', currentTimeStamp.toString(10));
    }

    // saveToStorage(resultState);

    return newState || nextState;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    AuthContainer,
    LoginRoute,
    RegistrationRoute,
    NameRoute,
    PinRoute,
    TelephoneIdentityRoute,
    ConfirmationCodeRoute,
    RegistrationSuccessfulRoute,
    OnboardingChildrenComponent,
    AskInviteRoute,
    ChildrenContentRoute,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    ForgotPinComponent,
    InvitationCodeRoute
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: AppConstants.API_KEY,
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule,
        StoreModule.forRoot({state: reducer, payment: paymentReducer}, {
            metaReducers: [metaReducer]
        }),
        EffectsModule.forRoot([StoreEffects]),
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        UserModule,
        HttpClientModule,
        SweetAlert2Module.forRoot({
            provideSwal: Swal.mixin({
                heightAuto: false,
                showClass: {
                    popup: 'animated zoomInSmall fastest'
                },
                hideClass: {
                    popup: 'animated animate__zoomOut fastest'
                },
                reverseButtons: true,
                customClass: {
                    confirmButton: 'confirm-popup-button',
                    cancelButton: 'cancel-popup-button',
                    title: 'popup-title',
                    content: 'popup-text'
                }
            })
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        }),
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        ClinicsModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp({
            apiKey: 'AIzaSyANJbG3u8gcVv6JBmuihYsru3RVEDdMdlM',
            authDomain: 'appotek-7ac89.firebaseapp.com',
            databaseURL: 'https://appotek-7ac89.firebaseio.com',
            projectId: 'appotek-7ac89',
            storageBucket: 'appotek-7ac89.appspot.com',
            messagingSenderId: '57597666984',
            appId: '1:57597666984:web:693aa48cab703f041e849d'
        }),
        NotificationsModule,
        CallModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        CodeInputModule,
        MatProgressBarModule,
        InlineSVGModule, MatRadioModule,
        WebBluetoothModule.forRoot({
            enableTracing: false
        }), OnlyNumbersModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
