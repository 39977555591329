import { Pipe, PipeTransform } from '@angular/core';
import {AddressModel} from '../../store/model/address.model';
import {formatAddress} from '../helpers';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {

  transform(value: AddressModel): any {
    return formatAddress(value);
  }

}
