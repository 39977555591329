import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'showTitlesFromArray'
})
export class ShowTitlesFromArrayPipe implements PipeTransform {

  transform(
    array: any[], otherArray: any[], prop: string,
    refresh?: any, prop2?: string, selected: boolean = false
  ): string {
    let result = '';

    if ((!array) || (otherArray && array && otherArray.length === array.length)) {
      result = '';
    } else {
      if (selected) {
        if (array.every((i) => i.selected)) {
          result = '';
        } else {
          array.forEach(item => {
            if (item.selected) {
              result += ` ${item[prop]}; `;
            }
          });
          result = result.slice(0, -2);
        }

      } else {
        array.forEach(item => {
          result += `${item[prop]}`;
          if (prop2) {
            result += ` ${item[prop2]}`;
          }

          result+= '; '
        });

        result = result.slice(0, -2);
      }

    }

    return result;
  }

}
