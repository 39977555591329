import {Action} from '@ngrx/store';
import {CountryModel} from '../model/country.model';
import {ActionCreator, NotAllowedCheck, TypedAction} from '@ngrx/store/src/models';

export enum StateActionTypes {
  LoadState = '[State] Load State',
  AuthTokenChanged = '[User API] Authorization access token change',
  LoginNeeded = '[Side effect] Show Login popup',
  LoginNeededNew = '[Side effect NEW] Show Login popup',
  TestAction = '[Test] Test action',
  SetInCallStatus = '[Call websocket service] set in call status',
  UpdateRegistrationToken = '[Angular Fire Messaging Request Token] Update device push registration token',


  LoadAvailableCountries = '[Cloud request] Load countries available',
  LoadAvailableCountriesSuccess = '[Cloud request API] Load countries available success',
  LoadAvailableCountriesFailure = '[Cloud request API] Load countries available failure',

  StartHeartbeat = '[State] Start Heartbeat',
  StopHeartbeat = '[State] Stop Heartbeat',

  OpenSessionPopup = '[Session service] Open session popup',
  KeepSession = '[App Component] Keep session popup',
}


export class LoadState implements Action {
  readonly type = StateActionTypes.LoadState;
}

export class AuthTokenChanged implements Action {
  readonly type = StateActionTypes.AuthTokenChanged;

  constructor(public payload: {
    token: string
  }) {
  }
}

export class LoginNeeded implements Action {
  readonly type = StateActionTypes.LoginNeeded;

  constructor(public payload: {
    action: any,
    payload: any
  }) {
  }
}

export class LoginNeededNew<A extends string, P extends object> implements Action {
  readonly type = StateActionTypes.LoginNeededNew;

  constructor(public payload: {
    action: ActionCreator<A, () => TypedAction<A>> | ActionCreator<A, (props: P & NotAllowedCheck<P>) => P & TypedAction<A>>,
    payload: P
  }) {
  }
}

export class TestAction implements Action {
  readonly type = StateActionTypes.TestAction;

  constructor(public payload: { text: string }) {
  }
}

export class SetInCallStatus implements Action {
  readonly type = StateActionTypes.SetInCallStatus;

  constructor(public payload: {
    inCall: boolean,
  }) {
  }
}

export class UpdateRegistrationToken implements Action {
  readonly type = StateActionTypes.UpdateRegistrationToken;

  constructor(public payload: {
    registrationToken: string,
  }) {
  }
}

export class LoadAvailableCountries implements Action {
  readonly type = StateActionTypes.LoadAvailableCountries;

  constructor() {
  }
}

export class LoadAvailableCountriesSuccess implements Action {
  readonly type = StateActionTypes.LoadAvailableCountriesSuccess;

  constructor(public payload: {
    countries: CountryModel[]
  }) {
  }
}

export class LoadAvailableCountriesFailure implements Action {
  readonly type = StateActionTypes.LoadAvailableCountriesFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class StartHeartbeat implements Action {
  readonly type = StateActionTypes.StartHeartbeat;

  constructor() {
  }
}

export class StopHeartbeat implements Action {
  readonly type = StateActionTypes.StopHeartbeat;

  constructor() {
  }
}


export class OpenSessionPopup implements Action {
  readonly type = StateActionTypes.OpenSessionPopup;

  constructor() {
  }
}

export class KeepSession implements Action {
  readonly type = StateActionTypes.KeepSession;

  constructor() {
  }
}


export type StateActions =
  LoadState |
  LoginNeeded |
  SetInCallStatus |
  TestAction |
  UpdateRegistrationToken |
  LoadAvailableCountries |
  LoadAvailableCountriesSuccess |
  LoadAvailableCountriesFailure |
  StartHeartbeat |
  StopHeartbeat |
  OpenSessionPopup |
  KeepSession |
  AuthTokenChanged |
  LoginNeededNew<string, object>
  ;
