import { HttpWrapperCollection } from '../../store/model/http';
import {AttachmentModel} from '../attachment/store/models/attachment.model';
import {Injectable,EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpDataWrapper, HttpWrapper, HttpWrapperArray} from '../../store/model/http';
import {AppConstants} from '../../app.constants';

import * as fromReducers from 'src/app/store/reducers';
import {select, Store} from '@ngrx/store';
import {LoginResponse} from '../../store/model/login.response';
import {RegistrationInitResponse} from './store/models/registration-init.response';
import { of, Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ProfileEditModel, UserModel} from './store/models/user.model';
import { IInviteInfo } from './store/models/abstract-user.model';
import { SuccessResponse } from 'src/app/store/model/success.response';
import { SecurityQuestionModel } from 'src/app/store/model/notification.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userLoggedIn$ = new EventEmitter<UserModel>();

  constructor(private api: HttpClient, private store: Store<fromReducers.State>) {
  }

  public login(params: {
    role: string,
    appType: string,
    deviceId: string,
    login: string,
    password: string
  }) {
    return this.api.post<HttpDataWrapper<LoginResponse>>(`${AppConstants.baseUrl}/auth/login`, params).pipe(tap(response => {
      this.userLoggedIn$.emit(response.data.profile);
    }));
  }

  public logout() {
    return this.api.post(`${AppConstants.baseUrl}/logout`, {});
  }

  public initRegistrationSession(params: {
    telephone: string,
    role: string,
    country: string,
    invite_id?: any
  }) {
    return this.api.post<HttpDataWrapper<RegistrationInitResponse>>(`${AppConstants.baseUrl}/auth/initialize`, params);
  }

  public checkPhoneRegistration(params: {
    session_id: string,
    code: string
  }) {
    return this.api.post<HttpDataWrapper<any>>(`${AppConstants.baseUrl}/cloud/users/session-code`, params);
  }

  public finalizeRegistrationSession(params: {
    firstName: string,
    lastName: string,
    gender: string,
    dob: string,
    enableBiometricSecurity: boolean,
    password: string,
    sId: string,
    code: number,
    appType: string,
    deviceId: string,
  }) {
    // return of(false);
    return this.api.post<HttpDataWrapper<LoginResponse>>(`${AppConstants.baseUrl}/auth/finalize`, params);
  }

  public userProfile() {
    return this.api.get<HttpWrapper<UserModel>>(`${AppConstants.baseUrl}/api/v3/profile`);
  }

  public updateUserProfile(data) {
    return this.api.patch<HttpWrapper<ProfileEditModel>>(`${AppConstants.baseUrl}/api/v3/profile`, data);
  }

  public uploadAvatar(file: File): Observable<AttachmentModel> {
    const data = new FormData();
    data.append('file', file);
    return this.api.post<AttachmentModel>(`${AppConstants.baseUrl}/api/v3/profile/avatar`, data);
  }

  public fetchUserByIds(params: {
    ids: string[]
  }) {
    return this.api.post<HttpWrapperArray<UserModel>>(`${AppConstants.baseUrl}/cloud/users/minified`, params);
  }

  public fetchUserById(params: {
    userId: string
  }) {
    return this.api.get<HttpWrapper<UserModel>>(`${AppConstants.baseUrl}/cloud/users/${params.userId}`);
  }

  getContacts(params: {
    state: [{
      in_list: string[]
    }]
  }) {
    return this.api.post<HttpWrapperArray<UserModel>>(`${AppConstants.baseUrl}/cloud/contacts`, params);
  }

  public checkInvintationCode(params) {
    return this.api.post<HttpWrapper<IInviteInfo>>(`${AppConstants.baseUrl}/cloud/invites/patient/verify_code`, params);
  }

  public changePass(pass: string) {
    const options = {password: pass};
    return this.api.patch(`${AppConstants.baseUrl}/api/v3/profile/password`, options);
  }

  public sendConfirmCode(params:{
    where_to_send: string
  }) {
    return this.api.post<HttpWrapper<SuccessResponse>>(`${AppConstants.baseUrl}/cloud/users/confirmation`, params);
  }
  public verifyConfirmCode(params) {
    return this.api.post<HttpWrapper<SuccessResponse>>(`${AppConstants.baseUrl}/cloud/users/confirmation/verify_code`, params);
  }

  public restorePin(params: {
    email?: string,
    telephone?: string,
    qId: number,
    answer: string,
    role: string
  }) {
    return this.api.post<SuccessResponse>(`${AppConstants.baseUrl}/restore/credentials`, params);
  }

  public getRecoveryQuestions() {
    return this.api.get<HttpWrapperCollection<SecurityQuestionModel>>(`${AppConstants.baseUrl}/api/v3/recovery-questions?pageSize=${Number.MAX_SAFE_INTEGER}`);
  }

  public getUserRecoveryQuestion() {
    return this.api.get<HttpWrapper<SecurityQuestionModel>>(`${AppConstants.baseUrl}/api/v3/profile/recovery_question`);
  }

  public recoveryQuestion(questionId: number, answer: string) {
    const options = {questionId, answer};
    return this.api.post(`${AppConstants.baseUrl}/api/v3/profile/recovery_question`, options);
  }
}
