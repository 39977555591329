import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromSelectors from 'src/app/features/user/store/selectors';
import {ActivatedRoute, Router} from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'atk-registration-successful',
  templateUrl: './registration-successful.route.html',
  styleUrls: ['./registration-successful.route.scss']
})
export class RegistrationSuccessfulRoute implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  constructor(private router: Router,private store: Store, private route:ActivatedRoute) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  public skipClick(): void {
    this.router.navigate(['get-help', 'booking']).then();
  }

  public getStartedClick(): void {
    this.goToQuestionary();
  }

  private goToQuestionary(): void {
    this.store.pipe(takeUntil(this.destroy$), select(fromSelectors.userProfile), first()).subscribe(userProfile => {
      if (userProfile.need_to_complete_questionnare) {
        this.router.navigate(['user', 'questionnaire']);
      } else {
        this.router.navigate(['']).then();
      }
    });
  }
}
