import {Injectable} from '@angular/core';
import * as fromReducers from 'src/app/store/reducers';
import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import {select, Store} from '@ngrx/store';
import {WebsocketService} from '../../core/websocket.service';
import {AppConstants} from '../../app.constants';
import {first, map, skip, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ChatRoomModel} from './chat/store/models/chat-room.model';
import {ChatMessageModel, ChatMessageRead} from '../../store/model/chat-message.model';
import {FetchUsersByIds} from '../user/store/actions';
import {ChatLocationAttachmentModel} from '../../store/model/chat-location-attachment.model';
import {distinct} from '../../shared/helpers';
import { ChatInviteModel } from './chat/store/models/chat-invite.model';

@Injectable({
  providedIn: 'root'
})
export class ChatAndCallWebsocketService extends WebsocketService {


  constructor(
    private store: Store<fromReducers.State>
  ) {
    super(AppConstants.wssMessenger);
  }

  auth(token: string) {
    super.sendToken({token: `Bearer ${token}`})
  }
  

  onInvites() {
    return this.on<ChatInviteModel[]>(MESSENGER_TYPES.get_invites).pipe(
      switchMap(invites => {
        if (invites.length > 0) {
          this.store.dispatch(new FetchUsersByIds({
            ids: invites.map(invite => invite.invited_by as string)
          }))
          return this.store.pipe(
            select(fromUserSelectors.usersByIds),
            skip(1),
            first(),
            map(users => {
              const invitesWithUser = invites.map(item => {
                const foundedUserIndex = users.findIndex(user => user.id === item.invited_by);
                if (foundedUserIndex >= 0) {
                  item.interlocutor = {
                    ...item.interlocutor,
                    ...users[foundedUserIndex]
                  };
                }
                return item;
              })
            })
          )
        } else {
          return of(invites);
        }
      })
    );
  }

  onGetRoom() {
    return this.on<ChatRoomModel[]>(MESSENGER_TYPES.get_rooms).pipe(
      switchMap(rooms => {
        if (rooms.length > 0) {
          const ids = [];
          rooms.forEach(room => {
            if (room.type === 'dialog' && room.interlocutor && !ids.includes(room.interlocutor.id)) {
              ids.push(room.interlocutor.id);
            }
            if (room.type === 'group' && room.participants && room.participants.length > 0) {
              room.participants.forEach(participant => {
                if (!ids.includes(participant.id)) {
                  ids.push(participant.id);
                }
              });
            }
          });
          this.store.dispatch(new FetchUsersByIds({
            ids: ids
          }));
          return this.store.pipe(
            select(fromUserSelectors.usersByIds),
            skip(1),
            first(),
            map(users => {
              const filteredRooms = rooms
                .filter(room => room.status !== 'leaved')
                .map(room => ({
                    ...room,
                    id: parseInt(room.id.toString())
                  })
                );

                return filteredRooms.map(room => {
                  if (room.type === 'dialog') {
                    if (!room.interlocutor){
                      return null;
                    }
                    return {
                      ...room,
                      interlocutor: {
                        ...room.interlocutor,
                        ...users.filter(user => user.id === room.interlocutor.id)[0]
                      }
                    } as ChatRoomModel;
                  }
                  if (room.type === 'group') {
                    return {
                      ...room,
                      participants: room.participants.map(participant => ({
                        ...participant,
                        ...users.filter(user => user.id === participant.id)[0]
                      }))
                    };
                  }
                }).filter(item => !!(item));
            })
          )
        } else {
          return of(rooms);
        }
      })
    )
  }

  onGetMessages() {
    return this.on<ChatMessageModel[]>(MESSENGER_TYPES.get_messages).pipe(
      switchMap(messages => {
        return this.formatMessages(messages)
      })
    );
  }

  onCreateDialog() {
    return this.on<{ room_id: number }>(MESSENGER_TYPES.create_dialog);
  }

  onCreateGroup() {
    return this.on<{ room_id: number }>(MESSENGER_TYPES.create_group);
  }

  onLeaveGroup() {
    return this.on<{ room_id: number }>(MESSENGER_TYPES.leave_group);
  }

  onMessagePublished() {
    return this.on<ChatMessageModel>(MESSENGER_TYPES.message_published).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }

  onMessageRead() {
    return this.on<ChatMessageRead>(MESSENGER_TYPES.message_read);
  }

  onMessageUpdated() {
    return this.on<ChatMessageModel>(MESSENGER_TYPES.message_updated).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }

  onMessageDeleted() {
    return this.on<ChatMessageModel>(MESSENGER_TYPES.message_deleted).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }

  getRooms(params: {
    room_id?: number
  }) {
    this.send(MESSENGER_TYPES.get_rooms, params);
  }

  getMessages(params: {
    room_id: number,
    page: number,
    count: number,
  }) {
    this.send(MESSENGER_TYPES.get_messages, params);
  }

  sendMessage(params: {
    room_id: number,
    text: string
  }) {
    this.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'text'
    });
  }

  sendAttachmentMessage(params: {
    room_id: number,
    shared_attachment_id: string
  }) {
    this.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'file_attachment'
    });
  }

  sendLocationMessage(params: {
    room_id: number,
    location: ChatLocationAttachmentModel
  }) {
    this.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'location_attachment'
    });
  }

  sendContactMessage(params: {
    room_id: number,
    shared_contact_id: string
  }) {
    this.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'contact_attachment'
    });
  }

  readMessage(params: {
    room_id: number,
    message_id: number
  }) {
    this.send(MESSENGER_TYPES.read_message, params);
  }

  updateMessage(params: {
    id: number,
    room_id: number,
    text: string
  }) {
    this.send(MESSENGER_TYPES.update_message, params);
  }

  deleteMessage(params: {
    id: number,
    room_id: number,
  }) {
    this.send(MESSENGER_TYPES.delete_message, params);
  }

  createDialog(params: {
    participants: string[]
  }) {
    this.send(MESSENGER_TYPES.create_dialog, params);
  }

  private formatMessages(messages: ChatMessageModel[]) {
    messages = messages.map(message => ({
        ...message,
        id: parseInt(message.id.toString(10), 10),
        room_id: parseInt(message.room_id.toString(10), 10)
      })
    );

    const userIds = messages.map(item => item.created_by);
    const contactMessageUserIds = messages
      .filter(item => item.type === 'contact_attachment')
      .map(item => item.shared_contact_id);
    userIds.push(...contactMessageUserIds);
    this.store.dispatch(new FetchUsersByIds({
      ids: distinct(userIds)
    }));
    return this.store.pipe(
      select(fromUserSelectors.usersByIds),
      skip(1),
      first(),
      map(users => {
        messages = messages.map(message => {
          const foundedUserIndex = users.findIndex(item => item.id === message.created_by);
          if (foundedUserIndex >= 0) {
            message.created_user = users[foundedUserIndex];
          }
          if (message.type === 'contact_attachment') {
            const foundedContactUserIndex = users.findIndex(item => item.id === message.shared_contact_id);
            if (foundedContactUserIndex >= 0) {
              message.shared_contact = users[foundedUserIndex]
            }
          }
          return message;
        })
        return messages;
      })
    );
  }
}

// const CALL_TYPES = {
//   ignored_call: 'ignored_call',
//   incoming_call: 'incoming_call',
//   cancelled_call: 'cancelled_call',
// };

const MESSENGER_TYPES = {
  connection: 'connection',
  get_rooms: 'get_rooms',
  get_messages: 'get_messages',
  get_invites: 'get_invites',
  accept_invite: 'accept_invite',
  decline_invite: 'decline_invite',
  invite_feedback: 'invite_feedback',
  send_message: 'send_message',
  update_message: 'update_message',
  delete_message: 'delete_message',
  message_published: 'message_published',
  message_updated: 'message_updated',
  message_deleted: 'message_deleted',
  create_dialog: 'create_dialog',
  create_group: 'create_group',
  room_created: 'room_created',
  room_invited: 'room_invited',
  leave_group: 'leave_group',
  update_room_info: 'update_room_info',
  message_read: 'message_read',
  read_message: 'read_message'
};
