import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class HeightChartBuilder extends ChartsBuilder {

  public static chartName = 'Height';
  public static measurementUnits = 'm';
  public static axisNames: string[] = ['Height'];

  constructor(element: ElementRef) {
    super(element);
    super.yAxisTickInterval = 0.01;
    super.yLabelFormatter = (input: string) => {
      return input + 'm';
    }
  }

  init() {
    return super.initChart('stock');
  }
}
