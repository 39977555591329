import {NotificationsActions, NotificationsActionTypes} from '../actions';
import {UserModel} from '../../../user/store/models/user.model';
import {NotificationMessageModel} from '../models/notifications/notification-message.model';
import {NotificationCancelledCallModel} from '../models/notifications/notification-cancelled-call.model';
import {NotificationSystemModel} from '../models/notifications/notification-system.model';


export const notificationsFeatureKey = 'notifications';

export interface NotificationsState {
  notificationQueue: (
    NotificationMessageModel |
    NotificationCancelledCallModel |
    NotificationSystemModel
    )[],
  notificationLastId: number,

  showCallAlerPopup: {
    show: boolean,
    user: UserModel,
    video: boolean,
    roomId: string,
    roomType: string,
    callerId: string,
  },
  showCallPopup: {
    show: boolean,
    user: UserModel,
    video: boolean,
    roomId: string,
  }
}

export const initialState: NotificationsState = {
  notificationQueue: [],
  notificationLastId: 0,

  showCallPopup: null,
  showCallAlerPopup: null,
};


export function reducer(state = initialState, action: NotificationsActions): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.AddNotificationToQueue: {
      const notificationLastId = state.notificationLastId + 1;
      return {
        ...state,
        notificationQueue: [...state.notificationQueue, {
          ...action.payload,
          id: notificationLastId
        }],
        notificationLastId: notificationLastId
      }
    }

    case NotificationsActionTypes.RemoveNotificationFromQueue: {
      return {
        ...state,
        notificationQueue: state.notificationQueue.filter(item => item.id !== action.payload.messageId)
      }
    }

    case NotificationsActionTypes.SetShowIncomingCallPopup: {
      return {
        ...state,
        showCallPopup: action.payload.showCallPopup
      }
    }

    case NotificationsActionTypes.SetShowIncomingCallAlert: {
      return {
        ...state,
        showCallAlerPopup: action.payload.showCallAlerPopup
      }
    }

    default:
      return state;
  }
}
