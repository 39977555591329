import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AbstractUserModel} from '../../../features/user/store/models/abstract-user.model';
import {MatButton} from '@angular/material/button';

import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import {select, Store} from '@ngrx/store';
import {filter, skip, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'atk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: AbstractUserModel;
  destroy$ = new Subject<boolean>();

  @ViewChild('btnToFocus', {static: false}) btnToFocus: ElementRef<MatButton>;

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.store.pipe(
      select(fromUserSelectors.userProfile),
      filter(user => !!(user)),
      takeUntil(this.destroy$)
    ).subscribe(user => {
      if (user) {
        this.user = user;
        this.ref.markForCheck();
      }
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
