import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpWrapper, HttpWrapperArray} from '../store/model/http';
import {IdResponse} from '../store/model/id.response';
import {AppConstants} from '../app.constants';
import {CountryModel} from '../store/model/country.model';

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  constructor(private api: HttpClient) {
  }

  getAvailableCountries() {
    const params = {
      launched: 'true'
    }
    return this.api.get<HttpWrapperArray<CountryModel>>(`${AppConstants.baseUrl}/cloud/countries`, {params});
  }

  public getCountries(){
    return this.api.get<HttpWrapperArray<CountryModel>>(`${AppConstants.baseUrl}/cloud/countries`);
  }
}
