import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromSelectors from 'src/app/features/user/store/selectors';
import {Subscription} from 'rxjs';

@Component({
  selector: 'atk-registration',
  templateUrl: './registration.route.html',
  styleUrls: ['./registration.route.scss']
})
export class RegistrationRoute implements OnInit, OnDestroy {

  registrationSuccessful: boolean
  sub: Subscription;

  constructor(
    private store: Store
  ) {
    this.sub = new Subscription();
  }

  ngOnInit() {
    this.store.pipe(select(fromSelectors.registrationSuccess)).subscribe(next => {
      this.registrationSuccessful = next;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
