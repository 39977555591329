import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { fromEvent, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CloudService } from 'src/app/core/cloud.service';
import { states } from 'src/app/core/data/states.data';
import { PaymentCardPostModel } from 'src/app/core/models/payment.model';
import { PaymentService } from 'src/app/core/services/payment.service';
import { UiService } from 'src/app/core/services/ui.service';
import { CustomValidators } from 'src/app/core/validators/custom.validators';
import { DateValidtors } from 'src/app/core/validators/date.validators';
import { ICountryExtended } from 'src/app/store/model/country.model';

@Component({
  selector: 'atk-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCardComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public readonly canClose: boolean = true;

  @ViewChild('ccNumber', { static: false }) ccNumberField: ElementRef;
  @ViewChild('expirationDate', { static: false }) expirationDateField: ElementRef;
  @ViewChild('cvcNumber', { static: false }) cvcNumberField: ElementRef;

  @ViewChild('cityInput', { static: false })
  private readonly cityInput: ElementRef;

  @ViewChild('addressInput', { static: false })
  private readonly addressInput: ElementRef;

  public errorMessage = '';

  public countries: ICountryExtended[];
  public selectedCountry: ICountryExtended;

  public regions = [];
  public selectedRegion: string;

  public cardFormGroup: FormGroup;

  public readonly expirationDateConfig = {
    mask: [/[0-1]/, /\d/, '/', /\d/, /\d/],
    guide: false,
    keepCharPositions: true
  };

  private readonly destroyer$: Subject<void> = new Subject();

  constructor(
    private readonly cloudService: CloudService,
    private readonly paymentService: PaymentService,
    private readonly uiService: UiService,
    private readonly dialogRef: MatDialogRef<AddCardComponent>,
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setupCardForm();
    this.getCounties();
  }

  ngAfterViewInit(): void {
    this.setupInputListener();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private setupCardForm(): void {
    this.cardFormGroup = new FormGroup({
      card: new FormControl('', [
        Validators.required, Validators.pattern('^[ 0-9]*$'),
        Validators.minLength(16)
      ]),
      cvc: new FormControl('', [
        Validators.required, Validators.minLength(3),
        CustomValidators.validateNumber
      ]),
      owner_name: new FormControl('', Validators.required),
      exp_date: new FormControl('', [Validators.required, DateValidtors.dateValidator()]),
      address_country: new FormControl('', Validators.required),
      address_city: new FormControl('', Validators.required),
      address_zip: new FormControl('', Validators.required),
      address_line1: new FormControl('', Validators.required)
    });
  }

  private setupInputListener(): void {
    const city = this.cityInput.nativeElement;
    const address = this.addressInput.nativeElement;

    fromEvent(city, 'input')
      .pipe(takeUntil(this.destroyer$))
      .subscribe((x: any) => this.getFC('address_city').patchValue(x.target.value));

    fromEvent(address, 'input')
      .pipe(takeUntil(this.destroyer$))
      .subscribe((x: any) => this.getFC('address_line1').patchValue(x.target.value));
  }


  private getCounties(): void {
    this.cloudService.getAvailableCountries()
      .pipe(takeUntil(this.destroyer$))
      .subscribe((countries: any) => this.countries = countries.data);
  }

  public getFC(controlName: string): AbstractControl {
    return this.cardFormGroup.controls[controlName];
  }

  public selectCountry(country: ICountryExtended): void {
    this.selectedCountry = country;
    this.getFC('address_country').patchValue(this.selectedCountry.name);

    this.getRegions(country.iso3Code);
  }

  public selectRegion(region: string): void {
    this.selectedRegion = region;
  }

  public onStreetAutocompleteSelected(result): void {
    this.getFC('address_line1').patchValue(result.name);
  }

  public onCityAutocompleteSelected(result): void {
    this.getFC('address_city').patchValue(result.name);
  }

  private getRegions(countryCode): void {
    const currentCoutrieStates = states.find(element => element.countrieCode === countryCode);
    if (currentCoutrieStates) {
      this.regions = currentCoutrieStates.regions;
    } else {
      this.regions = [];
      this.selectedRegion = null;
    }
  }

  public creditCardSpacing(): void {
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const { card } = this.cardFormGroup.controls;

    let trimmedCardNum = card.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
      this.expirationDateField.nativeElement.focus();
    }

    /* Handle American Express 4-6-5 spacing */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
      ? [4, 6, 5]
      : [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    card.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < card.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }

  private get preparedFormValue(): PaymentCardPostModel {
    const formValue = this.cardFormGroup.value;

    let expMonth;
    let expYear;

    [expMonth, expYear] = formValue.exp_date.split('/');

    return {
      ...formValue,
      is_default: true,
      card: formValue.card.replace(/\s/g, ''),
      cvc: Number(formValue.cvc),
      exp_month: Number(expMonth),
      exp_year: 2000 + Number(expYear)
    };
  }

  public saveCard(): void {
    const config: MatSnackBarConfig = { data: { text: 'Card was successfully added' } };
    this.uiService.setProgressBar();

    this.paymentService.createCard({...this.preparedFormValue})
      .pipe(finalize(() => this.uiService.removeProgressBar()),takeUntil(this.destroyer$)).subscribe({
      next: () => (this.uiService.fireSnackBar(config), this.closeCard(true)),
      error: (error) => (this.errorMessage = error.error.error_message, this.cd.detectChanges())
    });
  }

  public closeCard(result: boolean): void {
    this.dialogRef.close(result);
  }

}
