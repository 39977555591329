import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {ScheduleModel} from '../../store/model/schedule.model';

@Pipe({
  name: 'schedule'
})
export class SchedulePipe implements PipeTransform {

  transform(value: ScheduleModel[], start: boolean): any {
    if (!value || value.length === 0) {
      return 'Closed';
    }

    const currentTimeStart = moment.parseZone(`1970-01-01T${value[0].time_to}`);
    const currentDate = moment();
    currentDate.utcOffset(currentTimeStart.utcOffset());
    const currentWeekDay = currentDate.isoWeekday();

    const foundedDay = value.findIndex(item => item.day_of_week === currentWeekDay);
    if (foundedDay < 0) {
      return 'Closed';
    }
    const timeFrom = moment(`1970-01-01T${value[foundedDay].time_from}`);
    const timeTo = moment(`1970-01-01T${value[foundedDay].time_to}`);
    if (start) {
      return timeFrom.format('HH:mm');
    } else {
      if (timeTo.isAfter(timeFrom, 'day')) {
        return 'Next day at ' + timeTo.format('HH:mm');
      } else {
        return timeTo.format('HH:mm');
      }
    }
  }

}
