import {ElementRef, EventEmitter} from '@angular/core';
import * as Highcharts from 'highcharts';
import {RangeSelectorOptions, SeriesZonesOptionsObject} from 'highcharts';
import * as moment from 'moment';

import HC_stock from 'highcharts/modules/stock';
import HC_breaks from 'highcharts/modules/broken-axis';
import HC_boost from 'highcharts/modules/boost';
import {ChartDataModel} from '../models/chart-data.model';

HC_stock(Highcharts);
HC_breaks(Highcharts);
HC_boost(Highcharts);

export class ChartsBuilder {

  public static chartName = 'Unknown type';
  public static measurementUnits = 'N/A';
  public static axisNames: string[] = ['Unknown'];
  protected readonly dataGroupingType: Highcharts.DataGroupingApproximationValue = 'average';

  protected chart: Highcharts.Chart;
  protected readonly chartConfig: Highcharts.Options
  private readonly element: ElementRef;

  protected data: {
    x: number,
    y: number
  }[][] = [];

  dataUpdated = new EventEmitter<{
    x: number,
    y: number
  }[][]>(true);

  protected valueSuffix = '';

  _pointClickHandler: (x: number, y: number, serieIndex: number) => void;
  _yLabelFormatter: (input: string) => string;

  breaksAllowed: boolean;

  dataGroupingUnits: Array<[string, (Array<number> | null)]> = [];

  constructor(element: ElementRef) {
    this.element = element;
    const context = this;
    this.chartConfig = {
      title: {
        text: ''
      },
      rangeSelector: {
        allButtonsEnabled: true,
        buttons: [{
          type: 'hour',
          count: 1,
          text: 'Hour',
          dataGrouping: {
            units: [['minutes', [1]]]
          }
        }, {
          type: 'day',
          count: 1,
          text: 'Day',
          dataGrouping: {
            units: [['hour', [1]]]
          }
        }, {
          type: 'week',
          count: 1,
          text: 'Week',
          dataGrouping: {
            units: [['day', [1, 2, 3, 4, 5, 6, 7]], ['hour', [6, 9, 12]]]
          }
        }, {
          type: 'month',
          count: 1,
          text: 'Month',
          dataGrouping: {
            units: [['week', [1]], ['day', [1, 2, 3, 4, 5, 6, 7]]]
          }
        }, {
          type: 'year',
          count: 1,
          text: 'Year',
          dataGrouping: {
            units: [['month', [1]], ['week', [1, 2, 3, 4]], ['day', [1, 2, 3, 4, 5, 6, 7]]]
          }
        }, {
          type: 'all',
          text: 'All',
          dataGrouping: {
            units: [['month', [1]]]
          }
        }],
        buttonTheme: {
          width: 45
        },
        selected: 2
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          color: '#42a3ed',
          zones: [],
          marker: {
            enabled: true
          }
        },
        areaspline: {
          color: '#42a3ed',
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              {0: 0, 1: '#42A3ED4F'},
              {0: 1, 1: '#42a3ed00'}
            ],
          },
          marker: {
            radius: 3
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: this.valueSuffix,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        gridLineWidth: 1,
        gridLineColor: '#f3f3f3',
        lineColor: '#f3f3f3',
        tickColor: '#f3f3f3',
        labels: {
          rotation: 0,
          style: {
            color: '#848484',
            fontSize: '14px'
          },
          x: 0,
          y: 30,
          reserveSpace: true,
        },

        tickPixelInterval: 73,
        // tickLength: 15,
      },
      yAxis: {
        opposite: false,
        gridLineWidth: 1,
        gridLineColor: '#f3f3f3',
        tickInterval: 1,
        title: {
          text: ''
        },
        plotLines: [{
          value: 0,
          width: 1,
          color: '#f3f3f3'
        }],
        labels: {

          formatter: function () {
            if (context._yLabelFormatter) {
              return context._yLabelFormatter(this.value.toString(10))
            } else {
              return (+this.value).toFixed(2);
            }
          },
          style: {
            color: '#848484',
            fontSize: '14px'
          },
        },
        lineWidth: 1,
        lineColor: '#f3f3f3',
        tickColor: '#f3f3f3',
        tickWidth: 1,
        tickLength: 5
      },
      series: []
    };
  }

  isInit() {
    return !!(this.chart);
  }

  init(): Promise<Highcharts.Chart> {
    return Promise.resolve<Highcharts.Chart>(null);
  }

  // Math.round(parseFloat(input) * 100) / 100
  protected initChart(chartType: 'default' | 'stock') {
    return new Promise<Highcharts.Chart>(resolve => {
      switch (chartType) {
        case 'stock':
          Highcharts.stockChart(this.element.nativeElement, this.chartConfig, (newChart) => {
            this.chart = newChart;
            resolve(newChart);
          });
          break;
        case 'default':
        default:
          new Highcharts.Chart(this.element.nativeElement, this.chartConfig, (newChart) => {
            this.chart = newChart;
            resolve(newChart);
          });
          break;
      }
    })


    // this.chart.init();
  }

  protected updateChart() {
    if (this.chart) {
      this.chart.update(this.chartConfig);
    }
  }

  private getPoints(points: {
    x: number,
    y: number
  }[]): number[][] {
    return points
      .sort((a, b) => a.x > b.x ? 1 : a.x < b.x ? -1 : 0)
      .map(item => [item.x, item.y]);
    // return points.map((item) => {
    //   return {
    //     x: item.date,
    //     y: item.value,
    //     marker: {
    //       enabled: item.showMarker,
    //       states: {
    //         hover: {
    //           enabled: item.showMarker,
    //         },
    //         select: {
    //           enabled: item.showMarker,
    //         }
    //       },
    //       symbol: item.showMarker ? 'circle' : null
    //     },
    //     events: {
    //       click(event: Event): boolean {
    //         context.internalPointClick(event['point'].x, event['point'].y, event['point'].series.index);
    //         return true
    //       }
    //     }
    //   } as any as Highcharts.Point;
    // });
  }

  set yMin(yMin: number) {
    (this.chartConfig.yAxis as Highcharts.AxisOptions).min = yMin;
    this.updateChart()
  }

  set yMax(yMax: number) {
    (this.chartConfig.yAxis as Highcharts.AxisOptions).max = yMax;
    this.updateChart()
  }

  setTimeFrame(start: moment.Moment, end: moment.Moment) {
    (this.chartConfig.xAxis as Highcharts.AxisOptions).min = start.utc(true).valueOf();
    (this.chartConfig.xAxis as Highcharts.AxisOptions).max = end.utc(true).valueOf();
    this.updateChart();
  }

  // resetTimeFrame() {
  //   (this.chartConfig.xAxis as Highcharts.AxisOptions).min = null;
  //   (this.chartConfig.xAxis as Highcharts.AxisOptions).max = null;
  // }

  protected addZones(zone: Highcharts.SeriesZonesOptionsObject) {
    this.chartConfig.plotOptions.spline.zones.push(zone);
  }

  private prepareData(monitoringChartData: ChartDataModel[]): { x: number, y: number }[] {
    if (monitoringChartData['data']) {
      return monitoringChartData['data'].map(data => {
        let value;
        if (data.rows[0]?.hasOwnProperty('slot_price')) {
          value = data.rows[0] ? parseFloat(data.rows[0].slot_price) : 0
        } else {
          value = data.rows[0] ? parseFloat(data.rows[0].total_amount) : 0
        }
        return {
          x: moment(data.period).valueOf(),
          y: value
        };
      })
    } else {
      return monitoringChartData.map(data => {
        // const date = moment(data.date);
        // const plainDate = Date.UTC(date.year(), date.month(), date.date(),
        // date.hours(), date.minutes(), date.seconds(), date.milliseconds())
        return {
          x: !!(data.date) && data.date.length > 0 ? moment(data.date).valueOf() : data.dateMilliseconds,
          y: data.value
        };
      })
    }

  }

  addSeries(
    chartData: ChartDataModel[],
    name: string,
    type: 'spline' | 'areaspline' = 'spline',
    breakOptions: {
      interval: number
    } = null,
    seriesZoneColor: Array<SeriesZonesOptionsObject> = null) {
    if (name === 'Ecg') {
      const Ecgdata = [];
      const Newecgdata = [];
      chartData.map(dataC => {
        dataC.value.map(item => (Ecgdata.push({date: dataC.date, value: item})));
      });

      Ecgdata.map((item, index) => {
        (Newecgdata.push({date: index + 1, value: item.value}))
      });

      chartData = Newecgdata;
    }
    const data = this.prepareData(chartData)

    this.pushDataSerie(data);

    if (!this.chart) {
      return;
    }

    this.chart.addSeries({
      gapSize: breakOptions ? 60000 : 0,
      gapUnit: breakOptions ? 'value' : null,
      data: this.getPoints(data),
      type,
      color: '#42a3ed',
      name,
      zones: seriesZoneColor || null,
      dataGrouping: {
        groupPixelWidth: 10,
        approximation: this.dataGroupingType,
        // units: this.dataGroupingUnits
      }
    });
    if (breakOptions) {
      this.setXBreaks(true, breakOptions.interval);
    }
    // (this.chartConfig.xAxis as Highcharts.AxisOptions).min = chartData[0].date;
    // (this.chartConfig.xAxis as Highcharts.AxisOptions).max = chartData[chartData.length - 1].date
  }


  addPoints(points: ChartDataModel[], series: number = 0) {
    if (!this.chart) {
      return;
    }

    const max = Math.max(...points.map(item => item.value));
    const min = Math.min(...points.map(item => item.value));

    if ((this.chartConfig.yAxis as Highcharts.AxisOptions).min > min) {
      this.yMin = min
    } else if ((this.chartConfig.yAxis as Highcharts.AxisOptions).max < max) {
      this.yMax = max
    }
    const chartData = this.prepareData(points);
    this.pushDataForSerie(series, chartData);
    (this.chartConfig.xAxis as Highcharts.AxisOptions).max = chartData[chartData.length - 1].x
    const dataPoints = this.getPoints(chartData);
    dataPoints.forEach(point => {
      // console.log('point', point);
      this.chart.series[series].addPoint(point);
    })
  }

  removeSerie(serie: number = 0) {
    this.chart.series[serie].remove();
    // this.chart.removeSerie(serie);
    this.data.splice(serie, 1);
    this.dataUpdated.emit(this.data);
  }

  set yAxisTickInterval(tickInterval: number) {
    (this.chartConfig.yAxis as Highcharts.AxisOptions).tickInterval = tickInterval;
  }

  set pointClickHandler(pointClickHandler: (x: number, y: number, serieIndex: number) => void) {
    this._pointClickHandler = pointClickHandler;
  }

  private internalPointClick(x: number, y: number, serieIndex: number): boolean {
    if (this._pointClickHandler) {
      this._pointClickHandler(x, y, serieIndex);
    }
    return true
  }

  set yLabelFormatter(formatter: (input: string) => string) {
    this._yLabelFormatter = formatter;
  }

  get seriesCount() {
    return this.data.length;
  }

  get dataCount() {
    return this.data.length > 0 ? this.data[0].length : 0;
  }

  // updateLastValue(newValue: number, serie: number) {
  //   const firstSerieData = this.chart.series[serie].data;
  //   firstSerieData[firstSerieData.length - 1].update(newValue);
  // }


  setXBreaks(breaks: boolean, interval: number) {
    this.breaksAllowed = breaks;
    this.prepareXBreaks(interval);
  }

  setYBreaks(breaks: boolean, interval: number) {
    this.breaksAllowed = breaks;
    this.prepareYBreaks(interval);
  }

  prepareXBreaks(interval: number) {
    if (this.breaksAllowed && this.data.length > 0) {
      (this.chartConfig.xAxis as Highcharts.XAxisOptions).breaks = this.getXBreaks(this.data[0], interval)
      this.chart.update({
        xAxis: this.chartConfig.xAxis
      });
    }
  }

  prepareYBreaks(interval: number) {
    if (this.breaksAllowed && this.data.length > 0) {
      (this.chartConfig.yAxis as Highcharts.YAxisOptions).breaks = this.getYBreaks(this.data[0], interval)
      this.chart.update({
        yAxis: this.chartConfig.yAxis
      });
    }
  }


  private getXBreaks(data: {
    x: number,
    y: number
  }[], breakLength: number) {
    const breaks: Highcharts.XAxisBreaksOptions[] = []
    for (let i = 1; i < data.length; i++) {
      const interval = (data[i].x - data[i - 1].x);
      if (interval > breakLength) {
        breaks.push({
          breakSize: interval,
          from: data[i - 1].x,
          to: data[i].x
        })
      }
    }
    console.log(breaks);
    return breaks
  }

  private getYBreaks(data: {
    x: number,
    y: number
  }[], breakLength: number) {
    const breaks: Highcharts.YAxisBreaksOptions[] = []
    for (let i = 1; i < data.length; i++) {
      const interval = (data[i].y - data[i - 1].y);
      if (interval > breakLength) {
        breaks.push({
          breakSize: interval,
          from: data[i - 1].y,
          to: data[i].y
        })
      }
    }
    console.log(breaks);
    return breaks
  }

  setSimpleHighstockGraph() {
    this.chartConfig.navigator = {enabled: false};
    this.chartConfig.scrollbar = {enabled: false};
    this.chartConfig.rangeSelector = {enabled: false};
    this.updateChart();
  }

  setDataGroupingUnits(dataGroupingUnits: Array<[string, (Array<number> | null)]>) {
    this.dataGroupingUnits = dataGroupingUnits;
  }

  disableMarkers() {
    this.chartConfig.plotOptions.spline.marker.enabled = false;
    this.chartConfig.plotOptions.areaspline.marker.enabled = false;
    this.updateChart();
  }

  setXAxisType(type: 'datetime' | 'linear' = 'datetime') {
    if (type === 'datetime') {
      (this.chartConfig.xAxis as Highcharts.XAxisOptions).type = 'datetime';
      (this.chartConfig.xAxis as Highcharts.XAxisOptions).dateTimeLabelFormats = {
        month: '%e. %b',
        year: '%b'
      }
    } else {
      (this.chartConfig.xAxis as Highcharts.XAxisOptions).type = 'linear';
      (this.chartConfig.xAxis as Highcharts.XAxisOptions).dateTimeLabelFormats = null;
    }
  }

  pushDataSerie(data: { x: number, y: number }[] = []) {
    this.data.push(data);
    this.dataUpdated.emit(this.data);
  }

  pushDataForSerie(index: number, data: { x: number, y: number }[] = []) {
    this.data[index].push(...data);
    this.dataUpdated.emit(this.data);
  }

  redraw(animation: boolean = true) {
    if (!this.chart) {
      return;
    }
    this.chart.redraw(animation);
  }

  setBoost(boost: boolean = false) {
    if (boost) {
      this.chartConfig.boost = {
        enabled: true,
        allowForce: true
      }
    } else {
      this.chartConfig.boost = null;
    }
  }

  setPlotAnimation(animate: boolean = true) {
    this.chartConfig.plotOptions.spline.animation = animate;
  }


  setRangeOptions(options: RangeSelectorOptions) {
    this.chartConfig.rangeSelector = options;
  }

  setNavigatorOptions(options: Highcharts.NavigatorOptions) {
    this.chartConfig.navigator = options;
  }

  setScrollbarOptions(options: Highcharts.ScrollbarOptions) {
    this.chartConfig.scrollbar = options;
  }

  setPopoverOptions(options: Highcharts.TooltipOptions) {
    this.chartConfig.tooltip = options;
  }


  setXAxis(xAxis: Highcharts.XAxisOptions) {
    this.chartConfig.xAxis = xAxis;
  }

  setYAxisLabelEnabled(enabled: boolean = true) {
    (this.chartConfig.yAxis as Highcharts.YAxisOptions).labels.enabled = enabled;
  }

  resizeChart(width: number, height: number){
    this.chart.update({
      chart:{
        width,
        height
      }
    });
  }
}




