import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {AppService} from '../../../../app.service';
import {ClinicsService} from '../../clinics.service';
import {
  AddFavouriteClinic,
  AddFavouriteClinicFailure,
  AddFavouriteClinicSuccess,
  AddFavouritePharmacy,
  AddFavouritePharmacyFailure,
  AddFavouritePharmacySuccess,
  ClinicsActionTypes,
  FetchMedicalSpecializations,
  FetchMedicalSpecializationsSuccess,
  FetchProfessionals, FetchProfessionalsFailure,
  FetchProfessionalsSuccess,
  FetchDoctors, FetchDoctorsFailure,
  FetchDoctorsSuccess,
  LoadClinics,
  LoadClinicsFailure,
  LoadClinicsSuccess,
  LoadPharmacies,
  LoadPharmaciesFailure,
  LoadPharmaciesSuccess
} from '../actions';


@Injectable()
export class ClinicsEffects {


  loadClinicsList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadClinics>(ClinicsActionTypes.LoadClinics),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.loadClinics(payload).pipe(
        map(response => new LoadClinicsSuccess({clinicsList: response.data})),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, LoadClinics, payload, () => {
            return of(new LoadClinicsFailure(error.error));
          })
        })
      )
    )
  ));


  loadPharmaciesList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPharmacies>(ClinicsActionTypes.LoadPharmacies),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.loadPharmacies(payload).pipe(
        map(response => new LoadPharmaciesSuccess({pharmaciesList: response.data})),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, LoadPharmacies, payload, () => {
            return of(new LoadPharmaciesFailure(error.error));
          })
        })
      )
    )
  ));


  addFavouriteClinic$ = createEffect(() => this.actions$.pipe(
    ofType<AddFavouriteClinic>(ClinicsActionTypes.AddFavouriteClinic),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.addFavouriteClinic(payload).pipe(
        map(() => new AddFavouriteClinicSuccess()),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, AddFavouriteClinic, payload, () => {
            return of(new AddFavouriteClinicFailure(error.error));
          })
        })
      )
    )
  ));


  addFavouritePharmacy$ = createEffect(() => this.actions$.pipe(
    ofType<AddFavouritePharmacy>(ClinicsActionTypes.AddFavouritePharmacy),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.addFavouritePharmacy(payload).pipe(
        map(() => new AddFavouritePharmacySuccess()),
        catchError((error: HttpErrorResponse) =>
          this.appService.catchUnauthorized(
            error,
            AddFavouritePharmacy,
            payload,
            () => of(new AddFavouritePharmacyFailure(error.error))
          )
        )
      )
    )
  ));


  getMedicalSpecializations$ = createEffect(() => this.actions$.pipe(
    ofType<FetchMedicalSpecializations>(ClinicsActionTypes.FetchMedicalSpecializations),
    exhaustMap(() =>
      this.clinicsService.getMedicalSpecializations().pipe(
        map(response => new FetchMedicalSpecializationsSuccess({
          medicalSpecialization: response.data
        })),
        catchError((error: HttpErrorResponse) =>
          this.appService.catchUnauthorized(error, FetchMedicalSpecializations, {}, () =>
            of(new AddFavouritePharmacyFailure(error.error))
          )
        )
      )
    )
  ));


  getProfessionals$ = createEffect(() => this.actions$.pipe(
    ofType<FetchProfessionals>(ClinicsActionTypes.FetchProfessionals),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.getProfessionals(payload).pipe(
        map(response => new FetchProfessionalsSuccess({
          professionals: response.data
        })),
        catchError((error: HttpErrorResponse) =>
          this.appService.catchUnauthorized(error, FetchProfessionals, payload, () =>
            of(new FetchProfessionalsFailure(error.error))
          )
        )
      )
    )
  ));

  getFilterDoctors$ = createEffect(() => this.actions$.pipe(
    ofType<FetchDoctors>(ClinicsActionTypes.FetchDoctors),
    map(action => action.payload),
    exhaustMap(payload =>
      this.clinicsService.getFilterDoctorList(payload).pipe(
        map(response => new FetchDoctorsSuccess({filterDoctors: response.data})),
        catchError((error: HttpErrorResponse) =>
          this.appService.catchUnauthorized(error, FetchDoctors, payload, () =>
            of(new FetchDoctorsFailure(error.error))
          )
        )
      )
    )
  ));


  constructor(private actions$: Actions,
              private clinicsService: ClinicsService,
              private appService: AppService) {
  }

}
