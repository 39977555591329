import {Injectable, OnDestroy} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {CancelCall, EndCall, InitCall, SetCallRoomId, InitCallSuccess} from './store/actions';
import {DefaultProjectorFn, select, Store} from '@ngrx/store';
import {BehaviorSubject, interval, Observable, ReplaySubject, Subject, Subscription, timer} from 'rxjs';
import * as fromCallSelectors from './store/selectors/call.selectors';
import {concatMap, filter, skip, switchMap, take, takeUntil} from 'rxjs/operators';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {Call} from 'src/app/features/chat-and-call/call/lib/call/call';
import * as moment from 'moment';
import { CallApiService } from './call-api.service';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import {connect, LocalAudioTrack, LocalVideoTrack, createLocalAudioTrack} from "twilio-video";
import {EndCallSuccess} from './store/actions';

@Injectable({
  providedIn: 'root'
})
export class TwilioCallService implements OnDestroy {

  private destroy$ = new Subject<boolean>()
  private call;
  private callRoomId: string;
  private callTimerSecondsStart: number;
  private callTimerSubscription: Subscription;
  private waitForRemoteAnswerSubscription: Subscription;
  private dialingSound: HTMLAudioElement;

  private callTimer = new BehaviorSubject<string>('');
  private statusCall = new BehaviorSubject<'incoming' | 'started' | 'call' | 'idle'>('idle');
  private localStream = new BehaviorSubject<LocalAudioTrack | LocalVideoTrack>(null);
  private remoteStream = new BehaviorSubject<LocalAudioTrack | LocalVideoTrack>(null);
  private showLocalVideo = new BehaviorSubject<boolean>(false);
  private isCameraEnabled = new BehaviorSubject<boolean>(false);
  private isMicrophoneMuted = new BehaviorSubject<boolean>(false);
  private isScreenSharingEnabled = new BehaviorSubject<boolean>(false);

  private isCameraExist = new BehaviorSubject<boolean>(false);
  private isMicExist = new BehaviorSubject<boolean>(false);
  private isPermissionGranted = new BehaviorSubject<boolean>(true);
  private onRemoteCameraOn = new BehaviorSubject<boolean>(false);

  POLLING_INTERVAL = 3000;
  timerCtrl$ = new BehaviorSubject<number>(0);
  close$ = new ReplaySubject<any>(1);

  public callTimer$ = this.callTimer.asObservable();
  public statusCall$ = this.statusCall.asObservable();
  public localStream$ = this.localStream.asObservable();
  public remoteStream$ = this.remoteStream.asObservable();
  public showLocalVideo$ = this.showLocalVideo.asObservable();
  public isCameraEnabled$ = this.isCameraEnabled.asObservable();
  public isMicrophoneMuted$ = this.isMicrophoneMuted.asObservable();
  public isScreenSharingEnabled$ = this.isScreenSharingEnabled.asObservable();


  public isCameraExist$ = this.isCameraExist.asObservable();
  public isMicExist$ = this.isMicExist.asObservable();
  public isPermissionGranted$ = this.isPermissionGranted.asObservable();
  public onRemoteCameraOn$ = this.onRemoteCameraOn.asObservable();


  private isMicGranted: boolean;
  private isCameraGranted: boolean;

  private manuallyAllowMicrophone = new BehaviorSubject<boolean>(false);
  private manuallyAllowCamera = new BehaviorSubject<boolean>(false);
  public manuallyAllowMicrophone$ = this.manuallyAllowMicrophone.asObservable();
  public manuallyAllowCamera$ = this.manuallyAllowCamera.asObservable();
  // public addLocalMediaStreamTest = new BehaviorSubject<MediaStream>(null);

  room = null;

  constructor(
    private store: Store,
    private callDatabase: CallApiService,
    private router:Router,
    private route: ActivatedRoute
  ) {
    let callerId;
    this.router.events.subscribe(value => {
      if (value instanceof ActivationEnd) {
        callerId  = value.snapshot.queryParams['callerId'];
      }
    });
    this.pipeStore(fromCallSelectors.callSettings).pipe(skip(1)).subscribe(callSettings => {
      if (!callSettings) {
        return;
      }
      this.callRoomId = callSettings.roomId;
      if (this.callRoomId) {
        this.statusCall.next('started');
        // logic to wait for patient
        if (this.route.snapshot.queryParams.roomType === 'booking' || this.route.snapshot.queryParams.roomType === 'open queue') {
          const doctorId = this.router.url.split(/[/?]+/)[2];
          this.callDatabase.requestToDoctorAllow({room_id: parseInt(this.callRoomId, 10), doctor_id:doctorId})
            .subscribe(() => {
              // start
              this.timerCtrl$.asObservable().pipe(
                switchMap((time: number) =>
                  timer(time, this.POLLING_INTERVAL).pipe(
                    concatMap(() => this.callDatabase.checkRequestStatus({room_id: parseInt(this.callRoomId, 10), doctor_id:doctorId})),
                  )), takeUntil(this.close$)).subscribe({
                next: (reqestStatus) => {
                  if (reqestStatus['data'].status === 'accepted') {
                    this.close$.next(0);
                    this.receiveCall(this.route.snapshot.queryParams.callerId
                      ? this.route.snapshot.queryParams.callerId : callerId);
                    this.stopCallingSound();
                  } else if (reqestStatus['data'].status === 'declined') {
                    this.stopCallingSound();
                    this.close$.next(0);
                    this.store.dispatch(new CancelCall({
                      roomId: this.route.snapshot.queryParams.callerId ? this.route.snapshot.queryParams.callerId : callerId
                    }));
                    // this.store.dispatch(action);
                    // location.href = 'get-help/booking';
                    location.href = 'calls/' + doctorId + '?video=false' + '&roomId='+ this.callRoomId
                      + '&roomType=' + this.route.snapshot.queryParams.roomType;
                  }
                }, error: error => { alert(error.error.message)}
              });
              // end
            }, error => { alert(error.error.error_message)});

        } else if (this.route.snapshot.queryParams.roomType === 'free') {
          const doctorId = this.router.url.split(/[/?]+/)[2];
          this.callDatabase.requestEventToDoctor({event_id: +callerId, doctor_id: doctorId})
            .subscribe(() => {
              this.timerCtrl$.asObservable().pipe(
                switchMap((time: number) =>
                  timer(time, this.POLLING_INTERVAL).pipe(
                    concatMap(() => this.callDatabase.checkCurrentStatus({event_id: +callerId,doctor_id: doctorId})),
                  )), takeUntil(this.close$)).subscribe({
                next: (reqestStatus) => {
                  if (reqestStatus['data'].status === 'accepted') {
                    this.close$.next(0);
                    this.receiveCall(this.callRoomId);
                    this.stopCallingSound();
                  } else if (reqestStatus['data'].status === 'declined') {
                    this.stopCallingSound();
                    this.close$.next(0);
                    this.store.dispatch(new CancelCall({roomId: this.callRoomId}));
                    location.href = 'calls/' + doctorId + '?video=false' + '&roomId='+ this.callRoomId + '&roomType='
                      + this.route.snapshot.queryParams.roomType;
                    // location.href = 'get-help/booking';
                  }
                }, error: error => { alert(error.error.message)}
              });
            });
        } else {
          if (this.route.snapshot.queryParams['receive']) {
            // this.callDatabase.receiveCall({roomId: this.callRoomId}).subscribe(receiveResult => {
            //   this.makeCall(callSettings);
            // });
          } else if (this.route.snapshot.queryParams['start']) {
            // this.makeCall(callSettings);
          }
        }
      }
    });

    this.pipeStore(fromCallSelectors.callEnded).pipe(skip(1)).subscribe(callEnded => {
      if (callEnded) {
        this.clearCall();
      }
    })
  }

  ngOnDestroy() {
    this.endCall();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  connectToTwilioCall(token, roomName, videoCall, receiving) {
    this.statusCall.next('started');
    this.showLocalVideo.next(videoCall);
    this.isCameraEnabled.next(videoCall);

    connect(token, { name: roomName, video: true }).then(room => {
      this.room = room;

      this.room.localParticipant.tracks.forEach(publication => {
        this.localStream.next(publication.track);
      });

      if (!receiving) {
        this.setCallType(videoCall);
      }

      this.room.participants.forEach(participant => {
        this.participantConnected(participant, receiving);
      });

      this.room.on('participantConnected', participant => {
        this.participantConnected(participant, receiving);
      });

      this.room.on('participantDisconnected', participant => {
        this.participantDisconnected(participant);
      });

      this.room.on('disconnected', room => {
        room.localParticipant.tracks.forEach(publication => {
          publication.track.stop();
          publication.unpublish();
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => {
            element.remove();
          });
        });
      });
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  participantConnected(participant, receiving) {
    this.stopCallingSound();
    this.statusCall.next('call');
    this.startCallTimer();

    createLocalAudioTrack().then(localAudioTrack => {
      return this.room.localParticipant.publishTrack(localAudioTrack);
    }).then(publication => {
      this.localStream.next(publication.track);
    });

    participant.tracks.forEach(publication => {
      if (publication.track) {
        this.remoteStream.next(publication.track);
        if (receiving && publication.track.kind === 'video') {
          this.setCallType(publication.track.isEnabled);
        }
      }
      if (publication.isSubscribed) {
        this.handleTrack(publication.track);
      }
      publication.on('subscribed', this.handleTrack.bind(this));
    });

    participant.on('trackSubscribed', track => {
      this.remoteStream.next(track);
      if (receiving && track.kind === 'video') {
        this.setCallType(track.isEnabled);
      }
    });
  }

  setCallType(type) {
    if (!type) {
      this.room.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    }
    this.onRemoteCameraOn.next(type);
    this.showLocalVideo.next(type);
    this.isCameraEnabled.next(type);
  }

  participantDisconnected(participant) {
    this.endCall();
    this.router.navigate(['chat']).then();
  }

  handleTrack(track) {
    track.on('disabled', () => {
      if (track.kind === 'video') {
        this.onRemoteCameraOn.next(false);
      }
    });
    track.on('enabled', () => {
      if (track.kind === 'video') {
        this.onRemoteCameraOn.next(true);
      }
    });
  }

  private pipeStore<T>(selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>): Observable<T> {
    return this.store.pipe(select(selector), takeUntil(this.destroy$));
  }

  askPermission(isVideo: boolean) {
    return navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInput = devices.find(item => item.kind === 'videoinput');
      this.isCameraExist.next(!!(videoInput));
      const audioInput = devices.find(item => item.kind === 'audioinput');
      this.isMicExist.next(!!(audioInput));

      console.log('isMicExist', videoInput, audioInput)

      const mediaConstraints = {
        audio: true
      } as MediaStreamConstraints;

      if (isVideo) {
        mediaConstraints.video = {facingMode: 'user'};
      }

      return navigator.mediaDevices.getUserMedia(mediaConstraints).then(mediaStream => {
        return mediaStream;
      }).catch(error => {
        this.isPermissionGranted.next(false);
        return Promise.reject(error);
      });
    });
  }

  public startCall(callerId: string, calleeId: string, videoCall: boolean) {
    const params = {
      participants: [calleeId],
      type: videoCall ? 'video' : 'audio'
    };
    this.callDatabase.startCall(params).subscribe(response => {
      this.playCallingSound();

      const token = response['data'].token;
      const roomName = response['data'].roomName;
      this.connectToTwilioCall(token, roomName, videoCall, false);
    });
  }

  public receiveCall(callRoomId: string) {
    this.callDatabase.joinCall({id: callRoomId}).subscribe(response => {
      const token = response['data'].token;
      const roomName = response['data'].roomName;
      this.connectToTwilioCall(token, roomName, false, true);
    });
  }

  public endCall() {
    if (this.waitForRemoteAnswerSubscription && !this.waitForRemoteAnswerSubscription.closed) {
      this.waitForRemoteAnswerSubscription.unsubscribe();
    }

    if (this.statusCall.getValue() === 'started') {
      this.callDatabase.cancelCALL({id: this.room.name}).subscribe();
    }

    this.clearCall();
    this.room.disconnect();
    this.store.dispatch(new EndCallSuccess({response: {}}));
  }

  private startCallTimer() {
    this.callTimerSecondsStart = moment().unix();
    this.callTimerSubscription = interval(1000).subscribe(() => {
      let secondsElapsed = moment().unix() - this.callTimerSecondsStart;
      const hours = Math.floor(secondsElapsed / 3600);
      secondsElapsed %= 3600;
      const minutes = Math.floor(secondsElapsed / 60);
      secondsElapsed %= 60;

      let hoursString = ('0' + hours);
      hoursString = hoursString.slice(-(Math.max(2, hoursString.length - 1)));
      const minutesString = ('0' + minutes).slice(-2);
      const secondsString = ('0' + secondsElapsed).slice(-2);
      this.callTimer.next(`${hoursString}:${minutesString}:${secondsString}`)
    });
  }

  private stopCallTimer() {
    if (this.callTimerSubscription && !this.callTimerSubscription.closed) {
      this.callTimerSubscription.unsubscribe();
    }
  }

  private playCallingSound() {
    this.dialingSound = new Audio('assets/audio/call-tone.mp3');
    this.dialingSound.addEventListener('canplaythrough', (event) => {
      this.dialingSound.play().then();
    });
    this.dialingSound.loop = true;
  }

  private stopCallingSound() {
    if (this.dialingSound) {
      this.dialingSound.pause();
    }
  }

  toggleScreenShare() {
    if (!this.call || (this.statusCall.getValue() !== 'started' && this.statusCall.getValue() !== 'call')) {
      return;
    }
    const isScreenSharingEnabled = !this.isScreenSharingEnabled.getValue();

    if (isScreenSharingEnabled && this.isCameraEnabled.getValue()) {
      this.call.stopVideo();
      timer(100).subscribe(_ => {
        this.call.startScreenSharing();
      });
    } else if (isScreenSharingEnabled) {
      this.call.startScreenSharing();
    } else {
      this.call.stopVideo();
    }
    this.isScreenSharingEnabled.next(isScreenSharingEnabled);
  }

  toggleCamera() {
    if (this.statusCall.getValue() !== 'started' && this.statusCall.getValue() !== 'call') {
      return;
    }

    this.showLocalVideo.next(!this.showLocalVideo.getValue());
    this.isCameraEnabled.next(!this.isCameraEnabled.getValue());
    this.room.localParticipant.videoTracks.forEach(publication => {
      if (this.showLocalVideo.getValue()) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
  }

  toggleMicrophone() {
    if (this.statusCall.getValue() !== 'started' && this.statusCall.getValue() !== 'call') {
      return;
    }

    this.isMicrophoneMuted.next(!this.isMicrophoneMuted.getValue());
    this.room.localParticipant.audioTracks.forEach(publication => {
      if (this.isMicrophoneMuted.getValue()) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
  }


  private clearCall() {
    this.stopCallTimer();
    this.stopCallingSound();
    this.statusCall.next('idle');
    this.callRoomId = null;

    this.callTimer.next('');
    this.localStream.next(null);
    this.remoteStream.next(null);
    this.showLocalVideo.next(false);
    this.isCameraEnabled.next(false);
    this.isMicrophoneMuted.next(false);
    this.isScreenSharingEnabled.next(false);
    this.isCameraExist.next(false);
    this.isMicExist.next(false);
    this.onRemoteCameraOn.next(false);

    this.isCameraGranted = false;
    this.isMicGranted = false;
  }
}
