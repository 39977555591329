import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class DistanceChartBuilder extends ChartsBuilder {

  public static chartName = 'Distance';

  public static axisNames: string[] = ['Distance'];


  constructor(element: ElementRef) {
    super(element);
    super.yLabelFormatter = (input: string) => {
      return input;
    }
  }

  init() {
    return super.initChart('stock');
  }
}
