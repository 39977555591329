import { createFeatureSelector, createSelector } from '@ngrx/store';
import {callFeatureKey} from '../reducers';
import * as fromStore from '../reducers'

export const featureSelector = createFeatureSelector<fromStore.CallState>(callFeatureKey);

export const callSettings = createSelector(featureSelector, s => s.callSettings);
export const callEnded = createSelector(featureSelector, s => s.callEnded);


