import {ProfileEditModel, UserModel} from '../store/models/user.model';
import {MatDialog} from '@angular/material/dialog';
import {ImageCropperPopupComponent} from '../../../shared/component/image-cropper/image-cropper.component';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoadAvailableCountries} from 'src/app/store/actions';
import * as fromRootSelectors from 'src/app/store/selectors';
import {CloudIdTypeModel} from '../store/models/colud-id-type.model';
import {GenderModel} from '../store/models/gender.model';
import {CountryModel} from '../../../store/model/country.model';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'atk-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit, OnChanges, OnDestroy {

  destroy$ = new Subject<boolean>();
  @Input() isDisabled: boolean;
  @Input() userData: UserModel;
  @Input() prepareForm: EventEmitter<void>;
  @Output() save = new EventEmitter<any>();

  isAvatarDeleted = false;
  croppedImage: string;
  imageChangedEvent;
  selectIdType: CloudIdTypeModel;
  public cloudIdTypes: CloudIdTypeModel[] = [];
  selectSex: GenderModel;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];
  selectCountry: CountryModel;
  countriesLists: CountryModel[];


  public formGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    middleName: new FormControl(''),
    id_number: new FormControl(''),
    dob: new FormControl(''),
    patientnumber: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    telephone: new FormControl('', Validators.required),
    state: new FormControl(''),
    city: new FormControl(''),
    streetName: new FormControl(''),
    streetNumber: new FormControl(''),
    apartment: new FormControl(''),
    zipcode: new FormControl(''),

  });

  public sexes = [
    {value: 'male', viewValue: 'male', id: 1},
    {value: 'female', viewValue: 'female', id: 2},
    {value: 'other', viewValue: 'transgender', id: 3},
  ];

  constructor(private store: Store, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCoutriesList();
    this.getCountriesDialCode();
    this.getIdTypes();
    this.prepareForm.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.saveForm();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    }

    if (changes.userData && changes.userData.currentValue) {
      this.formGroup.patchValue({
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        telephone: this.userData.telephone ? this.userData.telephone.number : '',
        email: this.userData.email && this.userData.email.email ? this.userData.email.email : '',
        dob: this.userData.dob ? this.userData.dob : '',
        city: this.userData.address && this.userData.address.city ? this.userData.address.city : '',
        streetName: this.userData.address && this.userData.address.street ? this.userData.address.street : '',
        streetNumber: this.userData.address && this.userData.address.buildingNumber ? this.userData.address.buildingNumber : '',
        apartment: this.userData.address && this.userData.address.apartment ? this.userData.address.apartment : '',
        state: this.userData.address && this.userData.address.state ? this.userData.address.state :
          this.userData.address && this.userData.address.country && this.userData.address.country.region ?
          this.userData.address.country.region : '',
        zipcode: this.userData.address && this.userData.address.postal ? this.userData.address.postal : '',
      });
        this.selectSex = this.getSelectedGender(this.userData.gender);
        if(this.userData.address && this.userData.address.country) this.selectCountry =  this.userData.address.country;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private getSelectedGender(val) {
    return this.sexes.filter(ele => ele.value === val)[0];
  }

  public removeElementLogo(): void {
    this.userData.photo = null;
    this.isAvatarDeleted = true;
  }

  public fileChanged(event: Event) {
    this.imageChangedEvent = event;
    const target = (event.target as HTMLInputElement);
    if (target.files && target.files.length) {
      console.log(target.files);
      const reader = new FileReader();
      reader.onload = (e: any) => {
      };

      reader.readAsArrayBuffer(target.files[0]);

      const dialogRef = this.dialog.open(ImageCropperPopupComponent,
        {
          data: {
            event,
            element: this.userData,
            elementLogo: this.userData
          },
          panelClass: 'image-crop-dialog'
        });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.croppedImage = result;
          this.isAvatarDeleted = false;
        }
      });
    }
  }

  getCountriesDialCode() {
    this.store.pipe(takeUntil(this.destroy$), select(fromRootSelectors.getDialCodeCountries)).subscribe(countries => {
      this.preferredCountries = countries;
    });
  }

  getCoutriesList() {
    this.store.dispatch(new LoadAvailableCountries())
    this.store.pipe(takeUntil(this.destroy$), select(fromRootSelectors.availableCountries)).subscribe(countriesList => {
      this.countriesLists = countriesList;
    });
  }

  getIdTypes() {
    this.cloudIdTypes = [
      {
        id:1,
        title:'passport'
      },
      {
        id:2,
        title:'ticket'
      }
    ]
  }

  selectedIdType(item: CloudIdTypeModel) {
    this.selectIdType = item;
  }

  selectedsex(item: GenderModel) {
    this.selectSex = item;
  }

  numberOnly(event): boolean {
    const charCode = event.charCodeAt(0);
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  dateChange(event) {
    if (!this.numberOnly(event.key)) {
      event.preventDefault();
    } else {
      const currentValue = this.formGroup.controls.dob.value + event.key;
      if (currentValue.length > 10) {
        event.preventDefault();
      }
      if (currentValue.length === 2 || currentValue.length === 5) {
        event.preventDefault();
        this.formGroup.controls.dob.setValue(currentValue + '/');
      }
    }
  }

  selectedCountry(item: CountryModel) {
    this.selectCountry = item;
  }

  onStreetAutocompleteSelected(result: PlaceResult) {
    const address = result.address_components.filter(addr => addr.types.includes('route'))[0];
    this.formGroup.controls.streetName.setValue(address.long_name);

  }

  onCityAutocompleteSelected(result: PlaceResult) {
    const address = result.address_components.filter(addr => addr.types.includes('locality'))[0];
    this.formGroup.controls.city.setValue(address.long_name);
  }


  public saveForm() {
    const params: ProfileEditModel = {
      // clinic_id: this.clinic.id,
    };
    if (this.formGroup.controls.firstName.dirty) {
      params.first_name = this.formGroup.controls.firstName.value;
    }
    if (this.formGroup.controls.lastName.dirty) {
      params.last_name = this.formGroup.controls.lastName.value;
    }
    if (this.formGroup.controls.middleName.dirty) {
      params.middle_name = this.formGroup.controls.middleName.value;
    }
    if (this.formGroup.controls.dob.dirty) {
      params.dob = this.formGroup.controls.dob.value;
    }
    if (this.formGroup.controls.email.dirty && this.formGroup.controls.email.value !== this.userData.email) {
      params.email = this.formGroup.controls.email.value;
    }
    if (this.selectSex && this.selectSex.value !== this.userData.gender) {
      params.gender = this.selectSex.value;
    }

    if (
      this.formGroup.controls.city.dirty ||
      this.formGroup.controls.streetName.dirty ||
      this.formGroup.controls.streetNumber.dirty ||
      this.formGroup.controls.zipcode.dirty ||
      this.formGroup.controls.apartment.dirty ||
      this.formGroup.controls.state.dirty || (this.selectCountry && this.selectCountry.iso3Code  !== this.userData.country.iso3Code)
    ) {
      params.address = {
        country: this.selectCountry.iso3Code,
        city: this.formGroup.controls.city.value,
        street: this.formGroup.controls.streetName.value,
        streetNumber: this.formGroup.controls.streetNumber.value,
        postal: this.formGroup.controls.zipcode.value,
        buildingNumber: this.formGroup.controls.apartment.value,
        state: this.formGroup.controls.state.value,
      };
    }

    if (this.croppedImage) {
      params.croppedImage = this.croppedImage;
    }
    this.save.emit(params);
  }

}
