import {Action} from '@ngrx/store';
import {StateActionTypes} from '../../../../store/actions';
import {ClinicsActions, ClinicsActionTypes, SetClinicSearch} from '../actions';
import {ClinicModel} from '../models/clinic.model';
import {PharmacyModel} from '../models/pharmacy.model';
import {TitleModel} from '../../../../store/model/title.model';
import {UserModel} from '../../../user/store/models/user.model';
import {ProfessionalModel} from '../models/professional.model';


export const clinicsFeatureKey = 'clinics';

export interface ClinicsState {
  clinicsList: ClinicModel[];
  searchClinicsField: string;
  paginationConfig: {
    page: number,
    count: number,
    total: number
  },
  pharmaciesList: PharmacyModel[];

  currentClinicLogo: string,

  medicalSpecializations: TitleModel[];
  professionals: ProfessionalModel[]
  filterDoctors: any
}

export const initialState: ClinicsState = {
  clinicsList: [],
  searchClinicsField: '',
  paginationConfig: {
    page: 0,
    count: 20,
    total: 20
  },
  pharmaciesList: [],
  currentClinicLogo: null,
  medicalSpecializations: [],
  professionals: [],
  filterDoctors: []
};

export function reducer(state = initialState, action: ClinicsActions): ClinicsState {
  switch (action.type) {

    case ClinicsActionTypes.LoadClinicsSuccess: {
      return {
        ...state,
        clinicsList: action.payload.clinicsList
      }
    }

    case ClinicsActionTypes.LoadClinicsFailure: {
      return {
        ...state,
        clinicsList: []
      }
    }

    case ClinicsActionTypes.SetClinicsSearch: {
      return {
        ...state,
        searchClinicsField: action.payload.searchClinicsField
      }
    }

    case ClinicsActionTypes.SetClinicsPagination: {
      return {
        ...state,
        paginationConfig: {
          ...state.paginationConfig,
          ...action.payload.paginationConfig
        }
      }
    }

    case ClinicsActionTypes.LoadPharmaciesSuccess: {
      return {
        ...state,
        pharmaciesList: action.payload.pharmaciesList.map(item => {
          return {
            ...item,
            id: +item.id
          };
        })

      }
    }

    case ClinicsActionTypes.LoadPharmaciesFailure: {
      return {
        ...state,
        pharmaciesList: []
      }
    }

    case ClinicsActionTypes.AddFavouriteClinicSuccess:
    case ClinicsActionTypes.AddFavouriteClinicFailure:
    case ClinicsActionTypes.AddFavouritePharmacySuccess:
    case ClinicsActionTypes.AddFavouritePharmacyFailure: {
      return state;
    }

    case ClinicsActionTypes.SetCurrentClinicLogo: {
      return {
        ...state,
        currentClinicLogo: action.payload.logoUrl
      }
    }

    case ClinicsActionTypes.FetchMedicalSpecializationsSuccess:{
      return {
        ...state,
        medicalSpecializations: action.payload.medicalSpecialization
      }
    }
    case ClinicsActionTypes.FetchMedicalSpecializationsFailure:{
      return {
        ...state,
        medicalSpecializations: []
      }
    }

    case ClinicsActionTypes.FetchProfessionalsSuccess:{
      return {
        ...state,
        professionals: action.payload.professionals
      }
    }

    case ClinicsActionTypes.FetchDoctorsSuccess:{
      return {
        ...state,
        filterDoctors: action.payload.filterDoctors
      }
    }

    default:
      return state;
  }
}
