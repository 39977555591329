export const states = [
  {
      countrieCode: 'AUS',
      regions: [
          {
              'region': 'Australian Capital Territory',
          },
          {
              'region': 'Northern Territory',
          },
          {
              'region': 'State of New South Wales',
          },
          {
              'region': 'State of Queensland',
          },
          {
              'region': 'State of South Australia',
          },
          {
              'region': 'State of Tasmania',
          },
          {
              'region': 'State of Victoria',
          },
          {
              'region': 'State of Western Australia',
          }
      ]
  },
  {
      countrieCode: 'BRA',
      regions: [
          {
              'region': 'Acre',
          },
          {
              'region': 'Alagoas',
          },
          {
              'region': 'Amapa',
          },
          {
              'region': 'Amazonas',
          },
          {
              'region': 'Bahia',
          },
          {
              'region': 'Ceara',
          },
          {
              'region': 'Espirito Santo',
          },
          {
              'region': 'Federal District',
          },
          {
              'region': 'Goias',
          },
          {
              'region': 'Maranhao',
          },
          {
              'region': 'Mato Grosso',
          },
          {
              'region': 'Mato Grosso do Sul',
          },
          {
              'region': 'Minas Gerais',
          },
          {
              'region': 'Para',
          },
          {
              'region': 'Paraiba',
          },
          {
              'region': 'Parana',
          },
          {
              'region': 'Pernambuco',
          },
          {
              'region': 'Piaui',
          },
          {
              'region': 'Rio de Janeiro',
          },
          {
              'region': 'Rio Grande do Norte',
          },
          {
              'region': 'Rio Grande do Sul',
          },
          {
              'region': 'Rondonia',
          },
          {
              'region': 'Roraima',
          },
          {
              'region': 'Santa Catarina',
          },
          {
              'region': 'Sao Paulo',
          },
          {
              'region': 'Sergipe',
          },
          {
              'region': 'Tocantins',
          }
      ]
  },
  {
      countrieCode: 'ETH',
      regions: [
          {
              'region': 'Adis Abeba Astedader',
          },
          {
              'region': 'Afar Region',
          },
          {
              'region': 'Amhara Region',
          },
          {
              'region': 'Benishangul-Gumuz Region',
          },
          {
              'region': 'Dire Dawa Region',
          },
          {
              'region': 'Gambela Region',
          },
          {
              'region': 'Harari Region',
          },
          {
              'region': 'Oromiya Region',
          },
          {
              'region': 'Somali Region',
          },
          {
              'region': 'Southern Nations, Nationalities, and People\'s Region',
          },
          {
              'region': 'Tigray Region',
          }
      ]
  },
  {
      countrieCode: 'DEU',
      regions: [
          {
              'region': 'Baden-Wuerttemberg Region',

          },
          {
              'region': 'Bavaria',

          },
          {
              'region': 'Brandenburg',

          },
          {
              'region': 'Bremen',

          },
          {
              'region': 'Free and Hanseatic City of Hamburg',

          },
          {
              'region': 'Hessen',

          },
          {
              'region': 'Land Berlin',

          },
          {
              'region': 'Lower Saxony',

          },
          {
              'region': 'Mecklenburg-Western Pomerania',

          },
          {
              'region': 'Nordrhein-Westfalen',

          },
          {
              'region': 'Rheinland-Pfalz',

          },
          {
              'region': 'Saarland',

          },
          {
              'region': 'Saxony',

          },
          {
              'region': 'Saxony-Anhalt',

          },
          {
              'region': 'Schleswig-Holstein',

          },
          {
              'region': 'Thuringia',

          }
      ]
  },
  {
      countrieCode: 'IND',
      regions: [
          {
              'region': 'Assam',

          },
          {
              'region': 'Goa',

          },
          {
              'region': 'Madhya Pradesh',

          },
          {
              'region': 'Manipur',

          },
          {
              'region': 'Meghalaya',

          },
          {
              'region': 'Mizoram',

          },
          {
              'region': 'National Capital Territory of Delhi',

          },
          {
              'region': 'Sikkim',

          },
          {
              'region': 'State of Andhra Pradesh',

          },
          {
              'region': 'State of Arunachal Pradesh',

          },
          {
              'region': 'State of Bihar',

          },
          {
              'region': 'State of Chhattisgarh',

          },
          {
              'region': 'State of Gujarat',

          },
          {
              'region': 'State of Haryana',

          },
          {
              'region': 'State of Himachal Pradesh',

          },
          {
              'region': 'State of Jammu and Kashmir',

          },
          {
              'region': 'State of Jharkhand',

          },
          {
              'region': 'State of Karnataka',

          },
          {
              'region': 'State of Kerala',

          },
          {
              'region': 'State of Maharashtra',

          },
          {
              'region': 'State of Nagaland',

          },
          {
              'region': 'State of Odisha',

          },
          {
              'region': 'State of Punjab',

          },
          {
              'region': 'State of Rajasthan',

          },
          {
              'region': 'State of Tamil Nadu',

          },
          {
              'region': 'State of Uttarakhand',

          },
          {
              'region': 'Telangana',

          },
          {
              'region': 'Tripura',

          },
          {
              'region': 'Union Territory of Andaman and Nicobar Islands',

          },
          {
              'region': 'Union Territory of Chandigarh',

          },
          {
              'region': 'Union Territory of Dadra and Nagar Haveli',

          },
          {
              'region': 'Union Territory of Daman and Diu',

          },
          {
              'region': 'Union Territory of Lakshadweep',

          },
          {
              'region': 'Union Territory of Puducherry',

          },
          {
              'region': 'Uttar Pradesh',

          },
          {
              'region': 'West Bengal',

          }
      ]
  },
  {
      countrieCode: 'MEX',
      regions: [
          {
              'region': 'Ciudad de Mexico',

          },
          {
              'region': 'Estado de Aguascalientes',

          },
          {
              'region': 'Estado de Baja California',

          },
          {
              'region': 'Estado de Baja California Sur',

          },
          {
              'region': 'Estado de Campeche',

          },
          {
              'region': 'Estado de Chiapas',

          },
          {
              'region': 'Estado de Chihuahua',

          },
          {
              'region': 'Estado de Coahuila de Zaragoza',

          },
          {
              'region': 'Estado de Colima',

          },
          {
              'region': 'Estado de Durango',

          },
          {
              'region': 'Estado de Guanajuato',

          },
          {
              'region': 'Estado de Guerrero',

          },
          {
              'region': 'Estado de Hidalgo',

          },
          {
              'region': 'Estado de Jalisco',

          },
          {
              'region': 'Estado de Mexico',

          },
          {
              'region': 'Estado de Michoacan de Ocampo',

          },
          {
              'region': 'Estado de Morelos',

          },
          {
              'region': 'Estado de Nayarit',

          },
          {
              'region': 'Estado de Nuevo Leon',

          },
          {
              'region': 'Estado de Oaxaca',

          },
          {
              'region': 'Estado de Puebla',

          },
          {
              'region': 'Estado de Queretaro',

          },
          {
              'region': 'Estado de Quintana Roo',

          },
          {
              'region': 'Estado de San Luis Potosi',

          },
          {
              'region': 'Estado de Sinaloa',

          },
          {
              'region': 'Estado de Sonora',

          },
          {
              'region': 'Estado de Tabasco',

          },
          {
              'region': 'Estado de Tamaulipas',

          },
          {
              'region': 'Estado de Tlaxcala',

          },
          {
              'region': 'Estado de Veracruz-Llave',

          },
          {
              'region': 'Estado de Yucatan',

          },
          {
              'region': 'Estado de Zacatecas',

          }
      ]
  },
  {
      countrieCode: 'MMR',

      regions: [
          {
              'region': 'Ayeyawady Region',

          },
          {
              'region': 'Bago Region',

          },
          {
              'region': 'Chin State',

          },
          {
              'region': 'Kachin State',

          },
          {
              'region': 'Kayah State',

          },
          {
              'region': 'Kayin State',

          },
          {
              'region': 'Magway Region',

          },
          {
              'region': 'Mandalay Region',

          },
          {
              'region': 'Mon State',

          },
          {
              'region': 'Nay Pyi Taw',

          },
          {
              'region': 'Rakhine State',

          },
          {
              'region': 'Sagaing Region',

          },
          {
              'region': 'Shan State',

          },
          {
              'region': 'Taninthayi Region',

          },
          {
              'region': 'Yangon Region',

          }
      ]
  },
  {
      countrieCode: 'NGA',
      regions: [
          {
              'region': 'Abia State',

          },
          {
              'region': 'Adamawa State',

          },
          {
              'region': 'Akwa Ibom State',

          },
          {
              'region': 'Anambra State',

          },
          {
              'region': 'Bauchi State',

          },
          {
              'region': 'Bayelsa State',

          },
          {
              'region': 'Benue State',

          },
          {
              'region': 'Borno State',

          },
          {
              'region': 'Cross River State',

          },
          {
              'region': 'Delta State',

          },
          {
              'region': 'Ebonyi State',

          },
          {
              'region': 'Edo',

          },
          {
              'region': 'Ekiti State',

          },
          {
              'region': 'Enugu State',

          },
          {
              'region': 'Federal Capital Territory',

          },
          {
              'region': 'Gombe State',

          },
          {
              'region': 'Imo State',

          },
          {
              'region': 'Jigawa State',

          },
          {
              'region': 'Kaduna State',

          },
          {
              'region': 'Kano State',

          },
          {
              'region': 'Katsina State',

          },
          {
              'region': 'Kebbi State',

          },
          {
              'region': 'Kogi State',

          },
          {
              'region': 'Kwara State',

          },
          {
              'region': 'Lagos State',

          },
          {
              'region': 'Nasarawa State',

          },
          {
              'region': 'Niger State',

          },
          {
              'region': 'Ogun State',

          },
          {
              'region': 'Ondo State',

          },
          {
              'region': 'Osun State',

          },
          {
              'region': 'Oyo State',

          },
          {
              'region': 'Plateau State',

          },
          {
              'region': 'Rivers State',

          },
          {
              'region': 'Sokoto State',

          },
          {
              'region': 'Taraba State',

          },
          {
              'region': 'Yobe State',

          },
          {
              'region': 'Zamfara State',

          }
      ]
  },
  {
      countrieCode: 'PLW',
      regions: [
          {
              'region': 'State of Aimeliik',

          },
          {
              'region': 'State of Airai',

          },
          {
              'region': 'State of Angaur',

          },
          {
              'region': 'State of Hatohobei',

          },
          {
              'region': 'State of Kayangel',

          },
          {
              'region': 'State of Koror',

          },
          {
              'region': 'State of Melekeok',

          },
          {
              'region': 'State of Ngaraard',

          },
          {
              'region': 'State of Ngarchelong',

          },
          {
              'region': 'State of Ngardmau',

          },
          {
              'region': 'State of Ngatpang',

          },
          {
              'region': 'State of Ngchesar',

          },
          {
              'region': 'State of Ngeremlengui',

          },
          {
              'region': 'State of Ngiwal',

          },
          {
              'region': 'State of Peleliu',

          },
          {
              'region': 'State of Sonsorol',

          }
      ]
  },
  {
      countrieCode: 'USA',
      regions: [
          {
              'region': 'Alabama',

          },
          {
              'region': 'Alaska',

          },
          {
              'region': 'Arizona',

          },
          {
              'region': 'Arkansas',

          },
          {
              'region': 'California',

          },
          {
              'region': 'Colorado',

          },
          {
              'region': 'Connecticut',

          },
          {
              'region': 'Delaware',

          },
          {
              'region': 'District of Columbia',

          },
          {
              'region': 'Florida',

          },
          {
              'region': 'Georgia',

          },
          {
              'region': 'Hawaii',

          },
          {
              'region': 'Idaho',

          },
          {
              'region': 'Illinois',

          },
          {
              'region': 'Indiana',

          },
          {
              'region': 'Iowa',

          },
          {
              'region': 'Kansas',

          },
          {
              'region': 'Kentucky',

          },
          {
              'region': 'Louisiana',

          },
          {
              'region': 'Maine',

          },
          {
              'region': 'Maryland',

          },
          {
              'region': 'Massachusetts',

          },
          {
              'region': 'Michigan',

          },
          {
              'region': 'Minnesota',

          },
          {
              'region': 'Mississippi',

          },
          {
              'region': 'Missouri',

          },
          {
              'region': 'Montana',

          },
          {
              'region': 'Nebraska',

          },
          {
              'region': 'Nevada',

          },
          {
              'region': 'New Hampshire',

          },
          {
              'region': 'New Jersey',

          },
          {
              'region': 'New Mexico',

          },
          {
              'region': 'New York',

          },
          {
              'region': 'North Carolina',

          },
          {
              'region': 'North Dakota',

          },
          {
              'region': 'Ohio',

          },
          {
              'region': 'Oklahoma',

          },
          {
              'region': 'Oregon',

          },
          {
              'region': 'Pennsylvania',

          },
          {
              'region': 'Rhode Island',

          },
          {
              'region': 'South Carolina',

          },
          {
              'region': 'South Dakota',

          },
          {
              'region': 'Tennessee',

          },
          {
              'region': 'Texas',

          },
          {
              'region': 'Utah',

          },
          {
              'region': 'Vermont',

          },
          {
              'region': 'Virginia',

          },
          {
              'region': 'Washington',

          },
          {
              'region': 'West Virginia',

          },
          {
              'region': 'Wisconsin',

          },
          {
              'region': 'Wyoming',

          }
      ]
  }


];