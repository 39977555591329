import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddCardComponent } from 'src/app/shared/component/add-card/add-card.component';
import { SuccessSnackbarComponent } from '../../shared/modals/success-snackbar/success-snackbar.component';

const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  duration: 5000,
  verticalPosition: 'top',
  horizontalPosition: 'right',
  panelClass: 'blank-snackbar'
}

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog
  ) { }

  private readonly progressBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public getProgressBarState(): Observable<boolean> {
    return this.progressBar$.asObservable();
  }

  public setProgressBar(): void {
    this.progressBar$.next(true);
  }

  public removeProgressBar(): void {
    this.progressBar$.next(false);
  }

  public fireSnackBar(config?: MatSnackBarConfig, component: ComponentType<any> = SuccessSnackbarComponent): void {
    this.snackBar.openFromComponent(component, { ...DEFAULT_SNACKBAR_CONFIG, ...config });
  }

}
