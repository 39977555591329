import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenEmail'
})
export class HiddenEmailPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if (value && value.length > 3) {
      const values = value.split('@');
      if (values[0].length > 3) {
        const region = values[1].split('.').pop()
        return values[0].substring(0, 3) + '*'.repeat(values[0].length - 3)
          + '@' + '*'.repeat(values[1].length - (region.length + 1)) + '.' + region;
      } else {
        return '*'.repeat(values[0].length) + '@' + values[1];
      }
    }
    else { return value; }
  }

}
