import { Observable } from 'rxjs';
import { HttpWrapper, HttpWrapperArray, HttpWrapperCollection } from '../../store/model/http';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpDataArrayWrapper, HttpDataWrapper} from '../../store/model/http';
import {LoginResponse} from '../../store/model/login.response';
import {AppConstants} from '../../app.constants';
import {IWaitingRoomExtended, WaitingRoomModel} from './store/models/waiting-room.model';
import {WaitingRoomJoinResponse} from './store/models/waiting-room-join.response';



@Injectable({
  providedIn: 'root'
})
export class WaitingRoomsService {

  constructor(private api: HttpClient) { }

  public loadWaitingRooms(params) {
    return this.api.post<HttpDataArrayWrapper<WaitingRoomModel>>(`${AppConstants.baseUrl}/clinics/waiting_rooms/available`, params);
  }

  public joinIntoWaitingRoom(params: {
    room_id: number,
    body_part_ids: number[],
    description: string,
    symptoms: string
  }) {
    return this.api.post<HttpDataWrapper<WaitingRoomJoinResponse>>(`${AppConstants.baseUrl}/clinics/waiting_rooms/join`, params);
  }


  // public getWaitingRooms(clinic_id) {
  //   return this.api.get<HttpWrapperCollection<WaitingRoomModel>>(`${AppConstants.baseUrl}/clinics/waiting_rooms?clinic_id=${clinic_id}`);
  // }

  public getWaitingRooms(params): Observable<HttpWrapperCollection<WaitingRoomModel>> {
    const httpParams = new HttpParams({ fromObject: { ...params } });
    return this.api.get<HttpWrapperCollection<WaitingRoomModel>>(`${AppConstants.baseUrl}/clinics/waiting_rooms`, { params: httpParams });
  }

  public getWaitingRoomsByIds(params) {
    return this.api.post<HttpWrapperArray<IWaitingRoomExtended>>(`${AppConstants.baseUrl}/clinics/clinic/waiting_rooms/multiple`, params);
  }

  public callBackReq(params) {
    return this.api.post<HttpWrapper<{status: string}>>(`${AppConstants.baseUrl}/clinics/waiting_rooms/ask`, params);
  }

  forceRemovePatintWR(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/waiting_rooms/force_remove`, params);
  }
}
