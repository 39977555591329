import {Action} from '@ngrx/store';
import {LoginResponse} from '../../../../store/model/login.response';
import {UserModel} from '../models/user.model';

export enum UserActionTypes {
  LoadUsers = '[User] Load Users',

  LoginUser = '[Login Component] Login User',
  LoginUserSuccess = '[Login Component] Login User Success',
  LoginUserFailure = '[Login Component] Login User Failure',

  LogoutUser = '[User service] Logout User',
  LogoutUserSuccess = '[User service] Logout User Success',

  LoadUserProfile = '[User API] Load user profile',
  LoadUserProfileSuccess = '[User API] Load user profile Success',
  LoadUserProfileFailure = '[User API] Load user profile Failure',
  RegistrationName = '[Registration Name] Passed registration step 1',
  RegistrationPin = '[Registration PIN] Passed registration step 2. Set PIN',
  RegistrationInitSession = '[Registration Phone] Initialize registration session',
  RegistrationInitSessionSuccess = '[Registration API] Successfully initialized registration session',
  RegistrationInitSessionFailure = '[Registration API] Error with initialize registration session',
  SendPhoneConfirmationCode = '[Registration Phone Confirmation] Send phone confirmation code',
  SendPhoneConfirmationCodeSuccess = '[Registration API] Phone confirmation code success',
  SendPhoneConfirmationCodeFailure = '[Registration API] Phone confirmation code failure',
  RegistrationFinalizeSession = '[Registration Complete] Finalize registration session',
  RegistrationFinalizeSessionSuccess = '[Registration API] Finalize registration session success',
  RegistrationFinalizeSessionFailure = '[Registration API] Finalize registration session failure',
  FetchUserProfile = '[User] Fetch user profile info by id',
  FetchUserProfileSuccess = '[User API] Fetch user profile info by id success',
  FetchUserProfileFailure = '[User API] Fetch user profile info by id failure',

  FetchUsersByIds = '[User] Fetch users info by multiple ids',
  FetchUsersByIdsSuccess = '[User API] Fetch users info by multiple ids success',
  FetchUsersByIdsFailure = '[User API] Fetch users info by multiple ids failure',

  FetchContacts = '[User Contacts] Fetch user contacts',
  FetchContactsSuccess = '[User Contacts API] Fetch user contacts success',
  FetchContactsFailure = '[User Contacts] Fetch user contacts failure'
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LoadUsers;
}

export class LoginUser implements Action {
  readonly type = UserActionTypes.LoginUser;

  constructor(public payload: {
    login: string,
    password: string
  }) {
  }
}

export class LoginUserSuccess implements Action {
  readonly type = UserActionTypes.LoginUserSuccess;

  constructor(public payload: LoginResponse) {
  }
}

export class LoginUserFailure implements Action {
  readonly type = UserActionTypes.LoginUserFailure;

  constructor(public payload: {
    name: string,
    message: string,
    code: number
  }) {
  }
}

export class LogoutUser implements Action {
  readonly type = UserActionTypes.LogoutUser;

  constructor() {
  }
}
export class LogoutUserSuccess implements Action {
  readonly type = UserActionTypes.LogoutUserSuccess;

  constructor() {
  }
}

export class LoadUserProfile implements Action {
  readonly type = UserActionTypes.LoadUserProfile;

  constructor() {
  }
}

export class LoadUserProfileSuccess implements Action {
  readonly type = UserActionTypes.LoadUserProfileSuccess;

  constructor(public payload: {
    user: UserModel
  }) {
  }
}

export class LoadUserProfileFailure implements Action {
  readonly type = UserActionTypes.LoadUserProfileFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class RegistrationName implements Action {
  readonly type = UserActionTypes.RegistrationName;

  constructor(public payload: {
    firstName: string,
    lastName: string,
    gender: string,
    acceptedTerms: boolean,
    birthday: string,
    invite_id?: any
    invite_info?: any
  }) {
  }
}

export class RegistrationPin implements Action {
  readonly type = UserActionTypes.RegistrationPin;

  constructor(public payload: {
    pin: string
  }) {
  }
}

export class RegistrationInitSession implements Action {
  readonly type = UserActionTypes.RegistrationInitSession;

  constructor(public payload: {
    telephone: string,
    role: string,
    country: string,
    invite_id?: any
  }) {
  }
}

export class RegistrationInitSessionSuccess implements Action {
  readonly type = UserActionTypes.RegistrationInitSessionSuccess;

  constructor(public payload: {
    registrationSessionId: string,
    telephone: string
  }) {
  }
}

export class RegistrationInitSessionFailure implements Action {
  readonly type = UserActionTypes.RegistrationInitSessionFailure;

  constructor(public payload: {
    error: string
  }) {
  }
}

export class SendPhoneConfirmationCode implements Action {
  readonly type = UserActionTypes.SendPhoneConfirmationCode;

  constructor(public payload: {
    sessionId: string,
    code: string
  }) {
  }
}

export class SendPhoneConfirmationCodeSuccess implements Action {
  readonly type = UserActionTypes.SendPhoneConfirmationCodeSuccess;

  constructor() {
  }
}

export class SendPhoneConfirmationCodeFailure implements Action {
  readonly type = UserActionTypes.SendPhoneConfirmationCodeFailure;

  constructor() {
  }
}


export class RegistrationFinalizeSession implements Action {
  readonly type = UserActionTypes.RegistrationFinalizeSession;

  constructor(public payload: {
    firstName: string;
    lastName: string;
    birthday: string;
    gender: string;
    pin: string;
    telephone: string;
    registrationSessionId: string;
    telephoneConfirmationCode: string;
    invite_info?: any;
  }) {
  }
}

export class RegistrationFinalizeSessionSuccess implements Action {
  readonly type = UserActionTypes.RegistrationFinalizeSessionSuccess;

  constructor(public payload: {
    user: LoginResponse
  }) {
  }
}

export class RegistrationFinalizeSessionFailure implements Action {
  readonly type = UserActionTypes.RegistrationFinalizeSessionFailure;

  constructor(public payload: {
    error: any,
  }) {
  }
}

export class FetchUserProfile implements Action {
  readonly type = UserActionTypes.FetchUserProfile;

  constructor(public payload: {
    userId: string
  }) {
  }
}

export class FetchUserProfileSuccess implements Action {
  readonly type = UserActionTypes.FetchUserProfileSuccess;

  constructor(public payload: {
    user: UserModel
  }) {
  }
}

export class FetchUserProfileFailure implements Action {
  readonly type = UserActionTypes.FetchUserProfileFailure;

  constructor(public payload: {
    error: any,
  }) {
  }
}

export class FetchUsersByIds implements Action {
  readonly type = UserActionTypes.FetchUsersByIds;

  constructor(public payload: {
    ids: string[]
  }) {
  }
}

export class FetchUsersByIdsSuccess implements Action {
  readonly type = UserActionTypes.FetchUsersByIdsSuccess;

  constructor(public payload: {
    users: UserModel[]
  }) {
  }
}

export class FetchUsersByIdsFailure implements Action {
  readonly type = UserActionTypes.FetchUsersByIdsFailure;

  constructor(public payload: {
    error: any,
  }) {
  }
}

export class FetchContacts implements Action {
  readonly type = UserActionTypes.FetchContacts;

  constructor(public payload: {
    state: [{
      in_list: string[]
    }]
  }) {
  }
}

export class FetchContactsSuccess implements Action {
  readonly type = UserActionTypes.FetchContactsSuccess;

  constructor(public payload: {
    users: UserModel[]
  }) {
  }
}

export class FetchContactsFailure implements Action {
  readonly type = UserActionTypes.FetchContactsFailure;

  constructor(public payload: {
    error: any,
  }) {
  }
}

export type UserActions =
  LoadUserProfileSuccess |
  LoadUserProfileFailure |
  LoginUser |
  LoginUserSuccess |
  LoginUserFailure |
  LogoutUser |
  LogoutUserSuccess |
  RegistrationName |
  RegistrationPin |
  RegistrationInitSession |
  RegistrationInitSessionSuccess |
  RegistrationInitSessionFailure |
  SendPhoneConfirmationCode |
  SendPhoneConfirmationCodeSuccess |
  SendPhoneConfirmationCodeFailure |
  RegistrationFinalizeSession |
  RegistrationFinalizeSessionSuccess |
  RegistrationFinalizeSessionFailure |
  FetchUserProfile |
  FetchUserProfileSuccess |
  FetchUserProfileFailure |
  FetchUsersByIds |
  FetchUsersByIdsSuccess |
  FetchUsersByIdsFailure |
  FetchContacts |
  FetchContactsSuccess |
  FetchContactsFailure
  ;
