import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors} from "@angular/forms";


import * as moment from 'moment';

export class CustomValidators {

    public static validateNumber(control: AbstractControl): ValidationErrors | null {
        const reqExp = !/\D+/g.test(control.value);
        return reqExp ? null : { isNumber: false };
    }

    public static mustMatch(controlName: string, matchingControlName: string):
        (FormGroup) => ValidationErrors | null {

            return (formGroup: FormGroup) => {
                const control = formGroup.controls[controlName].value;
                const matchingControl = formGroup.controls[matchingControlName].value;

                if(control === matchingControl) {
                    return null;
                } else {
                    return { passwordMissMatch: true };
                }
            }
    }

    public static minAge(minAge: number):
        (FormGroup) => ValidationErrors | null {

            return (control: AbstractControl) => {
                const age = moment(control.value);
                const now = moment();

                return now.diff(age, 'years') >= minAge ?
                    null : { tooYoung: true }
            }
    }



}
