import {environment} from '../environments/environment';

// base URL
export class AppConstants {
  public static readonly baseUrl = environment.BASE_URL;
  public static readonly baseCallUrl = environment.BASE_CALL_URL;

  public static readonly wssUrl = environment.WSS_URL;
  public static readonly wssPostUrl = environment.WSS_POST_URL;
  public static readonly iceServerRequestUrl = environment.ISE_SERVER_REQUEST_URL;


  public static readonly API_KEY = environment.API_KEY;

  public static readonly wssWaitingAreaUrl = environment.WSS_WAITING_AREA_URL;
  public static readonly wssMessenger = environment.WSS_MESSENGER;


  public static readonly INACTIVE_INTERVAL = 10 * 60/*5*/ * 1000;
  public static readonly INACTIVE_CHECK_INTERVAL = 30 * 1000;

  public static readonly awsIdentityPoolId = environment.AWS_IDENTITY_POOL_ID;
  public static readonly awsRegion = environment.AWS_REGION;
  public static readonly awsMqttId = environment.AWS_MQTT_ID;

  public static readonly awsPubSubEnv = environment.AWS_PUB_SUB_ENV;



  public static readonly awsConfig = {
    Auth: {
      identityPoolId: AppConstants.awsIdentityPoolId,
      region: AppConstants.awsRegion
    }
  };
}
