import { map, tap, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../../features/user/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { IInviteInfo } from 'src/app/features/user/store/models/abstract-user.model';

@Component({
  selector: 'atk-invitation-code',
  templateUrl: './invitation-code.route.html',
  styleUrls: ['./invitation-code.route.scss']
})
export class InvitationCodeRoute implements OnDestroy {
  public validCode = false;
  public invalidCode = false;

  private inviteInfo: IInviteInfo;
  private destroyer$: Subject<boolean> = new Subject();

  constructor(public  dialogRef: MatDialogRef<InvitationCodeRoute>,
              private userService: UserService) { }

  ngOnDestroy(): void {
    this.destroyer$.next(true)
    this.destroyer$.complete();
  }
  public onCodeCompleted(code: string): void {
    this.checkInvintationCode(code);
  }

  private checkInvintationCode(code: string): void {
    const params = { code, role_id: 1 };
    this.userService
      .checkInvintationCode(params)
      .pipe(
        map((result: any) => result.data.invite_info),
        tap(_=> {
          this.validCode = false;
          this.invalidCode = false;
        }),
        takeUntil(this.destroyer$)
      ).subscribe((result:IInviteInfo) => {
          if (result && !result.user_id) {
            this.validCode = true;
            this.inviteInfo = result;
          } else {
            this.invalidCode = true
          }
        }
      )
  }

  public navigateNext(): void {
    this.dialogRef.close(this.inviteInfo);
  }

}
