import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ShellComponent} from './shell/shell.component';
import {AuthContainer} from './routes/user/auth/auth.container';
import {LoginRoute} from './routes/user/auth/login/login.route';
import {RegistrationRoute} from './routes/user/auth/registration/registration.route';
import {AuthGuard} from './core/auth.guard';
import {NameRoute} from './routes/user/auth/registration/name/name.route';
import {PinRoute} from './routes/user/auth/registration/pin/pin.route';
import {TelephoneIdentityRoute} from './routes/user/auth/registration/telephone-identity/telephone-identity.route';
import {ConfirmationCodeRoute} from './routes/user/auth/registration/confimation-code/confirmation-code.route';
import {RegistrationSuccessfulRoute} from './routes/user/auth/registration/registration-successful/registration-successful.route';
import {ChatAndCallGuard} from './routes/chat-and-call/chat-and-call.guard';
import {OnboardingChildrenComponent} from './routes/user/onboarding-children/onboarding-children.component';
import {AskInviteRoute} from './routes/user/onboarding-children/ask-invite/ask-invite.route';
import {ChildrenContentRoute} from './routes/user/onboarding-children/children-content/children-content.route';
import {TermsOfServiceComponent} from './routes/user/auth/terms-of-service/terms-of-service.component';
import {PrivacyPolicyComponent} from './routes/user/auth/privacy-policy/privacy-policy.component';
import {ForgotPinComponent} from './routes/user/auth/forgot-pin/forgot-pin.component';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    data: {
      orientation: 'left'
    },
    children: [
      {
        path: '',
        redirectTo: 'get-help',
        // redirectTo: 'chat',
        pathMatch: 'full'
      },
      {
        path: 'get-help',
        loadChildren: () => import('./routes/find-a-doctor/find-a-doctor.module').then(m => m.FindADoctorModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'chat',
        loadChildren: () => import('./routes/chat-and-call/chat/chat.module').then(m => m.ChatModule),
        canActivate: [AuthGuard, ChatAndCallGuard],
        canActivateChild: [AuthGuard, ChatAndCallGuard]
      },
      {
        path: 'calls',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./routes/chat-and-call/call/call.module').then(m => m.CallModule),
      },
      {
        path: 'e-records',
        loadChildren: () => import('./routes/e-records/e-records.module').then(m => m.ERecordsModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'my-health',
        loadChildren: () => import('./routes/my-health/my-health.module').then(m => m.MyHealthModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'activities',
        loadChildren: () => import('./routes/activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'consultations',
        loadChildren: () => import('./routes/consultations/consultations.module').then(m => m.ConsultationsModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./routes/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'treatments',
        loadChildren: () => import('./routes/treatments/treatments.module').then(m => m.TreatmentsModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      }
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: '',
        component: AuthContainer,
        children: [
          {
            path: 'login',
            component: LoginRoute
          },
          {
            path: 'forgot-pin',
            component: ForgotPinComponent
          },
          {
            path: 'registration',
            component: RegistrationRoute,
            children: [
              {
                path: '',
                component: NameRoute
              },
              {
                path: 'pin',
                component: PinRoute
              },
              {
                path: 'pin-retype',
                component: PinRoute,
                data: {
                  retype: true
                }
              },
              {
                path: 'phone',
                component: TelephoneIdentityRoute
              },
              {
                path: 'phone-confirm',
                component: ConfirmationCodeRoute
              },
              {
                path: 'successful',
                component: RegistrationSuccessfulRoute
              }
            ]
          },
          {
            path: 'children',
            component: OnboardingChildrenComponent,
            children: [
              {
                path: '',
                component: ChildrenContentRoute
              },
              {
                path: 'invite',
                component: AskInviteRoute
              }
            ]
          },
          {
            path: 'terms-of-service',
            component: TermsOfServiceComponent
          },
          {
            path: 'privacy-policy',
            component: PrivacyPolicyComponent
          }
        ]
      },
      {
        path: 'questionnaire',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./routes/user/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      }
    ]
  },
  {
    path: 'standalone',
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*, {enableTracing: true}*/)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

