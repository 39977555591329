import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromClinicsSelector from 'src/app/features/clinics/store/selectors';
import {filter, takeUntil} from 'rxjs/operators';
import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import {AbstractUserModel} from '../../../features/user/store/models/abstract-user.model';
import { LogoutUser } from 'src/app/features/user/store/actions';
import {Location} from '@angular/common';
import {ChatAndCallWebsocketService} from '../../../features/chat-and-call/chat-and-call-websocket.service';

@Component({
  selector: 'atk-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {

  open = true;
  clinicLogo: string;
  user: AbstractUserModel
  destroy$ = new Subject<boolean>();
  isSettings = false;

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,  private router: Router,
    private location: Location,
    private chatAndCallWebsocketService: ChatAndCallWebsocketService
  ) {
    this.isSettings = this.location.path(false).includes('settings');
  }

  ngOnInit() {
    this.store.pipe(
      select(fromClinicsSelector.currentClinicLogo),
      takeUntil(this.destroy$)
    ).subscribe(clinicLogo => {
      this.clinicLogo = clinicLogo;
      this.ref.markForCheck();
    })

    this.store.pipe(
      select(fromUserSelectors.userProfile),
      filter(user => !!(user)),
      takeUntil(this.destroy$)
    ).subscribe(user => {
      if (user) {
        this.user = user;
        this.ref.markForCheck();
      }
    });
  }

  setSettingsMenu(value) {
    this.isSettings = value;
  }

  backToClinic() {
    if (this.isSettings) {
      this.router.navigate(['/get-help']);
    }
    this.isSettings = false;
  }

  public logOut() {
    this.store.dispatch(new LogoutUser());
    this.chatAndCallWebsocketService.disconnect();
    this.store.pipe(select(fromUserSelectors.userLoggedIn), takeUntil(this.destroy$)).subscribe(res => {
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
