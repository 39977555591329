import { EcgChartBuilder } from './chart-builder/ecg-chart.builder';
import { EcgLiveChartBuilder } from './chart-builder/ecg-live-chart.builder';
import { HealthService } from '../../../features/health/health.service';
import { AppsService } from '../../../features/connected-apps/apps.service';
import { WeightChartBuilder } from './chart-builder/weight-chart.builder';
import { HeightChartBuilder } from './chart-builder/height-chart.builder';
import { TimeFrameImpl } from './interfaces/time-frame';
import { HttpWrapperArray } from 'src/app/store/model/http';
import {switchMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject, Observable, combineLatest, Subscription, of} from 'rxjs';
import { UserModel } from 'src/app/features/user/store/models/user.model';
import {
  Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef,
  AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, EventEmitter, Output
} from '@angular/core';
import * as moment from 'moment';
import { HealthDataType } from 'src/app/features/activity-record/store/data-type.enum';
import { ChartDataModel } from './models/chart-data.model';
import { ChartsBuilder } from './chart-builder/charts.builder';
import { TimeChartBuilder } from './chart-builder/time-chart.builder';
import { DistanceChartBuilder } from './chart-builder/distance-chart.builder';
import {BloodPressureChartBuilder} from './chart-builder/blood-pressure-chart.builder';
import { OxygenMeterChartBuilder } from './chart-builder/oxygen-meter-chart.builder';
import { TemperatureChartBuilder } from './chart-builder/temperature-chart.builder';
import { HeartRateChartBuilder } from './chart-builder/heart-rate-chart.builder';
import { HealthCardModel } from 'src/app/store/model/health-card.model';
import { saveFile } from '../../utils';

@Component({
  selector: 'atk-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [AppsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  constructor(public ref: ChangeDetectorRef,
              private healthService:HealthService,
              private appsService: AppsService) { }

  get isChartInit() {
    return this.chartConfig.isInit();
  }

  @Input() bluetoothData = null;
  @Input() bluetoothDataType = null;

  @Input() healthDataType: HealthDataType;
  @Input() showChartHeader = false;
  @ViewChild('chart', {static: false}) private chart: ElementRef;
  @Input() user: UserModel;
  @Input() medicalAreaArr: HealthCardModel[];
  @Output() chartConfigChange = new EventEmitter<ChartsBuilder>();
  xCategories: any;
  chartName: string;
  selectedData;

  isViewInit = new BehaviorSubject<boolean>(false);

  private userAge = 0;
  private chartConfig: ChartsBuilder;
  private axisNames: string[];
  measurementUnits: string
  data: {
    series: {
      name: string
      originData: any,
      datasets: ChartDataModel[][],
      averagePoints: ChartDataModel[]
    }[]
  }
  dataBluetooth = null;
  mockData: ChartDataModel[] = [];
  startDate: moment.Moment;
  endDate: moment.Moment = moment();
  timeFrame = new TimeFrameImpl('year');
  lastValue: string;
  needsZoom: boolean;

  private chartConfigType: typeof ChartsBuilder;
  private readonly destroy$ = new Subject<boolean>();

  isEmpty = true;
  private chartConfigDataUpdatedSubscription: Subscription;

  private static getUserAge(user: UserModel) {
    let userAge;
    if (user.dob) {
      userAge = moment().diff(moment(user.dob), 'year');
    } else {
      userAge = 0
    }
    return userAge
  }

  ngOnInit() {
    // this.mockData = this.generateRandomValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.healthDataType && changes.healthDataType.currentValue) {
      this.selectedData = changes.healthDataType.currentValue
      this.configureChartContainer(this.selectedData.dataType);
      this.init();
    }
    if (changes.bluetoothData && changes.bluetoothData.currentValue) {
      if (this.bluetoothDataType) {
        this.dataBluetooth = changes.bluetoothData;
        if (changes.bluetoothData.firstChange) {
          this.configureChartContainer(this.bluetoothDataType);
          this.initBluetoothData();
        } else if (this.chartConfig.seriesCount && this.chartConfig.seriesCount > 0) {
          this.chartConfig.addPoints([this.bluetoothData]);
        } else {
          this.chartConfig.addSeries([this.bluetoothData], this.bluetoothDataType);
        }
        this.ref.markForCheck();
      }
    }
  }

  ngAfterViewInit() {
    this.isViewInit.next(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public selectdata(item: HealthCardModel) {
    this.selectedData = item;
    this.configureChartContainer(this.selectedData.dataType);
    this.init();
  }

  // generateRandomValues() {
  //   const start = moment().add(-6, 'h').valueOf();
  //   const end = moment().valueOf();
  //
  //   const data: ChartDataModel[] = [];
  //
  //   for (let i = start, randomGapBetweenDot = 0; i < end; i += randomGapBetweenDot) {
  //     const randomItemsCount = Math.round(Math.random() * 10) + 5;
  //     const randomGapBetweenDataset = (Math.round(Math.random() * 300) + 3000) * 1000;
  //     randomGapBetweenDot = 0;
  //     for (let j = 0; j < randomItemsCount; j++) {
  //       randomGapBetweenDot += (Math.round(Math.random() * 40) + 10) * 1000;
  //       data.push({
  //         id: i + randomGapBetweenDot,
  //         value: Math.round(Math.random() * 30) + 70,
  //         date: moment(i + randomGapBetweenDot).toISOString(),
  //         dateMilliseconds: i + randomGapBetweenDot
  //       });
  //     }
  //     randomGapBetweenDot += randomGapBetweenDataset;
  //   }
  //   return data;
  // }

  // loadMockData(requestParams: {
  //   createdAfter: string,
  //   createdBefore: string
  // }) {
  //   const start = moment(requestParams.createdAfter).valueOf();
  //   const end = moment(requestParams.createdBefore).valueOf();
  //   const result = this.mockData.filter(item => item.dateMilliseconds >= start && item.dateMilliseconds <= end);
  //   return result
  // }

  private loadData(update: boolean = false): void {
    if (this.healthDataType === HealthDataType.ECG_LIVE || this.healthDataType === HealthDataType.ECG) {
      return;
    }
    const timeFrame = this.getCreatedAfter();
    let requests: Observable<HttpWrapperArray<ChartDataModel>>[];

    const requestParams = {};
    // requests = [this.healthService.getHelathRecordList({date: 0})];

    if (this.selectedData.dataType === HealthDataType.BLOOD_PRESSURE) {
      requests = [
        this.healthService.loadHealth(HealthDataType.SYSTOLIC, requestParams),
        this.healthService.loadHealth(HealthDataType.DIASTOLIC, requestParams)
      ];
    } else {
      requests = [this.healthService.loadHealth(this.selectedData.dataType, requestParams)];
    }
    // requests = [this.appsService.fitbitGetData()];

    if (this.selectedData.dataType === HealthDataType.ECG) {
      this.getEcg();
    } else {
      combineLatest(requests).subscribe(healthDataResponses => {
        if (!update) {
          const seriesNames = this.getSeriesNames(this.selectedData.dataType);
          this.data = {
            series: healthDataResponses.map((item, index) => {
              return {
                name: seriesNames[index],
                originData: item.data,
                datasets: [item.data],
                averagePoints: [],
              }
            })
          };
          this.removeAllSeries()

          this.lastValue = healthDataResponses
            .map(item => item.data.length > 0 ? item.data[item.data.length - 1].value : null)
            .filter(item => !!(item))
            .join('/');

          this.needsZoom = this.data.series.some(item => item.datasets.length > 1);
          this.data.series.forEach(item => {
            if (item.datasets.length > 0) {
              if (this.needsZoom) {
                this.addSeries(item.averagePoints, item.name);
              } else {
                this.addSeries(item.datasets[0], item.name);
              }
            }
          });
          this.ref.markForCheck();
        } else {
          healthDataResponses.forEach((item, index) => {
            this.addPoint(item.data, index);
          })
        }
      });
    }
  }

  getEcg() {
    let foundedSession = null;
    this.healthService.loadHealth(this.selectedData.dataType, {}).pipe(
      switchMap(res => {
        const sessions = res.data;
        if (!res.data.length) {
          return of(null);
        } else {
          foundedSession = sessions[0];
          return this.healthService.getSessionData(sessions[0].id.toString());
        }
      })
    ).subscribe(sessionData => {
      if (!this.chartConfig) {
        return;
      }
      if (this.chartConfig.seriesCount > 0) {
        [...new Array(this.chartConfig.seriesCount)].forEach(() => {
          this.chartConfig.removeSerie(0);
          console.log('removed series');
        });
      }
      const chartData: ChartDataModel[] = [];
      let counter = 0;
      sessionData.data.forEach(item => {
        item.data.values.forEach(inner => {
          chartData.push({
            id: counter,
            dateMilliseconds: counter,
            value: inner,
            date: '',
          })
          counter++
        })
      });
      (this.chartConfig as EcgChartBuilder).addSeries(
        chartData,
        `ECG ${(moment(foundedSession.start_date)).format('DD MMM, YYYY, HH:mm:ss')} - ${(moment(foundedSession.end_date)).format('DD MMM, YYYY, HH:mm:ss')}`
      );
      this.ref.markForCheck();
    })
  }

  addPoint(points: ChartDataModel[], series: number = 0) {
    if (this.timeFrame.TimeFrame.type === 'realtime') {
      this.chartConfig.addPoints(points, series);
      this.ref.markForCheck();
    }
  }

  private configureChartContainer(newChartType: HealthDataType) {
    switch (newChartType) {
      case HealthDataType.HEIGHT:
        this.chartConfigType = HeightChartBuilder;
        break;
      case HealthDataType.WEIGHT:
        this.chartConfigType = WeightChartBuilder;
        break;
      case HealthDataType.SYSTOLIC:
        this.measurementUnits = 'mmHg';
        this.chartName = 'Systolic Blood Pressure';
        this.axisNames = ['Systolic'];
        break;
      case HealthDataType.DIASTOLIC:
        this.measurementUnits = 'mmHg';
        this.chartName = 'Diastolic Blood Pressure';
        this.axisNames = ['Diastolic'];
        break;
      case HealthDataType.HEART_RATE:
        this.chartConfigType = HeartRateChartBuilder;
        break;
      case HealthDataType.TEMPERATURE:
        this.chartConfigType = TemperatureChartBuilder;
        break;
      case HealthDataType.OXYGEN_IN_BLOOD:
        this.chartConfigType = OxygenMeterChartBuilder;
        break;
      case HealthDataType.BLOOD_PRESSURE:
        this.chartConfigType = BloodPressureChartBuilder;
        break;
      case HealthDataType.TIME:
        this.chartConfigType = TimeChartBuilder;
        break;
      case HealthDataType.DISTANCE:
        this.chartConfigType = DistanceChartBuilder;
        break;
      case HealthDataType.ECG:
        this.chartConfigType = EcgChartBuilder;
        break;
      case HealthDataType.ECG_LIVE:
        this.chartConfigType = EcgLiveChartBuilder;
        break;
      default:
        this.chartConfigType = ChartsBuilder;
    }

    if (this.chartConfigType) {
      this.chartName = this.chartConfigType.chartName;
      this.measurementUnits = this.chartConfigType.measurementUnits;
      this.axisNames = this.chartConfigType.axisNames;
    }
  }
  getSeriesNames(healthDataType: HealthDataType) {
    switch (healthDataType) {
      case HealthDataType.HEIGHT:
        return ['Height'];
      case HealthDataType.WEIGHT:
        return ['Weight'];
      case HealthDataType.SYSTOLIC:
        return ['Systolic'];
      case HealthDataType.DIASTOLIC:
        return ['Diastolic'];
      case HealthDataType.TEMPERATURE:
        return ['Temperature'];
      case HealthDataType.HEART_RATE:
        return ['Heart rate'];
      case HealthDataType.OXYGEN_IN_BLOOD:
        return ['Oxygen in blood'];
      case HealthDataType.BLOOD_PRESSURE:
        return ['Systolic', 'Diastolic'];
      case HealthDataType.TIME:
        return ['Height'];
      case HealthDataType.DISTANCE:
        return ['Distance'];
      case HealthDataType.ECG:
        return ['Ecg'];
      default:
        return [''];
    }
  }

  init() {
    this.isViewInit.pipe(takeUntil(this.destroy$)).subscribe(viewInit => {
      if (viewInit) {
        this.unsubscribeDataUpdated();
        this.chartConfig = new (this.chartConfigType)(this.chart);
        // this.mode = 'avg'
        this.chartConfig.init().then(() => {
          this.loadData();
          this.chartConfigChange.emit(this.chartConfig);
          this.subscribeDataUpdated();
          this.ref.detectChanges();
        })

      }
    });
  }

  initBluetoothData() {
    this.isViewInit.pipe(takeUntil(this.destroy$)).subscribe(viewInit => {
      if (viewInit) {
        this.unsubscribeDataUpdated();
        this.chartConfig = new (this.chartConfigType)(this.chart);
        this.chartConfig.init().then(() => {
          this.subscribeDataUpdated();
          this.chartConfig.setSimpleHighstockGraph();
          this.ref.detectChanges();
        })
      }
    });
  }

  subscribeDataUpdated() {
    this.chartConfigDataUpdatedSubscription = this.chartConfig.dataUpdated
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (this.isEmpty !== (data.length === 0 || data[0].length === 0)) {
          this.isEmpty = data.length === 0 || data[0].length === 0;
          this.ref.detectChanges()
        }
      });
  }

  unsubscribeDataUpdated() {
    if (this.chartConfigDataUpdatedSubscription && !this.chartConfigDataUpdatedSubscription.closed) {
      this.chartConfigDataUpdatedSubscription.unsubscribe();
    }
  }

  removeAllSeries() {
    [...new Array(this.chartConfig.seriesCount)].forEach(() => {
      this.chartConfig.removeSerie(0);
    });

  }

  addSeries(monitoringChartData: ChartDataModel[], name: string) {
    // const selectedMonitoringChartData = Object.keys(monitoringChartData).find(item => item === this.selectedData.dataType);
    // this.chartConfig.addSeries(monitoringChartData[selectedMonitoringChartData], name);
    this.chartConfig.addSeries(monitoringChartData, name);
    this.ref.markForCheck();
  }

  getCreatedAfter(): {
    start: moment.Moment,
    end: moment.Moment
  } {
    let createdAfter: moment.Moment;
    let createdBefore: moment.Moment;
    const timeUnit = this.timeFrame.TimeFrame.timeUnit;

    if (this.startDate && this.endDate) {
      createdAfter = this.startDate
      createdBefore = this.endDate;
    } else if (this.endDate) {
      createdAfter = moment().add(-1, timeUnit);
      createdBefore = moment();
      this.startDate = createdAfter;
    }
    return {
      start: createdAfter,
      end: createdBefore
    };
  }

  public downloadFile(): void {
    const axisNames = `Date,${this.axisNames.join(',')}`;
    let allDates: number[] = [];

    this.data.series.forEach(serie => {
      allDates.push(...serie.originData.map(item => moment(item.date).utc(true).valueOf()))
    });
    allDates = allDates.filter((item, index, self) => self.indexOf(item) === index);
    allDates.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);

    const csv = allDates.map(date => {
      const entries: string[] = [moment(date).toISOString()];
      this.data.series.forEach(serie => {
        const foundedDataIndex = serie.originData.findIndex(item => date === moment(item.date).utc(true).valueOf())
        if (foundedDataIndex >= 0) {
          entries.push(serie.originData[foundedDataIndex].value.toString(10));
        } else {
          entries.push('');
        }
      });
      return entries.join(',');
    })

    const values = '\r\n' + csv.join('\r\n');

    const blob = new Blob([axisNames, values], {type: 'text/csv', endings: 'native'});
    saveFile(blob, `${this.chartName}.csv`);
  }
}
