import {Injectable} from '@angular/core';
import {UpdateRegistrationToken} from '../../store/actions';
import {NotificationCancelledCallModel} from './store/models/notifications/notification-cancelled-call.model';
import {select, Store} from '@ngrx/store';
import { first, skip} from 'rxjs/operators';
import {getFullName} from '../../shared/helpers';
import {NotificationMessageModel} from './store/models/notifications/notification-message.model';
import {NotificationSystemModel} from './store/models/notifications/notification-system.model';
import {AngularFireMessaging} from '@angular/fire/messaging';
import * as fromStore from '../../store';
import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import {AddNotificationToQueue, SetShowIncomingCallAlert, SetShowIncomingCallPopup} from './store/actions';
import {ChatMessageModel} from '../../store/model/chat-message.model';
import {FetchUserProfile} from '../user/store/actions';
import { HttpClient } from '@angular/common/http';
import { TimezoneModel } from 'src/app/store/model/timezone.model';
import { Subject } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private ignoredCalls = new Subject<{ roomId: string }>();
  ignoredCalls$ = this.ignoredCalls.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,private userService:UserService,
    private store: Store<fromStore.State>, private api: HttpClient
  ) {
  }

  init() {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   }
    // );

    this.userService.userLoggedIn$.subscribe(next => {
      this.angularFireMessaging.requestToken.subscribe(token => {
        this.store.dispatch(new UpdateRegistrationToken({registrationToken: token}));
      });
    });

    this.angularFireMessaging.messages.subscribe(message => {
      console.log('PUSH NOTIFICATION', message);
      if (message.hasOwnProperty('data') && message['data'].hasOwnProperty('type')) {
        const pushData = message['data'];
        const pushType = pushData['type'];
        switch (pushType) {
          case 'incoming_call':
            this.store.dispatch(new FetchUserProfile({userId: pushData.userId}));
            this.store.pipe(
              select(fromUserSelectors.userById),
              skip(1),
              first()
            ).subscribe(userById => {
              this.store.dispatch(new SetShowIncomingCallPopup({
                showCallPopup: {
                  show: true,
                  user: userById,
                  video: pushData['callType'] === 'video',
                  roomId: pushData['roomId']
                }
              }));
            });
            break;
          case 'cancelled_call':
            this.store.dispatch(new SetShowIncomingCallPopup({
              showCallPopup: {
                show: false,
                user: null,
                video: false,
                roomId: ''
              }
            }));
            this.store.dispatch(new FetchUserProfile({userId: pushData['userId']}));
            this.store.pipe(
              select(fromUserSelectors.userById),
              skip(1),
              first()
            ).subscribe(userById => {
              this.store.dispatch(new AddNotificationToQueue({
                message: `You dismissed call from ${getFullName(userById)}`,
                notificationType: 'cancelled_call',
                roomId: pushData['roomId'],
                userId: pushData['userId'],
                timeout: 15000,
                title: 'Missed call',
                type: 'error',
              } as NotificationCancelledCallModel));
            });
            break;
          case 'dialog':
            const message = JSON.parse(pushData['message']) as ChatMessageModel;
            this.store.dispatch(new FetchUserProfile({userId: message.created_by}));
            this.store.pipe(
              select(fromUserSelectors.userById),
              skip(1),
              first()
            ).subscribe(userById => {
              this.store.dispatch(new AddNotificationToQueue({
                timeout: 0,
                message: message.text,
                title: getFullName(userById),
                notificationType: 'message',
                type: 'info',
                messageId: parseInt(message.id.toString(10), 10),
                roomId: parseInt(message.room_id.toString(10), 10)
              } as NotificationMessageModel));
            });
            break;
            case 'alert':
              this.store.dispatch(new FetchUserProfile({userId: pushData.doctorId}));
              this.store.pipe(
                select(fromUserSelectors.userById),
                skip(1),
                first()
              ).subscribe(userById => {
                this.store.dispatch(new SetShowIncomingCallAlert({
                  showCallAlerPopup: {
                    show: true,
                    user: userById,
                    video: pushData['callType'],
                    roomId: pushData['roomId'],
                    roomType: pushData.roomType,
                    callerId:pushData.callerId
                  }
                }));
              });
              break;
              case 'event_call':
                this.store.dispatch(new FetchUserProfile({userId: pushData.doctorId}));
                this.store.pipe(
                  select(fromUserSelectors.userById),
                  skip(1),
                  first()
                ).subscribe(userById => {
                  this.store.dispatch(new SetShowIncomingCallAlert({
                    showCallAlerPopup: {
                      show: true,
                      user: userById,
                      video: pushData['videoCall'],
                      roomId: pushData.callerId,
                      roomType: pushData.roomType,
                      callerId:pushData['event_id'],
                    }
                  }));
                });
                break;
              case 'ignored_call':
                this.onIgnoredCallNotification(pushData);
                break;
          default:
            this.store.dispatch(new AddNotificationToQueue(pushData as NotificationSystemModel));
            break;
        }
      }
    });
  }

  onIgnoredCallNotification(pushData) {
    this.ignoredCalls.next({
      roomId: pushData['roomId']
    });
  }

  getTimezones() {
    return this.api.get<TimezoneModel[]>('assets/mock/timezones.json');
  }

  notify(text: string) {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else {
      const permissionPromise = Notification.requestPermission(function (permission) {
        if (permission === 'granted') {
          const notification = new Notification(text);
        } else {
          alert('You blocked notifications, if you want to enable it, please go to your browser settings, and re-login');
        }
      });

      if (permissionPromise) {
        permissionPromise.then(r => {
          console.log(r);
        })
      }
    }
  }
}
