import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList, ViewChild,
  ViewChildren
} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromStore from './store';
import * as fromSelectors from './store/selectors';
import * as fromUserSelectors from './features/user/store/selectors';
import {SwalComponent, SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';
import {ChatAndCallWebsocketService} from './features/chat-and-call/chat-and-call-websocket.service';
import {Router} from '@angular/router';
import {AppService} from './app.service';
import {KeepSession, OpenSessionPopup, StateActionTypes} from './store/actions';
import * as moment from 'moment';
import {AppConstants} from './app.constants';
import {first, takeUntil, tap} from 'rxjs/operators';
import {lastHeartbeat} from './store/selectors';
import {LogoutUser} from './features/user/store/actions';
import {Actions, ofType} from '@ngrx/effects';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'atk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AppComponent implements OnInit {

  title = 'appotek-web-patient';

  @ViewChildren('notificationViews') notificationViews: QueryList<SwalComponent>;
  @ViewChild('session', {static: false}) sessionPopup: SwalComponent;

  sessionTimer: number;
  sessionTimerText: string;
  sessionTimerSub: Subscription;

  constructor(
    private store: Store<fromStore.State>,
    private actions$: Actions,
    private chatAndCallWebsocketService: ChatAndCallWebsocketService,
    private ref: ChangeDetectorRef,
    private router: Router,
    public readonly swalTargets: SwalPortalTargets,
  ) {

  }

  ngOnInit(): void {


    this.store.pipe(select(fromSelectors.showLoginPopupNew)).subscribe(showLoginPopup => {
      // TODO: implement login popup
      // console.log(showLoginPopup);
      // if (showLoginPopup){
      //   this.state.dispatch(new showLoginPopup.action(showLoginPopup.payload))
      // }
    });

    this.actions$.pipe(
      ofType<OpenSessionPopup>(StateActionTypes.OpenSessionPopup)
    ).subscribe(() => {
      this.showSessionPopup();
    })


    this.store.pipe(select(fromSelectors.getAuthToken)).subscribe(authToken => {
      if (authToken) {
        this.chatAndCallWebsocketService.connect();
        this.chatAndCallWebsocketService.auth(authToken);
      } else {
        this.chatAndCallWebsocketService.disconnect();
      }
    });
  }

  showSessionPopup() {
    this.sessionTimer = 120000;
    this.sessionTimerText = '2:00';
    this.sessionTimerSub = interval(1000).subscribe(() => {
      this.sessionTimer -= 1000;
      this.sessionTimerText = Math.floor(this.sessionTimer / 60000) + ':' +
        ('0' + (this.sessionTimer % 60000) / 1000).slice(-2);
      this.ref.markForCheck()
      if (this.sessionTimer <= 0 && this.sessionTimerSub && !this.sessionTimerSub.closed) {
        this.sessionPopup.close().then();
        this.store.dispatch(new LogoutUser());
      }
    });

    this.sessionPopup.swalOptions = {
      title: 'Session',
      confirmButtonColor: '#42a3ed',
      confirmButtonText: 'Keep session',
      showConfirmButton: true,
      cancelButtonText: 'Logout',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    }
    this.sessionPopup.fire().then(result => {
      if (this.sessionTimerSub && !this.sessionTimerSub.closed) {
        this.sessionTimerSub.unsubscribe();
      }
      if (result.isConfirmed) {
        this.store.dispatch(new KeepSession());
      } else {
        this.store.dispatch(new LogoutUser());
      }
    });
  }
}
