import {Action} from '@ngrx/store';
import {CallActions, CallActionTypes} from '../actions';


export const callFeatureKey = 'call';

export interface CallState {
  // callRoomId: string,
  callSettings: {
    roomId: string,
    isVideo: boolean,
    mediaStream: MediaStream
  }
  callEnded: boolean,
  callInitError: any,
  callEndError: any,
}

export const initialState: CallState = {
  // callRoomId: null,
  callSettings: null,
  callEnded: false,
  callInitError: null,
  callEndError: null
};

export function reducer(state = initialState, action: CallActions): CallState {
  switch (action.type) {
    case CallActionTypes.SetCallRoomId:
    case CallActionTypes.InitCallSuccess: {
      return {
        ...state,
        // callRoomId: action.payload.callRoomId,
        callSettings: action.payload,
        callEnded: false
      }
    }

    case CallActionTypes.InitCallFailure: {
      console.error(action.payload.error);
      return {
        ...state,
        callInitError: action.payload.error
      }
    }
    case CallActionTypes.CancelCallSuccess:
    case CallActionTypes.EndCallSuccess: {
      return {
        ...state,
        callSettings: null,
        callEnded: true
      }
    }

    case CallActionTypes.CancelCallFailure:
    case CallActionTypes.EndCallFailure: {
      return {
        ...state,
        callEndError: action.payload.error,
        callEnded: true
      }
    }

    default:
      return state;
  }
}
