import { Injectable } from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';

import * as fromSelectors from 'src/app/store/selectors';
import * as fromState from 'src/app/store/reducers';
import {first, flatMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store<fromState.State>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select(fromSelectors.getAuthToken).pipe(
      first(),
      flatMap(token => {
        let authReq;
        if (req.headers.has('Authorization') && req.headers.get('Authorization') === 'none'){
          authReq = req.clone({
            setHeaders: { Authorization: '' },
          })
        }else if (token){
          authReq = req.clone({
            setHeaders: { Authorization: 'Bearer ' + token },
          })
        }else{
          authReq = req;
        }
        return next.handle(authReq);
      }),
    );
  }
}
