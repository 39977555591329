import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {BehaviorSubject, combineLatest, interval, Observable, Subject} from 'rxjs';
import {ChatAndCallWebsocketService} from '../../features/chat-and-call/chat-and-call-websocket.service';
import {filter, first, map, skip, take} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ChatAndCallGuard implements CanActivate, CanActivateChild {

  constructor(private chatAndCallWebsocketService: ChatAndCallWebsocketService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.chatAndCallWebsocketService.onConnectionIsOpen.pipe(
      filter(item => !!(item)),
      first(),
      map(() => true)
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

}
