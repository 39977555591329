import {HttpWrapper, HttpWrapperArray} from '../../store/model/http';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {PeopleModel} from "../ehr/record-access/people/people.model";
import {ClinicModel} from "../clinics/store/models/clinic.model";
import {HealthDataType} from "../activity-record/store/data-type.enum";
import {ChartDataModel} from "../../shared/component/chart/models/chart-data.model";
import {map} from "rxjs/operators";

class EcgDataModel {
  data: {
    battery: number;
    hr: number;
    index: number;
    created_at: number;
    values: number[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(private api: HttpClient) { }

  public getHelathRecordList<HEALTH = any>(additionalParams?: any): Observable<HttpWrapperArray<HEALTH>> {
    const params = new HttpParams({ fromObject: { ...additionalParams } } );
    return this.api.get<HttpWrapperArray<HEALTH>>(`${AppConstants.baseUrl}/api/v3/health`,{params});
  }

  loadHealth(healthDataType: HealthDataType, params: {
    createdBefore?: string,
    createdAfter?: string,
    limit?: number,
    offset?: number,
  }) {

    const httpParams = {
      created_after: params.createdAfter,
      value: healthDataType,
      limit: params.limit,
      offset: params.offset
    };
    if (params.createdBefore) {
      httpParams['created_before'] = params.createdBefore;
    }
    return this.api.post<HttpWrapperArray<ChartDataModel>>(`${AppConstants.baseUrl}/cloud/patients/health`, httpParams)
      .pipe(map(item => {
        return {
          ...item,
          data: [
            ...item.data.map(model => ({...model, dateMilliseconds: moment(model.date).valueOf()}))
          ]
        }
      }));
  }

  getSessionData(sessionId: string) {
    return this.api.get<HttpWrapperArray<{ data: EcgDataModel }>>(`${AppConstants.baseUrl}/recording-sessions/recording_sessions/post_data/${sessionId}`);
  }

  public saveHealthRecordData(params) {
    return this.api.post(`${AppConstants.baseUrl}/api/v3/health`, params);
  }

  saveAsPdf(params = {}){
    return this.api.post(`${AppConstants.baseUrl}/journals/health/export`, params, {responseType: 'blob' });
  }

  startRecordingSession(sessionId, patientId, trackerId) {
    const params = {
      id: sessionId,
      patient_id: patientId,
      tracker_id: trackerId,
      recording_session_type: 'ecg',
      start_date: moment().toISOString()
    };
    return this.api.post(`${AppConstants.baseUrl}/recording-sessions/recording_sessions`, params);
  }

  stopRecordingSession(sessionId) {
    return this.api.put(`${AppConstants.baseUrl}/recording-sessions/recording_sessions/${sessionId}`, {
      end_date: moment().toISOString()
    });
  }

  public getRecordingSessionById(sessionId) {
    return this.api.get(`${AppConstants.baseUrl}/recording-sessions/recording_sessions/post_data/${sessionId}`);
  }

  getPeopleList(params) {
    return this.api.post<HttpWrapperArray<PeopleModel>>(`${AppConstants.baseUrl}/cloud/contacts`, params);
  }

  getClinicList(params) {
    return this.api.post<HttpWrapperArray<ClinicModel>>(`${AppConstants.baseUrl}/clinics/list`, params);
  }

  getAccessList(params) {
    return this.api.post<HttpWrapperArray<PeopleModel | ClinicModel>>(`${AppConstants.baseUrl}/sharing/permissions/shared/by_me`, params);
  }

  changeAccessPermission(params){
    return this.api.post<HttpWrapper<PeopleModel>>(`${AppConstants.baseUrl}/sharing/permissions/user`, params);
  }

  getClinicDetails(clinicId) {
    return this.api.get<HttpWrapper<ClinicModel>>(`${AppConstants.baseUrl}/clinics/details/${clinicId}`);
  }
}
