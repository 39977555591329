import { Pipe, PipeTransform } from '@angular/core';
import { EVENT_TYPES } from '../../core/enums/consultation-enums';

@Pipe({
  name: 'calendarEvents'
})
export class CalendarEventsPipe implements PipeTransform {

  constructor() { }

  transform(type: string): string {

    switch(type) {
      case EVENT_TYPES.HOME_VISIT: return 'Home visit';
      case EVENT_TYPES.CONSULTATION: return 'Clinic consultation';
      case EVENT_TYPES.CALL: return 'Audio call';
      case EVENT_TYPES.DAY_OFF: return 'Out of clinic';
      case EVENT_TYPES.COMMON: return 'Event';
      default: return type;
    }
  }
}
