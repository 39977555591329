import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { HttpWrapper, HttpWrapperArray } from 'src/app/store/model/http';
import {ConnectedAppsAuth, ConnectedAppsData, ConnectedAppsDeAuth, ConnectedAppsStatus} from './apps.service.model';

@Injectable()
export class AppsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  // fitbit

  fitbitAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsAuth>>(`${AppConstants.baseUrl}/fitbit/auth`);
  }
  fitbitDeAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsDeAuth>>(`${AppConstants.baseUrl}/fitbit/deauth`);
  }
  fitbitStatus() {
    return this.http.get<HttpWrapper<ConnectedAppsStatus>>(`${AppConstants.baseUrl}/fitbit/status`);
  }
  fitbitGetData() {
    return this.http.get<HttpWrapper<any>>(`${AppConstants.baseUrl}/fitbit/get_data`);
  }

  // strava

  stravaAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsAuth>>(`${AppConstants.baseUrl}/strava/auth`);
  }
  stravaDeAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsDeAuth>>(`${AppConstants.baseUrl}/strava/deauth`);
  }
  stravaStatus() {
    return this.http.get<HttpWrapper<ConnectedAppsStatus>>(`${AppConstants.baseUrl}/strava/status`);
  }
  stravaGetData() {
    return this.http.get<HttpWrapper<ConnectedAppsData>>(`${AppConstants.baseUrl}/strava/get_data`);
  }

  // withings

  withingsAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsAuth>>(`${AppConstants.baseUrl}/withings/auth`);
  }
  withingsDeAuth() {
    return this.http.get<HttpWrapper<ConnectedAppsDeAuth>>(`${AppConstants.baseUrl}/withings/deauth`);
  }
  withingsStatus() {
    return this.http.get<HttpWrapper<ConnectedAppsStatus>>(`${AppConstants.baseUrl}/withings/status`);
  }
  withingsGetData() {
    return this.http.get<HttpWrapper<ConnectedAppsData>>(`${AppConstants.baseUrl}/withings/get_data`);
  }
}
