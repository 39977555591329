import { ChartDataModel } from '../models/chart-data.model';
import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class OxygenMeterChartBuilder extends ChartsBuilder {

  public static chartName = 'Oxygene in blood';
  public static measurementUnits = '% SpO<sub>2</sub>';
  public static axisNames: string[] = ['Oxygene in blood'];

  constructor(element: ElementRef) {
    super(element);
    // super.addZones({
    //   value: 81,
    //   color: '#ed4242'
    // });
    // super.addZones({
    //   value: 89,
    //   color: '#f2d273'
    // });
    // super.addZones({
    //   value: 100,
    //   color: '#42a3ed'
    // });
    super.yMax = 100;

    super.yLabelFormatter = (input: string) => {
      return input + '%';
    }
    super.valueSuffix = '%';

  }

  init() {
    return super.initChart('stock');
  }

  addSeries(chartData: ChartDataModel[], name: string) {
    super.addSeries(chartData, name, 'spline', {
      interval: 60000,
    }, [
      {
        value: 81,
        color: '#ed4242'
      },
      {
        value: 89,
        color: '#f2d273'
      },
      {
        value: 100,
        color: '#42a3ed'
      }
    ]);
  }

  addPoints(point: ChartDataModel[], series: number = 0) {
    super.addPoints(point, series);
  }


}
