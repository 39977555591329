import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HealthDataType} from 'src/app/features/activity-record/store/data-type.enum';
import {ChartsBuilder} from '../chart-builder/charts.builder';
import {EcgLiveChartBuilder} from '../chart-builder/ecg-live-chart.builder';
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'atk-ecg-live-chart',
  templateUrl: './ecg-live-chart.component.html',
  styleUrls: ['./ecg-live-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcgLiveChartComponent implements OnChanges, OnDestroy {
  @Input() height: number;
  @Input() healthDataType: HealthDataType;
  @Input() showChartHeader = true;
  @Input() bluetoothData = null;

  @Output() emptyQueue = new EventEmitter<boolean>();
  destroy$ = new Subject<boolean>();
  afterChartInit$ = new BehaviorSubject<boolean>(false);

  previousQueueLength = 0;

  private pointsProcessorSubscription: Subscription;
  chartConfig: EcgLiveChartBuilder = null;
  pointsInQueue: number;

  constructor(
    public ref: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bluetoothData && changes.bluetoothData.currentValue) {
      this.startProcessingEcgLiveData();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.stopProcessingEcgLiveData();
  }

  onChartInit(chartConfig: ChartsBuilder) {
    if (chartConfig instanceof EcgLiveChartBuilder) {
      this.chartConfig = chartConfig;
      console.log('chart init', chartConfig);
      this.afterChartInit$.next(true);
    }
  }

  startProcessingEcgLiveData() {
    this.stopProcessingEcgLiveData();
    if (!this.chartConfig) {
      return;
    }
    if (this.chartConfig.seriesCount === 0) {
      (this.chartConfig as EcgLiveChartBuilder).addLiveSeries('Ecg Live', 128 *3);
      this.ref.markForCheck();
    }

    // Process points queue
    this.pointsProcessorSubscription = interval(16)
      .pipe(takeUntil(this.destroy$))
      .subscribe(__ => {
        if (this.bluetoothData.length > 0) {
          const point = this.bluetoothData.shift();
          (this.chartConfig as EcgLiveChartBuilder).updateNextPoint(point);
          this.ref.markForCheck();
          if (this.previousQueueLength === 0) {
            this.emptyQueue.emit(false);
          }
        } else if (this.previousQueueLength > 0) {
          this.emptyQueue.emit(true);
        }
        this.previousQueueLength = this.bluetoothData?.length || 0;
      });
  }

  stopProcessingEcgLiveData() {
    if (this.pointsProcessorSubscription && !this.pointsProcessorSubscription.closed) {
      this.pointsProcessorSubscription.unsubscribe();
    }
  }
}
