import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {select, Store} from '@ngrx/store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Subject } from 'rxjs';
import { TitleModel } from './../../../../store/model/title.model';
import * as fromRootSelectors from 'src/app/store/selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'atk-ask-invite',
  templateUrl: './ask-invite.route.html',
  styleUrls: ['./ask-invite.route.scss']
})
export class AskInviteRoute implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @ViewChild('inviteModal', {static: true}) inviteModalModalRef: SwalComponent;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];
  selectRole: TitleModel;

  public roles = [
    {title: 'Father', id: 1},
    {title: 'Step-father', id: 2},
    {title: 'setp-mother', id: 3},
    {title: 'Legal-guardian', id: 4},
  ];


  public inviteFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    telephone: new FormControl('', Validators.required),
  });

  constructor(private store: Store) { }

  ngOnInit(): void {

    this.store.pipe(takeUntil(this.destroy$), select(fromRootSelectors.getDialCodeCountries)).subscribe(countries => {
      this.preferredCountries = countries;
    });
  }

  selectedRole(item: TitleModel) {
    this.selectRole = item;
  }

  checkDisble() {
    if(this.inviteFormGroup.valid && this.selectRole !== undefined){
      return false
    } else {
      return true;
    }
  }
  sendInvite(){
    this.inviteModalModalRef.swalOptions = {
      showCancelButton: false,
      width: 480,
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'Invite',
      showConfirmButton: true,
    };
    this.inviteModalModalRef.fire().then(result => {
      if (result.isConfirmed) {

      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
