import {
  AfterViewChecked,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'atk-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input('length') length: number;
  @Input('digitOnly') digitOnly = false;
  @Input('tabindex') tabindex: number;
  @Input('error') error: string;
  @Input('label') label: string;

  @Output() pinChange = new EventEmitter<string>();
  @Output() filled = new EventEmitter();

  pin: {
    value: string;
    input?: HTMLInputElement;
  }[] = [];

  lengthChanged = false;

  pastedText = '';

  constructor(
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.length && changes.length.currentValue !== changes.length.previousValue) {
      this.pin = [];
      for (let i = 0; i < changes.length.currentValue; i++) {
        this.pin.push({
          value: ''
        })
      }
      this.ref.markForCheck();
      this.lengthChanged = true;
    }
  }

  ngAfterViewChecked(): void {
    if (this.lengthChanged) {
      this.pin = this.pin.map((item, index) => {
        item.input = document.getElementById(`pin-${index}`) as HTMLInputElement;
        return item;
      });
      this.lengthChanged = false;
    }
  }

  filterNumbers(event: KeyboardEvent) {
    if (
      this.digitOnly &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      !event.metaKey &&
      event.key.length === 1 && !/\d/g.test(event.key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  pinPaste(event) {
    const clipboardData = event.clipboardData;
    this.pastedText = clipboardData.getData('text');
    if (this.pastedText.length > 1) {
      for (let i = 0; i < this.pastedText.length; i++) {
        this.pin[i].value = '';
      }
    }
  }

  pinItemChange(index: number, event) {
    const pin = this.pin.map(item => item.value ? item.value.toString() : '').join('');
    this.pinChange.emit(pin);

    if (!this.pin[index].value) {
      if (index > 0) {
        this.pin[index - 1].input.focus();
      }
      return;
    }
    if (event.length > 1 && this.pastedText.length) {
      for (let i = index, j = 0; i < this.pin.length; i++, j++) {
        this.pin[i].value = event[j];
        this.pin[i].input.focus();
      }
      this.pastedText = '';
    } else {
      this.pin[index].value = this.pin[index].value.toString();
    }
    switch (this.pin[index].value.length) {
      case 1:
        const emptyPinItems = this.pin.filter(item => !item.value);
        if (emptyPinItems.length === 0) {
          this.filled.emit();
        } else {
          const foundedElement = this.pin.slice(index).find(item => !item.value);
          if (foundedElement) {
            foundedElement.input.focus();
          }
        }
        break;
    }
  }

  pinItemKeyDown(i: number, event: KeyboardEvent) {
    if (!this.filterNumbers(event)) {
      return;
    }

    if (!event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      !event.metaKey &&
      event.key.length === 1 &&
      this.pin[i].value && this.pin[i].value.length >= 1) {
      if (!/\d/g.test(event.key) && this.digitOnly) {
        event.preventDefault();
      } else {
        if (i === this.pin.length - 1) {
          event.preventDefault();
          return;
        }
        this.pin[i + 1].value = event.key[event.key.charAt(-1)];
        this.pin[i + 1].input.focus();
      }
    }
  }
}
