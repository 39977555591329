import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {interval, timer} from 'rxjs';

@Component({
  selector: 'atk-appotek-dropdown',
  templateUrl: './appotek-dropdown.component.html',
  styleUrls: ['./appotek-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppotekDropdownComponent),
      multi: true
    }
  ]
})
export class AppotekDropdownComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() id: string;

  @ViewChild('input', {static: false}) input: ElementRef<HTMLInputElement>;
  @ViewChild('dropdown', {static: false, read: TemplateRef}) dropdown: TemplateRef<HTMLInputElement>;
  @ViewChild('suggestionList', {static: false}) suggestionList: ElementRef<HTMLDivElement>;

  @Input() closeOnClick = false;
  @Input() required = false;
  @Input() dropdownClass: string;
  @Input() dropdownTemplate: TemplateRef<any>;
  @Input() dropdownFooter: TemplateRef<any>;
  @Input() containerHeight: number;
  @Input() containerWidth: number;
  @Input() showTrigger = false;
  @Input() placeholder: string;
  @Input() placeholderOpen?: string;
  @Input() label: string;
  @Input() labelClass: string;
  @Input() clickOutsideClose = true;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() shortDropdown = false;
  @Input() tabindex = 0;

  @Input() dropdownShow = false;


  @Input() value: any;
  @Output() valueChange = new EventEmitter();

  overlayRef: OverlayRef;
  bottomView = true;
  suggestionsContainerHeight = 0;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private element: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open && changes.open.currentValue !== changes.open.previousValue) {
      changes.open.currentValue ? this.openDropdown() : this.closeDropdown();
    }

    if ((!changes.placeholderOpen || !changes.placeholderOpen.currentValue) && (changes.placeholder && changes.placeholder.currentValue)) {
      this.placeholderOpen = this.placeholder;
    }

    if (!changes.id && !this.id) {
      throw new Error('Id is required attribute');
    }

    if ((changes.containerHeight && changes.containerHeight.currentValue !== changes.containerHeight.previousValue) ||
      (changes.shortDropdown && changes.shortDropdown.currentValue !== changes.shortDropdown.previousValue)
    ) {
      if (this.dropdownShow) {
        this.closeDropdown()
        this.openDropdown();
      }
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  @HostListener('document:click', ['$event', '$event.target'])
  closeDropdowns(event: MouseEvent, targetElement: HTMLElement) {
    // if (!(event && targetElement) || this.dropdownShow)  {
    //   return;
    // }
    const clickedInsideInput = this.element.nativeElement.contains(targetElement);
    const panelElement = document.querySelector(`.suggestions-container`);
    let clickedInsideDropdown = false;
    if (panelElement) {
      clickedInsideDropdown = panelElement.contains(targetElement);
    }
    const clickedInside = clickedInsideInput || clickedInsideDropdown
    if (!clickedInside) {
      this.closeDropdown();
    }
  }

  @HostListener('document:keydown', ['$event', '$event.target'])
  keyDown(event: KeyboardEvent) {
    if (this.dropdownShow && (event.key === 'Escape' || event.key === 'Tab')) {
      this.closeDropdown();
    }
    if (document.activeElement.id === this.id) {
      if (!this.dropdownShow && event.key === 'ArrowDown') {
        this.openDropdown();
      } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        const isDown = event.key === 'ArrowDown';
        let selectedItem = document.querySelector<HTMLDivElement>('.suggestions-list .dropdown-list-item.selected');
        if (!selectedItem) {
          selectedItem = document.querySelector<HTMLDivElement>('.suggestions-list .dropdown-list-item');
          selectedItem.classList.add('selected');
        } else {
          selectedItem.classList.remove('selected');
          let nextElement = isDown
            ? selectedItem.nextElementSibling as HTMLDivElement
            : selectedItem.previousElementSibling as HTMLDivElement;
          if (!nextElement) {
            nextElement = isDown
              ? document.querySelector<HTMLDivElement>('.suggestions-list .dropdown-list-item')
              : document.querySelector<HTMLDivElement>('.suggestions-list .dropdown-list-item:last-child')
          }
          nextElement.classList.add('selected');
          this.scrollToElement(nextElement);
        }
      } else if (event.key === 'Enter') {
        const selectedItem = document.querySelector<HTMLDivElement>('.suggestions-list .dropdown-list-item.selected');
        selectedItem.click();
      }
    }


  }

  toggleDropdown() {
    if (this.disabled) return;
    this.dropdownShow ? this.closeDropdown() : this.openDropdown();
  }

  closeDropdown() {
    if (this.overlayRef && this.clickOutsideClose) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.dropdownShow = false;
    }
  }

  openDropdown() {
    if (this.dropdownShow || this.containerHeight === 0) {
      return;
    }

    this.bottomView = window.innerHeight - this.input.nativeElement.getBoundingClientRect().bottom - 4 >= this.containerHeight;

    let position = this.overlay.position()
      .flexibleConnectedTo(this.input)
      .withDefaultOffsetX(0);


    if (this.bottomView) {
      position = position.withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top'
      }]).withDefaultOffsetY(0)
    } else {
      position = position.withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom'
      }]).withDefaultOffsetY(0)
    }


    const panelClass = [this.dropdownClass, 'pointer-events-none'];
    let containerHeight = this.containerHeight;
    if (!this.shortDropdown) {
      this.suggestionsContainerHeight = (containerHeight - 41);
    } else {
      this.suggestionsContainerHeight = containerHeight;
    }
    if (!this.bottomView) {
      panelClass.push('suggestions-top-view');
    } else if (!this.shortDropdown) {
      containerHeight -= 41;
    }


    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'transparent',
      height: containerHeight,
      width: !!(this.containerWidth) ? this.containerWidth : this.input.nativeElement.offsetWidth,
      positionStrategy: position,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      panelClass,
    });

    if (!this.bottomView) {

      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'transparent',
        height: containerHeight,
        width: this.input.nativeElement.offsetWidth,
        positionStrategy: position,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        panelClass,
      });
    }

    const templatePortal = new TemplatePortal(this.dropdown, this.viewContainerRef);
    this.dropdownShow = true;
    this.overlayRef.attach(templatePortal);

    const itemsList = document.querySelectorAll<HTMLDivElement>('.suggestions-list .dropdown-list-item');
    const foundedElement = Array.from(itemsList).find(item => item.textContent === this.value)
    if (foundedElement) {
      foundedElement.classList.add('selected');
      timer(1).subscribe(() => this.scrollToElement(foundedElement));
    }

    if (this.closeOnClick) {
      const intervalSub = interval(10).subscribe(() => {
        intervalSub.unsubscribe();
        const targetElement = document.querySelector('.suggestions-list');
        if (targetElement) {
          targetElement.addEventListener('click', () => {
            this.closeDropdown();
          });
        }
      });
    }
  }

  test() {
    // console.log('test');
  }

  scrollToElement(element: HTMLElement) {
    const containerElement = this.suggestionList.nativeElement;
    const delta = (element.offsetTop - 10) - containerElement.scrollTop;

    if (delta > this.suggestionsContainerHeight - 25 - element.offsetHeight) {
      containerElement.scrollTop += delta - (this.suggestionsContainerHeight - 25 - element.offsetHeight);
    } else if (delta < 0) {
      containerElement.scrollTop += delta;
    }
  }

  inputValueChanged(value: string) {
    if (!this.dropdownShow) {
      this.openDropdown();
    }
    this.valueChange.emit(value);
  }
}
