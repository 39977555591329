import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UserService } from './../../../../features/user/user.service';
import { FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { SecurityQuestionModel } from 'src/app/store/model/notification.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as fromRootSelectors from 'src/app/store/selectors';

@Component({
  selector: 'atk-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss']
})
export class ForgotPinComponent implements OnInit, OnDestroy {
  inviteBy: 'phone' | 'email' = 'phone';
  email: string;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];
  phone = new FormControl(null, [Validators.required]);
  selectedSecurityQuestion: SecurityQuestionModel;
  securityQuestions: SecurityQuestionModel[] = [];
  securityAnswer: string;
  isFormSubmitted = false;
  securityQuestionError = false;
  userNotFoundError = false;
  destroy$ = new Subject<boolean>();

  constructor(private router: Router,private route: ActivatedRoute, private store: Store,
    private userService: UserService, private cd:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getCountries();
    this.getRecoverQuestion();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private getCountries(): void {
    this.store.pipe(takeUntil(this.destroy$), select(fromRootSelectors.getDialCodeCountries)).subscribe(countries => {
      this.preferredCountries = countries;
    });
  }

  private getRecoverQuestion(): void {
    this.userService.getRecoveryQuestions().pipe(takeUntil(this.destroy$)).subscribe(securityQuestions => {
      this.securityQuestions = securityQuestions.data.rows;
    })
  }

  public isSendDisabled(): boolean {
    return (
        (this.inviteBy === 'phone' && !this.phone.valid) ||
        (this.inviteBy === 'email' && !this.email)
      ) ||
      !this.selectedSecurityQuestion ||
      !this.securityAnswer;
  }

  public goToLogin(): void {
    this.router.navigate(['login'], {relativeTo: this.route.parent}).then();
  }

  send() {
    this.securityQuestionError = false;
    this.userNotFoundError = false;
    const params = {
      role: 'patient',
      answer: this.securityAnswer,
      qId: this.selectedSecurityQuestion.id
    }

    if (this.inviteBy === 'email') {
      params['email'] = this.email;
    } else if (this.inviteBy === 'phone') {
      params['telephone'] = `${this.phone.value.dialCode}${this.phone.value.number.replace(/\s+/g, '')}`;
    }
    this.userService.restorePin(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.success) {
        this.isFormSubmitted = true;
        this.cd.detectChanges();
      }
    }, (error) => {
      if (error.status === 400) {
        this.securityQuestionError = true;
      } else if (error.status === 412) {
        this.userNotFoundError = true;
      }
      this.cd.detectChanges();
    });
  }

}
