import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromStore from 'src/app/store';
import {stateFeatureKey} from 'src/app/store';
import {CountryISO} from 'ngx-intl-tel-input';

export const featureSelector = createFeatureSelector<fromStore.State>(stateFeatureKey);

export const getAuthToken = createSelector(featureSelector, s => s.authToken);

export const getTest = createSelector(featureSelector, s => s.testText);


export const showLoginPopup = createSelector(featureSelector, s => s.showLoginPopup);
export const showLoginPopupNew = createSelector(featureSelector, s => s.showLoginPopup);


export const questionnaireSkipLastDate = createSelector(featureSelector, s => s.questionnaireSkipLastDate);


export const inCall = createSelector(featureSelector, s => s.inCall);
export const availableCountries = createSelector(featureSelector, s => s.availableCountries);

export const getDialCodeCountries = createSelector(featureSelector, s => s.availableCountries.map(item => item.isoCode.toLocaleLowerCase()) as CountryISO[])

export const lastHeartbeat = createSelector(featureSelector, s => s.lastHeartbeat);
