import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDatetime'
})
export class FormatDatetimePipe implements PipeTransform {

  transform(value: string | moment.Moment, mask: string): string {
    if (value.constructor.name.toLowerCase() === 'string') {
      return moment(value).format(mask);
    } else {
      return (value as moment.Moment).format(mask);
    }
  }
}
