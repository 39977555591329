import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NotificationModel} from '../store/models/notifications/notification.model';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NotificationsService} from '../notifications.service';
import * as fromNotificationsSelectors from '../store/selectors';
import {RemoveNotificationFromQueue, SetShowIncomingCallPopup} from '../store/actions';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {UserModel} from '../../user/store/models/user.model';
import {PushNotificationIncomingCallModel} from '../store/models/push-notifications/push-notification-incoming-call.model';
import {NotificationMessageModel} from '../store/models/notifications/notification-message.model';
import {NotificationCancelledCallModel} from '../store/models/notifications/notification-cancelled-call.model';
import {NotificationSystemModel} from '../store/models/notifications/notification-system.model';
import { CallApiService } from '../../chat-and-call/call/call-api.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'atk-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
  animations: [
    trigger('notificationShowClose', [
      state('show', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({opacity: 0}))
      ]),
    ])
  ]
})
export class NotificationsContainerComponent implements OnInit {

  @ViewChild('call', {static: true}) incomingCallModal: SwalComponent;
  notifications: (
    NotificationMessageModel |
    NotificationCancelledCallModel |
    NotificationSystemModel
    )[] = [];

  incomingCall: {
    user: UserModel,
    message: PushNotificationIncomingCallModel,
    isVideo: boolean
  } = null;

  checkRoomType = false;
  private waitForAnswerSubscription: Subscription;
  private dialingSound: HTMLAudioElement;
  constructor(
    private store: Store, private router: Router,
    private callService: CallApiService, private notificationsService: NotificationsService,
    private ref: ChangeDetectorRef
  ) {  }

  ngOnInit() {
    this.notificationsService.init();

    this.store.pipe(select(fromNotificationsSelectors.getNotifications)).subscribe(next => {
      if (next) {
        const newMessagesToAdd = next.filter(item => this.notifications.findIndex(currentMessage => currentMessage.id === item.id) === -1);
        const oldMessagesToRemove = this.notifications.filter(item => next.findIndex(nextMessage => nextMessage.id === item.id) === -1);

        const messageIndexToRemove = oldMessagesToRemove.map(item => item.id);
        this.notifications = this.notifications.filter(item => !messageIndexToRemove.includes(item.id));

        this.notifications.unshift(...newMessagesToAdd);
        this.ref.detectChanges();
      }
    });

    this.store.pipe(select(fromNotificationsSelectors.showCallPopup)).subscribe(showPopup => {
      if (showPopup) {
        if (showPopup.show) {
          this.playSound();
          this.checkRoomType = false;
          this.openIncomingCallPopup(showPopup.user, showPopup.roomId, showPopup.video);
        } else {
          this.stopCallingSound();
          this.incomingCallModal.close().then();
        }
      }
    });
    this.store.pipe(select(fromNotificationsSelectors.showCallAlerPopup)).subscribe(showPopup => {
      if (showPopup) {
        if (showPopup.show) {
          this.playSound();
          showPopup.roomType == 'free'?this.checkRoomType = false:this.checkRoomType = true;
          if (window.location.href.indexOf('calls') > -1) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigate(['calls', showPopup.user.id], {
              queryParams: {
                receive: showPopup.roomId,
                video: showPopup.video,
                roomType:showPopup.roomType?showPopup.roomType:'',
                callerId:showPopup.callerId
            }}).then();
          } else {
            this.openIncomingCallPopup(showPopup.user, showPopup.roomId, showPopup.video,showPopup.roomType, showPopup.callerId);
          }
        } else {
          this.stopCallingSound();
          this.incomingCallModal.close().then();
        }
      }
    });
  }


  notificationClosed(notificationId: number) {
    this.store.dispatch(new RemoveNotificationFromQueue({
      messageId: notificationId
    }))
  }

  notificationClick(notification: NotificationModel) {
    if (notification.notificationType === 'message') {
      this.router.navigate(['chat'], {
        queryParams: {
          room_id: (notification as NotificationMessageModel).roomId
        }
      }).then();
      this.notificationClosed(notification.id);
    }
  }

  redialClick(event: {
    callType: 'audio' | 'video'
  }, notification: NotificationModel) {
    this.router.navigate(['calls', (notification as NotificationCancelledCallModel).userId], {
      queryParams: {
        start: true,
        video: event.callType === 'video'
      }
    }).then();
    this.notificationClosed(notification.id);
  }

  markAsReadClick(notification: NotificationModel) {
    this.notificationClosed(notification.id);
  }

  openIncomingCallPopup(user: UserModel, roomId: string, isVideo: boolean, roomType?:string,callerId?:string) {
    this.incomingCall = {
      user,
      message: null,
      isVideo
    }
    this.incomingCallModal.swalOptions = {
      allowOutsideClick: false,
      width: 250,
      showConfirmButton: false,
      position: 'top-right',
      customClass: {
        content: 'p-0'
      }
    };
    // this.waitForAnswerSubscription = interval(30000).subscribe(() => {
    //   this.incomingCallDismissed();
    // });
    this.incomingCallModal.fire().then(dialogResult => {
      if (dialogResult.isConfirmed) {
        this.router.navigate(['calls', user.id], {
          queryParams: {
            receive: roomId,
            video: isVideo,
            roomType:roomType?roomType:'',
            callerId
          }
        }).then()
      } else {
        const room = !this.checkRoomType?roomId:callerId;
        this.callService.ignoreCALL({id: room}).subscribe();
      }
      if (dialogResult.value && dialogResult.value.dispatch) {
        this.stopCallingSound();
        this.store.dispatch(new SetShowIncomingCallPopup({
          showCallPopup: {
            show: false,
            user: null,
            video: false,
            roomId: ''
          }
        }));
      }
    });
  }

  incomingCallAnswered() {
    this.incomingCallModal.close({
      isConfirmed: true,
      isDismissed: false,
      isDenied: false,
      value: {
        dispatch: true
      }
    }).then();
    this.stopCallingSound();
  }

  incomingCallDismissed() {
    this.incomingCallModal.close({
      isConfirmed: false,
      isDismissed: true,
      isDenied: true,
      value: {
        dispatch: true
      }
    }).then();
    if (this.waitForAnswerSubscription) {
      this.waitForAnswerSubscription.unsubscribe();
    }
    this.stopCallingSound();
  }

  private playSound() {
    this.dialingSound = new Audio('assets/audio/calling-sound.mp3');
    this.dialingSound.addEventListener('canplaythrough', (event) => {
      this.dialingSound.play().then();
    });
    this.dialingSound.loop = true;
  }

  private stopCallingSound() {
    if (this.dialingSound) {
      this.dialingSound.pause();
    }
  }
}
