import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild} from '@angular/core';
import {PaymentCardModel} from '../../../core/models/payment.model';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'atk-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentCardComponent implements OnInit {
  @ViewChild('createCard', {static: true}) createCardModal: SwalComponent;
  @Input() card: PaymentCardModel;
  @Input() fromBilling: boolean;
  @Output() primaryChange = new EventEmitter<any>();
  @Output() removeEvent = new EventEmitter<any>();
  @Output() setForPayout = new EventEmitter<any>();
  @Output() editCard = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  makePrimary() {
    this.primaryChange.emit(this.card);
  }

  removeCard() {
    this.removeEvent.emit(this.card);
  }

  makePayout() {
    this.setForPayout.emit(this.card);
  }

  public openEditPopup(): void {
    this.createCardModal.swalOptions = {
      showConfirmButton: false,
      showCancelButton: false,
      width: 580,
      padding: 0,
      customClass: {content: 'content-payment-swal'},
      allowOutsideClick: false,
      allowEscapeKey: false,
    };
    this.createCardModal.fire().then();
  }

  public closeClick(e): void {
    if (e) this.editCard.emit();
    this.createCardModal.close().then();
  }
}
