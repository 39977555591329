import { ChangeDetectionStrategy, Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'atk-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements  OnChanges {

  @Input() public data;
  @Input() public meta;
  @Output() public monthChanges = new EventEmitter();
  @Output() public dayChanges = new EventEmitter();
  @ViewChild('dateContent', { read: ElementRef }) public dateContent: ElementRef<any>;
  public days: Date[] = [];
  public active;

  public isActive(date) {
    return moment(date).isSame(this.active);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      const data = changes.data.currentValue;
      this.active = data.days.find((day) => {
        return moment(day).isSame(new Date(2018, 5, 28));
      });
      if (!this.active) {
        data.days = data.days.map((day) => moment(day));
        this.active = moment.max(data.days);
      }
    }
  }

  public changeMonth(key: 'next' | 'prev') {
    this.monthChanges.emit(key); 
  }

  public selectDate(date) {
    this.active = date;
    this.dayChanges.emit(date);
  }

  scrollLeft() {
    // this.dateContent.nativeElement.scrollTo({ left: (this.dateContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    let dates = this.data.days.pop();
    this.data.days.unshift(dates);
  }

 scrollRight() {
  // this.dateContent.nativeElement.scrollTo({ left: (this.dateContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  let dates = this.data.days.shift();
  this.data.days.push(dates);
  }

}