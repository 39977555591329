import {Action} from '@ngrx/store';
import {StateActionTypes} from '../../../../store/actions';
import {PharmacyModel} from '../models/pharmacy.model';
import {ClinicModel} from '../models/clinic.model';
import {TitleModel} from '../../../../store/model/title.model';
import {UserModel} from '../../../user/store/models/user.model';
import {ProfessionalModel} from '../models/professional.model';

export enum ClinicsActionTypes {
  LoadClinics = '[Clinics] Load Clinics',
  LoadClinicsSuccess = '[Clinics API] Load Clinics Success',
  LoadClinicsFailure = '[Clinics API] Load Clinics Failure',

  SetClinicsSearch = '[Clinics API] Set Clinics Search',
  SetClinicsPagination = '[Clinics API] Set Clinics Pagination',

  LoadPharmacies = '[Pharmacies] Retrieve pharmacies information',
  LoadPharmaciesSuccess = '[Pharmacies API] Retrieve pharmacies information Success',
  LoadPharmaciesFailure = '[Pharmacies API] Retrieve pharmacies information Failure',

  AddFavouriteClinic = '[Clinics] Add favourite clinic',
  AddFavouriteClinicSuccess = '[Clinics API] Add favourite clinic success',
  AddFavouriteClinicFailure = '[Clinics API] Add favourite clinic failure',

  AddFavouritePharmacy = '[Pharmacies] Add favourite pharmacy',
  AddFavouritePharmacySuccess = '[Pharmacies API] Add favourite pharmacy success',
  AddFavouritePharmacyFailure = '[Pharmacies API] Add favourite pharmacy failure',

  SetCurrentClinicLogo = '[Clinics] set current clinic logo',

  FetchMedicalSpecializations = '[Clinics] fetch medical specializations',
  FetchMedicalSpecializationsSuccess = '[Clinics API] fetch medical specializations success',
  FetchMedicalSpecializationsFailure = '[Clinics API] fetch medical specializations failure',

  FetchProfessionals = '[Clinics] fetch professionals',
  FetchProfessionalsSuccess = '[Clinics API] fetch professionals Success',
  FetchProfessionalsFailure = '[Clinics API] fetch professionals Failure',

  FetchDoctors = '[Doctors] fetch ',
  FetchDoctorsSuccess = '[Doctors API] fetch  Success',
  FetchDoctorsFailure = '[Doctors API] fetch  Failure'
}

export class LoadClinics implements Action {
  readonly type = ClinicsActionTypes.LoadClinics;

  constructor(public payload: {
    types?: number[],
    specializations?: number[],
    search?: string,
    page?: number,
    count?: number,
    country_iso3_code?: string
  }) {
  }
}

export class LoadClinicsSuccess implements Action {
  readonly type = ClinicsActionTypes.LoadClinicsSuccess;

  constructor(public payload: {
    clinicsList: ClinicModel[]
  }) {
  }
}

export class LoadClinicsFailure implements Action {
  readonly type = ClinicsActionTypes.LoadClinicsFailure;

  constructor(public payload: { error: any }) {
  }
}

export class SetClinicSearch implements Action {
  readonly type = ClinicsActionTypes.SetClinicsSearch;

  constructor(public payload: {
    searchClinicsField: string
  }) {
  }
}

export class SetClinicPagination implements Action {
  readonly type = ClinicsActionTypes.SetClinicsPagination;

  constructor(public payload: {
    paginationConfig: {
      page?: number,
      count?: number,
      total?: number
    }
  }) {
  }
}


export class LoadPharmacies implements Action {
  readonly type = ClinicsActionTypes.LoadPharmacies;

  constructor(public payload: {
    id?: number,
    country_iso3_code?: string
    search?: string,
    page?: number,
    count?: number,
  }) {
  }
}

export class LoadPharmaciesSuccess implements Action {
  readonly type = ClinicsActionTypes.LoadPharmaciesSuccess;

  constructor(public payload: {
    pharmaciesList: PharmacyModel[]
  }) {
  }
}

export class LoadPharmaciesFailure implements Action {
  readonly type = ClinicsActionTypes.LoadPharmaciesFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class AddFavouriteClinic implements Action {
  readonly type = ClinicsActionTypes.AddFavouriteClinic;

  constructor(public payload: {
    clinic_id: string[];
  }) {
  }
}

export class AddFavouriteClinicSuccess implements Action {
  readonly type = ClinicsActionTypes.AddFavouriteClinicSuccess;

  constructor() {
  }
}

export class AddFavouriteClinicFailure implements Action {
  readonly type = ClinicsActionTypes.AddFavouriteClinicFailure;

  constructor(public  payload: {
    error: any
  }) {
  }
}


export class AddFavouritePharmacy implements Action {
  readonly type = ClinicsActionTypes.AddFavouritePharmacy;

  constructor(public payload: {
    pharmacy_id: number[];
  }) {
  }
}

export class AddFavouritePharmacySuccess implements Action {
  readonly type = ClinicsActionTypes.AddFavouritePharmacySuccess;

  constructor() {
  }
}

export class AddFavouritePharmacyFailure implements Action {
  readonly type = ClinicsActionTypes.AddFavouritePharmacyFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}


export class FetchMedicalSpecializations implements Action {
  readonly type = ClinicsActionTypes.FetchMedicalSpecializations;

  constructor() {
  }
}

export class FetchMedicalSpecializationsSuccess implements Action {
  readonly type = ClinicsActionTypes.FetchMedicalSpecializationsSuccess;

  constructor(public payload: {
    medicalSpecialization: TitleModel[];
  }) {

  }
}

export class FetchMedicalSpecializationsFailure implements Action {
  readonly type = ClinicsActionTypes.FetchMedicalSpecializationsFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}


export class FetchProfessionals implements Action {
  readonly type = ClinicsActionTypes.FetchProfessionals;

  constructor(public payload: {
    clinic_id: string,
    search?: string,
    page?: number,
    count?: number
  }) {
  }
}

export class FetchProfessionalsSuccess implements Action {
  readonly type = ClinicsActionTypes.FetchProfessionalsSuccess;

  constructor(public payload: {
    professionals: ProfessionalModel[];
  }) {

  }
}

export class FetchProfessionalsFailure implements Action {
  readonly type = ClinicsActionTypes.FetchProfessionalsFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class SetCurrentClinicLogo implements Action {
  readonly type = ClinicsActionTypes.SetCurrentClinicLogo;

  constructor(public payload: {
    logoUrl: string
  }) {
  }
}


export class FetchDoctors implements Action {
  readonly type = ClinicsActionTypes.FetchDoctors;

  constructor(public payload: {
  }) {
  }
}

export class FetchDoctorsSuccess implements Action {
  readonly type = ClinicsActionTypes.FetchDoctorsSuccess;

  constructor(public payload: {
    filterDoctors: any;
  }) {

  }
}

export class FetchDoctorsFailure implements Action {
  readonly type = ClinicsActionTypes.FetchDoctorsFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}


export type ClinicsActions =
  LoadClinics |
  LoadClinicsSuccess |
  LoadClinicsFailure |
  SetClinicSearch |
  SetClinicPagination |
  LoadPharmacies |
  LoadPharmaciesSuccess |
  LoadPharmaciesFailure |
  AddFavouriteClinic |
  AddFavouriteClinicSuccess |
  AddFavouriteClinicFailure |
  AddFavouritePharmacy |
  AddFavouritePharmacySuccess |
  AddFavouritePharmacyFailure |
  FetchMedicalSpecializations |
  FetchMedicalSpecializationsSuccess |
  FetchMedicalSpecializationsFailure |
  FetchProfessionals |
  FetchProfessionalsSuccess |
  FetchProfessionalsFailure |
  SetCurrentClinicLogo |
  FetchDoctors |
  FetchDoctorsSuccess |
  FetchDoctorsFailure;

