import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(seconds: number): any {
    const duration = moment.duration(seconds, 'seconds') as Duration;
    const result = duration.format('HH:mm:ss');
    return result.length === 2 ? `00:${result}` : result;
  }

}

interface Duration extends moment.Duration {
  format: (template?: string, precision?: number, settings?: DurationSettings) => string;
}

interface DurationSettings {
  forceLength: boolean;
  precision: number;
  template: string;
  trim: boolean | 'left' | 'right';
}
