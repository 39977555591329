import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class EcgChartBuilder extends ChartsBuilder {

  public static chartName = 'Ecg';
  public static measurementUnits = 'rate';
  public static axisNames: string[] = ['Ecg'];

  constructor(element: ElementRef) {
    super(element);
    super.yAxisTickInterval = .02;
    super.setXAxis({
      type: 'linear',
      allowDecimals: false,
      ordinal: false,
      gridLineWidth: 1,
      gridLineColor: '#f3f3f3',
      lineColor: '#f3f3f3',
      tickColor: '#f3f3f3',
      labels: {
        enabled: false,
        rotation: 0,
        style: {
          color: '#848484',
          fontSize: '14px'
        },
        x: 0,
        y: 30,
        reserveSpace: true,
      },
      tickPixelInterval: 20,
    })
    super.setPopoverOptions({
      enabled: true,
      className: 'hide-header-tooltip-for-highstock',
      valueDecimals: 2,
      valueSuffix: this.valueSuffix,
    });
    super.setRangeOptions({enabled: false});
  }

  init() {
    return super.initChart('stock');
  }


}
