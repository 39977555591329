import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConstants} from '../../app.constants';
import {Observable} from 'rxjs';
import {HttpWrapper, HttpWrapperArray} from '../../store/model/http';
import {PeopleModel} from '../ehr/record-access/people/people.model';
import {ClinicModel} from '../clinics/store/models/clinic.model';

@Injectable({
  providedIn: 'root'
})
export class TreatmentsDataService {

  constructor(private api: HttpClient) { }

  public load(params) {
    return this.api.post<any>(`${AppConstants.baseUrl}/treatments/shared/list`, params);
  }

  public getPrescriptions(params) {
    return this.api.post<any>(`${AppConstants.baseUrl}/treatments/prescriptions`, params);
  }

  public getProcedures(params) {
    return this.api.post<any>(`${AppConstants.baseUrl}/treatments/prescriptions/procedures`, params);
  }

  public exportPDF(params): Observable<any> {
    return this.api.post(`${AppConstants.baseUrl}/treatments/export`, params, { responseType: 'blob' });
  }

  getPeopleList(params) {
    return this.api.post<HttpWrapperArray<PeopleModel>>(`${AppConstants.baseUrl}/cloud/contacts`, params);
  }

  getPeopleAccessList(params) {
    return this.api.post<HttpWrapperArray<PeopleModel>>(`${AppConstants.baseUrl}/sharing/permissions/shared/by_me`, params);
  }

  deletePeopleAccess(params) {
    return this.api.post(`${AppConstants.baseUrl}/sharing/permissions/shared/by_me/drop`, params);
  }

  addPeopleAccessPermission(params){
    return this.api.post<HttpWrapper<PeopleModel>>(`${AppConstants.baseUrl}/sharing/permissions/user`, params);
  }

  getClinicList(params) {
    return this.api.post<HttpWrapperArray<ClinicModel>>(`${AppConstants.baseUrl}/clinics/list`, params);
  }

  getClinicAccessList(params) {
    return this.api.post<HttpWrapperArray<ClinicModel>>(`${AppConstants.baseUrl}/clinics/authorize/list`, params);
  }

  deleteClinicAccess(params) {
    const options = {
      headers: {},
      body: params
    }
    return this.api.delete(`${AppConstants.baseUrl}/clinics/authorize`, options);
  }

  addClinicPermission(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/authorize`, params);
  }

  getClinicDetails(clinicId) {
    return this.api.get<HttpWrapper<ClinicModel>>(`${AppConstants.baseUrl}/clinics/details/${clinicId}`);
  }

  public getMedicinesList(id = null) {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    return this.api.get<HttpWrapper<any>>(`${AppConstants.baseUrl}/medical/medicines`,  {params});
  }

  public getTrackers(category) {
    let params = new HttpParams();
    params = params.append('category', category);
    return this.api.get<HttpWrapper<any>>(`${AppConstants.baseUrl}/clinics/trackers`, {params});
  }

  public getBodyParts() {
    return this.api.get<HttpWrapper<any>>(`${AppConstants.baseUrl}/clinics/body_parts`);
  }

  public createTreatment(params) {
    return this.api.post(`${AppConstants.baseUrl}/treatments`, params);
  }
}
