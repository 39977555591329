import { NotificationsService } from 'src/app/features/notifications/notifications.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {MatButton} from '@angular/material/button';
import {select, Store} from '@ngrx/store';

import * as fromSelectors from 'src/app/features/user/store/selectors';
import * as fromRootSelectors from 'src/app/store/selectors';
import {LoginUser} from 'src/app/features/user/store/actions';
import {filter, first, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {FormControl, Validators} from '@angular/forms';
import {LoadAvailableCountries} from '../../../../store/actions';

@Component({
  selector: 'atk-login',
  templateUrl: './login.route.html',
  styleUrls: ['./login.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRoute implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();

  @ViewChild('signIn', {static: true}) signInButton: MatButton;

  sub: Subscription;

  identityPhone = true;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];

  phone = new FormControl(null, [Validators.required]);
  password = new FormControl(null, [Validators.required]);

  email: string;
  telephone: string;

  keepSignIn = true;

  loginError: 'login' | 'pass';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store, private readonly notificationService:NotificationsService,
    public ref: ChangeDetectorRef
  ) {
    this.sub = new Subscription();
  }


  ngOnInit() {

    this.store.dispatch(new LoadAvailableCountries());

    this.store.pipe(takeUntil(this.destroy$), select(fromSelectors.userLoggedIn), filter(item => !!(item))).subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.store.pipe(takeUntil(this.destroy$), select(fromSelectors.userProfile), first()).subscribe(userProfile => {
          if (userProfile.need_to_complete_questionnare) {
            this.store.pipe(takeUntil(this.destroy$), select(fromSelectors.questionnaireSkipLastDate), first()).subscribe(skipLastDate => {
              const skipLastDateExist = !!(skipLastDate);
              const moreThanDayElapsed = moment().diff(moment(skipLastDate), 'day') >= 1;
              if ((skipLastDateExist && moreThanDayElapsed) || !skipLastDateExist) {
                this.router.navigate(['questionnaire'], {relativeTo: this.route.parent.parent}).then();
              } else {
                this.router.navigate(['']).then();
              }
            })
          } else {
            this.router.navigate(['']).then();
          }
        });
      }
    });

    this.store.pipe(takeUntil(this.destroy$), select(fromSelectors.loginError)).subscribe(loginError => {
      this.loginError = loginError ? loginError.type : null;
      this.ref.markForCheck();
    });

    this.store.pipe(takeUntil(this.destroy$), select(fromRootSelectors.getDialCodeCountries)).subscribe(countries => {
      this.preferredCountries = countries;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  goToForgotPin(event: MouseEvent) {
    event.preventDefault();
  }


  signInClick() {
    this.notifyMe();
    const login = this.identityPhone ? this.phone.value.dialCode + this.phone.value.number.replace(/\s+/g, '') : this.email;
    this.store.dispatch(new LoginUser({
      login,
      password: this.password.value
    }));
  }

  private notifyMe(): void {
    this.notificationService.notify('Notification granted');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
