import { ChartDataModel } from '../models/chart-data.model';
import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class BloodPressureChartBuilder extends ChartsBuilder {

  public static chartName = 'Blood Pressure';
  public static measurementUnits = 'mmHg';
  public static axisNames: string[] = ['Systolic', 'Diastolic'];

  constructor(element: ElementRef) {
    super(element);

    // super.addZones({
    //   value: 125,
    //   color: '#42a3ed'
    // });
    // super.addZones({
    //   value: 140,
    //   color: '#f2d273'
    // });
    // super.addZones({
    //   color: '#ed4242'
    // });
    super.yLabelFormatter = (input: string) => {
      return input + 'mmHg';
    }
  }

  init() {
    return super.initChart('stock');
  }

  addSeries(monitoringChartData: ChartDataModel[], name: string) {
    if (super.seriesCount === 0) {
      super.addSeries(monitoringChartData, name, 'spline',null, [{
        value: 120,
        color: '#42a3ed',
      },{
        value: 130,
        color: '#ffec00',
      },{
        value: 140,
        color: '#ffb600',
      },{
        value: 180,
        color: '#ba3a02',
      },{
        color: '#990711',
      }]);
    }else if (super.seriesCount === 1){
      super.addSeries(monitoringChartData, name, 'spline',null, [{
        value: 80,
        color: '#42a3ed',
      },{
        value: 90,
        color: '#ffb600',
      },{
        value: 120,
        color: '#ba3a02',
      },{
        color: '#990711',
      }]);
    }else{
      super.addSeries(monitoringChartData, name, 'spline');
    }


  }
}
