import {StateActions, StateActionTypes} from '../actions';
import {CountryModel} from '../model/country.model';
import {ActionCreator, NotAllowedCheck, TypedAction} from '@ngrx/store/src/models';


export const stateFeatureKey = 'state';

export interface State {
  authToken: string,

  testText: string;
  showLoginPopup: {
    action: { new(payload: any) };
    payload: any;
  };
  showLoginPopupNew: {
    action: ActionCreator<string, () => TypedAction<string>> | ActionCreator<string, (props: object & NotAllowedCheck<object>) => object & TypedAction<string>>;
    payload: any;
  };
  inCall: boolean;
  registrationPushToken: string;
  questionnaireSkipLastDate: string;
  availableCountries: CountryModel[],
  lastHeartbeat: number;
}

export const initialState: State = {
  authToken: '',
  testText: '',
  showLoginPopup: null,
  showLoginPopupNew: null,
  inCall: false,
  registrationPushToken: null,
  questionnaireSkipLastDate: '',
  availableCountries: [],
  lastHeartbeat: 0,
};

export function reducer(state = initialState, action: StateActions): State {
  switch (action.type) {
    case StateActionTypes.AuthTokenChanged: {
      return {
        ...state,
        authToken: action.payload.token
      }
    }

    case StateActionTypes.LoginNeeded: {
      return {
        ...state,
        showLoginPopup: {
          action: action.payload.action,
          payload: action.payload.payload
        }
      }
    }

    case StateActionTypes.LoginNeededNew: {
      return {
        ...state,
        showLoginPopupNew: {
          action: action.payload.action,
          payload: action.payload.payload
        }
      }
    }

    case StateActionTypes.TestAction: {
      return {
        ...state,
        testText: action.payload.text
      }
    }

    case StateActionTypes.SetInCallStatus: {
      return {
        ...state,
        inCall: action.payload.inCall
      }
    }

    case StateActionTypes.UpdateRegistrationToken: {
      return {
        ...state,
        registrationPushToken: action.payload.registrationToken,
      }
    }

    case StateActionTypes.LoadAvailableCountriesSuccess: {
      return {
        ...state,
        availableCountries: action.payload.countries,
      }
    }

    case StateActionTypes.LoadAvailableCountriesFailure: {
      return {
        ...state,
        availableCountries: [],
      }
    }


    default:
      return state;
  }
}
