import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'atk-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input('id') id: string;
  @Input('checked') checked: boolean;
  @Input('text') text: string;
  @Input('reversed') reversed = false;
  @Input('innerHTML') innerHTML: string

  @Output('checkedChange') checkedChange = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  checkedChanged() {
    this.checkedChange.emit(this.checked);
  }
}
