import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CallApiService {

  constructor(
    private api: HttpClient
  ) { }

  public startCall(params) {

    return this.api.post(`${AppConstants.baseUrl}/webrtcapi/api/calls/start-call`, params);
  }

  public joinCall(params) {

    return this.api.post(`${AppConstants.baseUrl}/webrtcapi/api/calls/join-call`, params);
  }

  public ignoreCALL(params) {

    return this.api.post(`${AppConstants.baseUrl}/webrtcapi/api/calls/ignore-call`, params);
  }

  public cancelCALL(params) {

    return this.api.post(`${AppConstants.baseUrl}/webrtcapi/api/calls/cancel-call`, params);
  }

  public initCall = (params: {
    callerType: string,
    calleeType: string,
    callerId: string,
    calleeId: string,
    videoCall: boolean
  }) => this.api.post<{ roomId: string }>(`${AppConstants.baseCallUrl}/api/v1/start-call`, params);


  public endCall = (params: {
    roomId: string
  }) => this.api.post<any>(`${AppConstants.baseCallUrl}/api/v1/end-call`, params);

  public cancelCall = (params: {
    roomId: string
  }) => this.api.post<any>(`${AppConstants.baseCallUrl}/api/v1/cancel-call`, params);

  public updateDevice = (params: {
    fcmToken: string
  }) => this.api.patch(`${AppConstants.baseUrl}/api/v3/devices`, params);

  public requestToDoctorAllow(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/waiting_rooms/call/request`, params);
  }

  public checkRequestStatus(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/waiting_rooms/call/request/status/check`, params);
  }
  public checkPosition(id) {
    return this.api.get(`${AppConstants.baseUrl}/clinics/waiting_rooms/queue/position?room_id=${id}`);
  }

  public ignoreCall(params) {
    return this.api.post(`${AppConstants.baseCallUrl}/api/v1/ignore-call`, params);
  }

  public receiveCall(params) {
    return this.api.post(`${AppConstants.baseCallUrl}/api/v1/receive-call`, params);
  }

  // event-call api

  public requestEventToDoctor(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/event/calls/request`, params);
  }
  public checkCurrentStatus(params) {
    return this.api.post(`${AppConstants.baseUrl}/clinics/event/calls/request/status`, params);
  }

}
