import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromSelectors from 'src/app/features/user/store/selectors';
import {first} from 'rxjs/operators';
import * as fromReducers from '../../../../../store/reducers';
import {ActivatedRoute, Router} from '@angular/router';

import {getFullName} from '../../../../../shared/helpers';
import {MatButton} from '@angular/material/button';
import {interval} from 'rxjs';
import {RegistrationPin} from '../../../../../features/user/store/actions';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.route.html',
  styleUrls: ['./pin.route.scss']
})
export class PinRoute implements OnInit {

  @ViewChild('nextButton', {static: false}) nextButton: MatButton;

  retype = false;

  userName: string;
  pinError = false;

  pin: string;
  originPin: string;

  constructor(
    private store: Store<fromReducers.State>,
    private router: Router, public ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.retype = this.route.snapshot.data['retype'];
    this.store.pipe(select(fromSelectors.registrationFlow), first()).subscribe(next => {
      this.userName = getFullName({
        id: '-1',
        firstName: next.firstName,
        lastName: next.lastName
      });
      if (this.retype) {
        this.originPin = next.pin;
        console.log(this.originPin);
      }
    });
  }

  checkPin() {
    this.pinError = this.retype && this.pin !== this.originPin;
    if (!this.retype || !this.pinError) {
      interval(0).pipe(first()).subscribe(() => {
        console.log('focus');
        this.nextButton.focus();
      });

    }
  }

  backClick() {
    if (this.retype) {
      this.router.navigate(['pin'], {relativeTo: this.route.parent}).then();
    } else {
      this.router.navigate(['.'], {relativeTo: this.route.parent}).then();

    }
  }

  nextClick() {
    if (this.retype) {
      this.router.navigate(['phone'], {relativeTo: this.route.parent}).then();
    } else {
      this.store.dispatch(new RegistrationPin({pin: this.pin}));
      this.router.navigate(['pin-retype'], {relativeTo: this.route.parent}).then();
    }
  }
}
