import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import { UiService } from '../core/services/ui.service';


@Component({
  selector: 'atk-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit {
  orientation: 'top' | 'left';

  public showProgressBar$: Observable<boolean>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly uiService: UiService
  ) { }

  ngOnInit(): void {
    this.orientation = this.route.snapshot.data['orientation'];
    this.getUiState();
  }

  private getUiState(): void {
    this.showProgressBar$ = this.uiService.getProgressBarState();
  }

}
