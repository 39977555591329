import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import * as fromSelectors from 'src/app/features/user/store/selectors';
import {first, skip} from 'rxjs/operators';
import {COUNTRY_CODES} from '../../../../../store/helpers/helper';
import {Subscription} from 'rxjs';
import {RegistrationInitSession} from '../../../../../features/user/store/actions';

@Component({
  selector: 'atk-telephone-identity',
  templateUrl: './telephone-identity.route.html',
  styleUrls: ['./telephone-identity.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelephoneIdentityRoute implements OnInit, OnDestroy {
  sub: Subscription;
  userName: string;
  inviteId: any;
  countryPhoneCodes = COUNTRY_CODES;
  selectedCountryCode = COUNTRY_CODES[0];
  telephone: string;
  isError: boolean;
  errorMsg: string;

  constructor( private store: Store, private router: Router,
    private route: ActivatedRoute, private ref: ChangeDetectorRef) {
    this.sub = new Subscription();
  }


  ngOnInit(): void {
    const mobileNumber = JSON.parse(localStorage.getItem('mobNumber'));
    if (mobileNumber) {
      this.selectedCountryCode = this.countryPhoneCodes.filter(item => item.phoneCode === mobileNumber.countrycode)[0];
      this.telephone = mobileNumber.telephone;
    }

    this.sub.add(this.store.pipe(select(fromSelectors.registrationFlow), first()).subscribe(next => {
      this.userName = next.firstName;
      if (next.invite_id) {
        this.inviteId = next.invite_id;
      }
      this.ref.markForCheck();
    }));
    this.sub.add(this.store.pipe(select(fromSelectors.registrationSessionError)).subscribe(next => {
      if (next) {
        this.errorMsg = next['message'];
      }
      this.isError = !!(next);
      this.ref.markForCheck();
    }));
    this.sub.add(this.store.pipe(select(fromSelectors.registrationSessionId), skip(1)).subscribe(next => {
      if (next) {
        this.router.navigate(['phone-confirm'], {relativeTo: this.route.parent}).then();
      }
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  filterNumbers(event: KeyboardEvent): boolean {
    if (!event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      !event.metaKey &&
      event.key.length === 1 && !/\d/g.test(event.key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  sendPhoneToConfirm(): void {
    const params = {
      telephone: this.selectedCountryCode.phoneCode + this.telephone,
      role: 'patient',
      country: this.selectedCountryCode.countryCode
    };
    if (this.inviteId) {
      params['invite_id'] = this.inviteId;
    }
    this.store.dispatch(new RegistrationInitSession(params));
    const mobileNum = { countrycode: this.selectedCountryCode.phoneCode, telephone: this.telephone };
    localStorage.setItem('mobNumber', JSON.stringify(mobileNum));
  }
}
