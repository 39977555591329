import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { KeepSession,
  LoadAvailableCountries,
  LoadAvailableCountriesFailure,
  LoadAvailableCountriesSuccess, StartHeartbeat,
  StateActionTypes, StopHeartbeat,
  UpdateRegistrationToken
} from '../actions';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {CallApiService} from '../../features/chat-and-call/call/call-api.service';
import {CloudService} from '../../core/cloud.service';
import {of} from 'rxjs';
import * as moment from 'moment';
import {SessionService} from '../../core/session.service';


@Injectable()
export class StoreEffects {


  updatePushRegistrationToken$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateRegistrationToken>(StateActionTypes.UpdateRegistrationToken),
    tap(action => this.callApiService.updateDevice({
      fcmToken: action.payload.registrationToken
    }).subscribe())
  ), {dispatch: false});

  loadAvailableCountries$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAvailableCountries>(StateActionTypes.LoadAvailableCountries),
    exhaustMap(() => this.cloudService.getAvailableCountries().pipe(
      map(response => new LoadAvailableCountriesSuccess({countries: response.data})),
      catchError(error => of(new LoadAvailableCountriesFailure({error: error})))
    ))
  ));

  startHeartbeat$ = createEffect(() => this.actions$.pipe(
    ofType<StartHeartbeat>(StateActionTypes.StartHeartbeat),
    tap(() => this.sessionService.startHeartBeating())
  ), {dispatch: false});

  stopHeartbeat$ = createEffect(() => this.actions$.pipe(
    ofType<StopHeartbeat>(StateActionTypes.StopHeartbeat),
    tap(() => this.sessionService.stopHeartBeating())
  ), {dispatch: false});

  keepSession$ = createEffect(() => this.actions$.pipe(
    ofType<KeepSession>(StateActionTypes.KeepSession),
    exhaustMap(() => of(new StartHeartbeat()))
  ));


  constructor(
    private actions$: Actions,
    private callApiService: CallApiService,
    private cloudService: CloudService,
    private sessionService: SessionService
  ) {
  }


}

