import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'atk-children-content',
  templateUrl: './children-content.route.html',
  styleUrls: ['./children-content.route.scss']
})
export class ChildrenContentRoute implements OnInit {

  acceptClick: boolean = false;
  showNotification: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute,private location: Location) { }

  ngOnInit(): void {
  }

  accept(){
    this.acceptClick= true;
  }

  decline() {
    this.location.back();
  }

  askInvite() {
    this.router.navigate(['invite'], {relativeTo: this.route}).then();
  }

  close() {
    this.showNotification = false;
  }
}
