export const COUNTRY_CODES: {
  name: string;
  countryCode: string;
  phoneCode: string;
}[] = [
  {
    name: 'Denmark',
    countryCode: 'DNK',
    phoneCode: '+45',
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    phoneCode: '+34',
  },
  {
    name: 'Switzerland',
    countryCode: 'CHE',
    phoneCode: '+41',
  },
  {
    name: 'UK',
    countryCode: 'GBR',
    phoneCode: '+44',
  },
  {
    name: 'Ukraine',
    countryCode: 'UKR',
    phoneCode: '+380',
  },
  {
    name: 'USA',
    countryCode: 'USA',
    phoneCode: '+1',
  }
];
