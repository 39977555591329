import {UserModel} from '../models/user.model';
import {UserActions, UserActionTypes} from '../actions';


export const userFeatureKey = 'user';

export interface UserState {
  user: UserModel;
  userLoggedIn: boolean;
  loginError: {
    type: 'login' | 'pass'
  }
  registrationFlow: {
    firstName: string;
    lastName: string;
    birthday: string;
    gender: string;
    acceptedTerms: boolean;
    pin: string;
    telephone: string;
    registrationSessionId: string;
    registrationError: string;
    telephoneConfirmed: boolean;
    telephoneConfirmationCode: string;
    registrationSuccess: boolean;
    invite_id?: any
    invite_info?: any
  }
  userById: UserModel,
  usersByIds: UserModel[],

  userContacts: UserModel[];

  questionnaireSkipLastDate: string
}

export const initialState: UserState = {
  user: null,
  userLoggedIn: false,
  loginError: null,
  registrationFlow: {
    firstName: '',
    lastName: '',
    birthday: null,
    gender: '',
    acceptedTerms: false,
    pin: '',
    telephone: '',
    registrationSessionId: '',
    registrationError: null,
    telephoneConfirmed: null,
    telephoneConfirmationCode: '',
    registrationSuccess: null,
  },
  userById: null,
  usersByIds: [],
  userContacts: [],

  questionnaireSkipLastDate: ''
};

export function reducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.LoginUser: {
      return {
        ...state,
        loginError: null
      }
    }
    case UserActionTypes.LoginUserSuccess: {
      return {
        ...state,
        user: action.payload.profile,
        userLoggedIn: true
      }
    }
    case UserActionTypes.LoginUserFailure: {
      let error: 'login' | 'pass';
      if (
        action.payload.message === 'Failed to match telephone or verified email via provided login' ||
        action.payload.message === 'user not found'
      ) {
        error = 'login'
      } else if (action.payload.message === 'Invalid login and password combination') {
        error = 'pass'
      } else {
        return state
      }

      return {
        ...state,
        userLoggedIn: false,
        loginError: {
          type: error
        }
      }
    }

    case UserActionTypes.LogoutUserSuccess:{
      return {
        ...state,
        user: null,
        userLoggedIn: false
      }
    }

    case UserActionTypes.LoadUserProfileSuccess: {
      return {
        ...state,
        user: action.payload.user,
        userLoggedIn: true
      }
    }
    case UserActionTypes.LoadUserProfileFailure: {
      return {
        ...state,
        user: {} as UserModel,
        userLoggedIn: false
      }
    }
    case UserActionTypes.RegistrationName: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          ...action.payload
        }
      }
    }

    case UserActionTypes.RegistrationPin: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          pin: action.payload.pin
        }
      }
    }
    case UserActionTypes.RegistrationInitSessionSuccess: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          registrationSessionId: action.payload.registrationSessionId,
          telephone: action.payload.telephone,
          registrationError: null
        }
      }
    }
    case UserActionTypes.RegistrationInitSessionFailure: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          registrationSessionId: '',
          telephone: '',
          registrationError: action.payload.error
        }
      }
    }
    case UserActionTypes.RegistrationFinalizeSessionFailure: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          registrationSessionId: '',
          telephone: '',
          registrationError: action.payload.error
        }
      }
    }

    case UserActionTypes.SendPhoneConfirmationCode: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          telephoneConfirmed: null,
          telephoneConfirmationCode: action.payload.code
        }
      }
    }
    case UserActionTypes.SendPhoneConfirmationCodeSuccess: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          telephoneConfirmed: true,

        }
      }
    }

    case UserActionTypes.SendPhoneConfirmationCodeFailure: {
      return {
        ...state,
        registrationFlow: {
          ...state.registrationFlow,
          telephoneConfirmed: false,
          telephoneConfirmationCode: ''
        }
      }
    }
    case UserActionTypes.RegistrationFinalizeSessionSuccess: {
      return {
        ...state,
        user: action.payload.user.profile,
        userLoggedIn: true,
        registrationFlow: {
          ...state.registrationFlow,
          registrationSuccess: true
        }
      }
    }
    case UserActionTypes.FetchUserProfileSuccess: {
      return {
        ...state,
        userById: action.payload.user
      }
    }

    case UserActionTypes.FetchUserProfileFailure: {
      return {
        ...state,
        userById: null
      }
    }

    case UserActionTypes.FetchUsersByIdsSuccess: {
      return {
        ...state,
        usersByIds: action.payload.users
      }
    }
    case UserActionTypes.FetchUsersByIdsFailure: {
      return {
        ...state,
        usersByIds: []
      }
    }

    case UserActionTypes.FetchContactsSuccess: {
      return {
        ...state,
        userContacts: action.payload.users
      }
    }
    case UserActionTypes.FetchContactsFailure: {
      return {
        ...state,
        userContacts: [],
      }
    }
    default:
      return state;
  }
}
