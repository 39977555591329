import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../../../../../store/reducers';
import {ActivatedRoute, Router} from '@angular/router';

import * as fromSelectors from 'src/app/features/user/store/selectors';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import * as moment from 'moment';
import {RegistrationFinalizeSession, SendPhoneConfirmationCode} from '../../../../../features/user/store/actions';
import {TreatmentsDataService} from '../../../../../features/treatments-data/treatments-data.service';

@Component({
  selector: 'atk-confirmation-code',
  templateUrl: './confirmation-code.route.html',
  styleUrls: ['./confirmation-code.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationCodeRoute implements OnInit, OnDestroy {

  sub: Subscription

  code: string;
  codePassed: 'passed' | 'wrong' | 'none' = 'none';
  registrationSessionId: string;
  registrationFlow = null;
  errorMsg: string;
  isError: boolean;

  constructor( private store: Store<fromReducers.State>,  private router: Router,
    private treatmentsService: TreatmentsDataService,
    private route: ActivatedRoute, private ref: ChangeDetectorRef) {
    this.sub = new Subscription();
  }

  ngOnInit(): void {
    this.sub.add(this.store.pipe(select(fromSelectors.registrationTelephoneConfirmed)).subscribe(next => {
      if (next === null) {
        this.codePassed = 'none';
      } else {
        this.codePassed = next ? 'passed' : 'wrong';
        if (this.codePassed === 'passed') {
          this.nextClick();
        }
      }
      this.ref.markForCheck()
    }));

    this.sub.add(this.store.pipe(select(fromSelectors.registrationSessionId)).subscribe(next => {
      this.registrationSessionId = next
    }));

    this.sub.add(this.store.pipe(select(fromSelectors.registrationSuccess)).subscribe(next => {
      if (next) {
        if (this.registrationFlow.invite_info) {
          this.treatmentsService.addClinicPermission({
            clinic_id: this.registrationFlow.invite_info.clinic.id
          }).subscribe(response => {});
        }
        this.router.navigate(['successful'], {relativeTo: this.route.parent}).then();
      }
    }));
    this.sub.add(this.store.pipe(select(fromSelectors.registrationSessionError)).subscribe(next => {
      if (next) {
        this.errorMsg = next['message'];
      }
      this.isError = !!(next);
      this.ref.markForCheck()
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  checkCode(): void {
    this.store.dispatch(new SendPhoneConfirmationCode({
      sessionId: this.registrationSessionId,
      code: this.code
    }))
  }

  backClick(): void {
    this.router.navigate(['phone'], {relativeTo: this.route.parent}).then();
  }

  nextClick(): void {
    this.store.pipe(select(fromSelectors.registrationFlow), first()).subscribe(registrationFlow => {
      this.registrationFlow = registrationFlow;
      this.store.dispatch(new RegistrationFinalizeSession(registrationFlow));
    });
    localStorage.removeItem('mobNumber');
  }
}
