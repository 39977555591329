import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './store/effects';
import {StoreModule} from '@ngrx/store';
import * as fromReducers from 'src/app/features/user/store/reducers';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


@NgModule({
  declarations: [ProfileEditComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    StoreModule.forFeature(fromReducers.userFeatureKey, fromReducers.reducer),
    EffectsModule.forFeature([UserEffects])
  ],
  exports:[ProfileEditComponent]
})
export class UserModule {
}
