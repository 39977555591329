import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {
  CallActionTypes, CancelCall, CancelCallFailure, CancelCallSuccess,
  EndCall,
  EndCallFailure,
  EndCallSuccess,
  InitCall,
  InitCallFailure,
  InitCallSuccess
} from '../actions';
import {AppService} from '../../../../../app.service';
import {CallApiService} from '../../call-api.service';


@Injectable()
export class CallEffects {


  initCall$ = createEffect(() => this.actions$.pipe(
    ofType<InitCall>(CallActionTypes.InitCall),
    map(action => {
      return {
        ...action.payload,
        callerType: 'patient',
        calleeType: 'doctor',
      }
    }),
    exhaustMap(payload =>{
      const {mediaStream, ..._payload} = payload;
      return this.callApiService.initCall(_payload).pipe(
        map(response => new InitCallSuccess({
          roomId: response.roomId,
          isVideo: payload.videoCall,
          mediaStream
        })),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, InitCall, payload, () => {
            return of(new InitCallFailure({error: error.error}));
          })
        })
      )
    })
  ))


  endCall$ = createEffect(() => this.actions$.pipe(
    ofType<EndCall>(CallActionTypes.EndCall),
    map(action => action.payload),
    exhaustMap(payload =>
      this.callApiService.endCall(payload).pipe(
        map(response => new EndCallSuccess({response})),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, EndCall, payload, () => {
            return of(new EndCallFailure({error: error.error}));
          })
        })
      )
    )
  ))


  cancelCall$ = createEffect(() => this.actions$.pipe(
    ofType<CancelCall>(CallActionTypes.CancelCall),
    map(action => action.payload),
    exhaustMap(payload =>
      this.callApiService.cancelCall(payload).pipe(
        map(response => new CancelCallSuccess({response})),
        catchError((error: HttpErrorResponse) => {
          return this.appService.catchUnauthorized(error, CancelCall, payload, () => {
            return of(new CancelCallFailure({error: error.error}));
          })
        })
      )
    )
  ))

  constructor(
    private actions$: Actions,
    private callApiService: CallApiService,
    private appService: AppService,
  ) {
  }

}
