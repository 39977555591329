import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-snackbar',
  templateUrl: './success-snackbar.component.html',
  styleUrls: ['./success-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessSnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: any,
    private readonly snackBarRef: MatSnackBarRef<SuccessSnackbarComponent>,
  ) { }

  public close(): void {
    this.snackBarRef.dismiss();
  }

}
