import { Pipe, PipeTransform } from '@angular/core';
import {getFullName} from '../helpers';

@Pipe({
  name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return getFullName(value);
  }

}
