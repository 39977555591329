import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild
} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import * as fromSelectors from 'src/app/store/selectors';
import {select, Store} from '@ngrx/store';
import {filter, first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {AppConstants} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.store.pipe(select(fromSelectors.getAuthToken), first()),
      this.store.pipe(
        select(fromUserSelectors.userProfile),
        filter(item => !!(item)),
        first()
      ),
    ]).pipe(
      map(([authToken, userProfile]) => {
        if (authToken && userProfile) {
          return true;
        } else {
          return this.router.createUrlTree(['user', 'login']);
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

}
