import {NgModuleRef, Type} from '@angular/core';
import {AbstractUserModel} from '../../app/features/user/store/models/abstract-user.model';
import * as moment from 'moment';

export function deepCompare(x, y) {
  if (x === y) {
    return true;
  }
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) {
    return false;
  }
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) {
      return false;
    }
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) {
      continue;
    }
    // if they have the same strict value or identity then they are equal

    if (typeof (x[p]) !== 'object') {
      return false;
    }
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!deepCompare(x[p], y[p])) {
      return false;
    }
    // Objects and Arrays must be tested recursively
  }

  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
    // allows x[ p ] to be set to undefined
  }
  return true;
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function syncModule(module: Type<any>): () => Type<any> {
  return function () {
    return module;
  };
}


export function parseQueryString(url: string): Object {
  let result = {};
  if (url) {
    const paramsObj = {};
    if (url.indexOf('?') < 0) {
      return result;
    }
    url
      .split('?')[1]
      .split('&')
      .forEach(item => {
        const keyValue = item.split('=');
        paramsObj[keyValue[0]] = keyValue[1];
      });
    result = paramsObj;
  }
  return result;
}

export function getFullName(userModel: AbstractUserModel) {
  if (!userModel || !userModel.firstName || userModel.firstName.length === 0 || !userModel.lastName || userModel.lastName.length === 0) {
    return '';
  }
  // const lastName = userModel.lastName.length > 20 ? userModel.lastName.substr(0, 19) + '…' : userModel.lastName;
  return userModel.firstName + ' ' + userModel.lastName;
}


export function getUserAge(userModel: { dob?: string }, format: string = null) {
  let userAge = 0;
  if (userModel && userModel.dob) {
    userAge = moment().diff(moment(userModel.dob, format), 'year');
  }
  return userAge;
}

declare global {
  interface Array<T> {
    unique(path: string): Array<T>;
  }
}

Array.prototype.unique = function (this, path: string) {
  const result = [];
  for (let i = 0; i < this.length; i++) {
    const foundedItems = this.filter(item => item[path] === this[i][path]);
    if (foundedItems && foundedItems.length > 0) {
      const itemToPush = foundedItems[0];
      const existingItemIndex = result.findIndex(item => item[path] === itemToPush[path]);
      if (existingItemIndex < 0) {
        result.push(foundedItems[0]);
      }
    }
  }
  return result;
};




export function saveFile(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.download = fileName;
  a.href = url;
  a.rel = 'noopener';
  a.target = '_blank';
  setTimeout(function () {
    URL.revokeObjectURL(url);

  }, 4E4); // 40s
  setTimeout(function () {
    try {
      a.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
      const evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
        20, false, false, false, false, 0, null);
      a.dispatchEvent(evt);
    }
  }, 0);
}

export function distinct(input: any[]) {
  return input.filter((value, index, self) => self.indexOf(value) === index);
}

export function generateTime15MinutesStep() {
  return [...Array(96).keys()].map(item => {
    const hours = Math.floor(item * 15 / 60);
    const minutes = (item - hours * 4) * 15;
    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
  });  // В Chrome 50+ используется DataView.
}

export function parseHeartRate(value) {
  value = value.buffer ? value : new DataView(value);
  const flags = value.getUint8(0);

  // tslint:disable-next-line:no-bitwise
  const rate16Bits = flags & 0x1;
  const result: {
    heartRate: any;
    rrIntervals: any
  } = null;
  let index = 1;

  if (rate16Bits) {
    result.heartRate = value.getUint16(index, /*littleEndian=*/true);
    index += 2;
  } else {
    result.heartRate = value.getUint8(index);
    index += 1;
  }

  // tslint:disable-next-line:no-bitwise
  const rrIntervalPresent = flags & 0x10;
  if (rrIntervalPresent) {
    const rrIntervals = [];
    for (; index + 1 < value.byteLength; index += 2) {
      rrIntervals.push(value.getUint16(index, /*littleEndian=*/true));
    }
    result.rrIntervals = rrIntervals;
  }

  return result;
}

