import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {CallEffects} from './store/effects';
import {StoreModule} from '@ngrx/store';
import {callFeatureKey, reducer} from './store/reducers';
import { CallComponent } from './call/call.component';
import {MatButtonModule} from '@angular/material/button';
import {InlineSVGModule} from 'ng-inline-svg';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CallSettingsPopupComponent} from './call/call-settings-popup/call-settings-popup.component';
import {SharedModule} from '../../../shared/shared.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [CallComponent, CallSettingsPopupComponent],
  exports: [
    CallComponent
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CallEffects]),
    StoreModule.forFeature(callFeatureKey, reducer),
    MatButtonModule,
    InlineSVGModule,
    MatTooltipModule,
    SharedModule,
    MatSelectModule,
    MatIconModule,
    SweetAlert2Module,
  ]
})
export class CallModule {
}
