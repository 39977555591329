import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {ClinicModel} from '../store/models/clinic.model';
import {select, Store} from '@ngrx/store';
import * as fromClinicsSelectors from '../store/selectors';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SetClinicSearch, SetClinicPagination, LoadClinics} from '../store/actions';

@Component({
  selector: 'atk-choose-clinic',
  templateUrl: './choose-clinic.component.html',
  styleUrls: ['./choose-clinic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseClinicComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>()


  @Output('close')
  closeEmitter = new EventEmitter<ClinicModel>()

  clinicsList: ClinicModel[] = [];
  paginationTotal = 20;
  pageOffset = 0;

  @Input()
  selectedClinic: ClinicModel;

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(select(fromClinicsSelectors.clinicsList), takeUntil(this.destroy$)).subscribe(clinicsList => {
      this.clinicsList = clinicsList;
      if (this.clinicsList.length === 20) {
        this.paginationTotal = this.pageOffset + 21;
      } else {
        this.paginationTotal = 20;
      }
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  searchClinicChange(event) {
    this.store.dispatch(new SetClinicSearch({
      searchClinicsField: event.target.value
    }));
  }

  pageChange({limit, offset}: { limit: number, offset: number }) {
    this.pageOffset = offset;
    this.store.dispatch(new SetClinicPagination({
      paginationConfig: { page: offset }
    }));
  }

  saveSelectedClinic() {
    this.closeEmitter.emit(this.selectedClinic);
  }

  cancelClick() {
    this.closeEmitter.emit();
  }
}
