// export enum activityDataType {
//   TIME = 'Heart rate/time',
//   DISTANCE = 'Heart rate/distance',
// }
export enum HealthDataType {
  HEIGHT = 'height',
  WEIGHT = 'weight',
  SYSTOLIC = 'systolic',
  DIASTOLIC = 'diastolic',
  TEMPERATURE = 'temperature',
  HEART_RATE = 'heart_rate',
  OXYGEN_IN_BLOOD = 'oxygen_in_blood',
  ATRIAL_FIBRILLATION = 'a_fib',
  BLOOD_PRESSURE = 'bloodPressure',
  WELL_BEING = 'well_being',
  ACTIVITY = 'activity',
  TIME = 'Heart rate/time',
  DISTANCE = 'Heart rate/distance',
  ECG = 'ecg',
  ECG_LIVE = 'ecg_live'
}
