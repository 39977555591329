import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';
import * as Highcharts from 'highcharts';

export class EcgLiveChartBuilder extends ChartsBuilder {

  public static chartName = 'Ecg Live';
  public static measurementUnits = 'rate';
  public static axisNames: string[] = ['Ecg'];

  private frameSize = 127;
  private internalCounter = 0;
  private chartGap = 5;

  private readonly disabledMarkerOption: Highcharts.PointOptionsType = {marker: {enabled: false}}

  constructor(element: ElementRef) {
    super(element);
    super.yAxisTickInterval = .02;
    super.yMax = 1.5;
    super.yMin = -1;
    super.disableMarkers();
    super.setXAxisType('linear');
    super.setBoost(true);
    super.setPlotAnimation(false);
    super.setXAxis({
      min: 0,
      max: this.frameSize,
      zoomEnabled: false,
      type: 'linear',
      allowDecimals: false,
      scrollbar: {
        enabled: false,
        height: 0
      },
      ordinal: false,
      gridLineWidth: 1,
      gridLineColor: '#f3f3f3',
      lineColor: '#f3f3f3',
      tickColor: '#f3f3f3',
      labels: {
        enabled: false,
        rotation: 0,
        style: {
          color: '#848484',
          fontSize: '14px'
        },
        x: 0,
        y: 30,
        reserveSpace: true,
      },
      tickPixelInterval: 20,
    })
    super.setRangeOptions({enabled: false});
    super.setNavigatorOptions({enabled: false})
    super.setScrollbarOptions({enabled: false});
    super.setYAxisLabelEnabled(true);
    super.setPopoverOptions({
      enabled: true,
      className: 'hide-header-tooltip-for-highstock',
      valueDecimals: 2,
      valueSuffix: this.valueSuffix,
    });

  }

  init() {
    return super.initChart('stock')
  }

  addLiveSeries(name: string, frameSize: number) {
    if (!this.chart) {
      return;
    }
    this.dropInternalCounter();
    this.setFrameSize(frameSize);

    this.pushDataSerie([]);

    this.chart.addSeries({
      boostThreshold: 125,
      gapSize: this.chartGap,
      data: [],
      type: 'spline',
      color: '#42a3ed',
      name,
      animation: false,
      dataGrouping: {
        enabled: false
      },
    }, true, false);
  }

  updatePoint(x: number, y: number) {
    if (!this.chart) {
      return;
    }
    // Save counter
    this.internalCounter = x;

    // Saving index of the latest point
    const latestIndex = this.frameSize - this.chartGap - 1;

    // Add point to the data array. Set marker to highlight last point
    this.chart.series[0].addPoint({
      x, y, marker: {radius: 4, enabled: true}
    }, false, false, false, false);
    this.data[0].push({x, y});

    // Are we reaching the end?
    if (this.frameSize - x <= this.chartGap) {
      // Remove marker from previous point.
      // We need to point the same index, as we are adding and removing buttons, so array length is the same
      this.chart.series[0].data[latestIndex].update(this.disabledMarkerOption, false, false)
      // Then start removing points from the beginning.
      this.chart.series[0].removePoint(x - this.frameSize + this.chartGap, false, false);
      this.data[0].splice(x - this.frameSize + this.chartGap, 1);
    } else {
      // If not, and we have points from previous data set, we removing it
      if (this.chart.series[0].data[x + 1]) {
        this.chart.series[0].removePoint(x + 1, false, false);
        this.data[0].splice(x + 1, 1);
      }
      // If we start new data set from start, we need to remove marker from very last point
      // Since we removed previous points, we need to calculate index with gap size taken into account
      if (x === 0 && this.chart.series[0].data[latestIndex]) {
        this.chart.series[0].data[latestIndex].update(this.disabledMarkerOption, false, false)
      } else if (x > 0) {
        // Simply remove marker from previous point if x > 0
        this.chart.series[0].data[x - 1].update(this.disabledMarkerOption, false, false)
      }
    }
    this.dataUpdated.emit(this.data);
    this.chart.redraw(false);
  }

  setFrameSize(frameSize: number) {
    if (!this.chart) {
      return;
    }
    this.frameSize = frameSize - 1;
    this.chart.xAxis[0].update({
      max: this.frameSize
    });
  }

  updateNextPoint(value: number) {
    this.updatePoint(this.internalCounter, value);
    this.internalCounter++;
    if (this.internalCounter > this.frameSize) {
      this.internalCounter = 0;
    }
  }

  dropInternalCounter() {
    this.internalCounter = 0;
  }
}
