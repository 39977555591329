import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { HttpWrapper, HttpWrapperArray } from 'src/app/store/model/http';
import { BillingModel, PaymentCardModel, PaymentCardPostModel } from '../models/payment.model';
import { BillingHistoryModel } from '../../store/model/payments.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getAllCards() {
    return this.http.get<HttpWrapperArray<PaymentCardModel>>(`${AppConstants.baseUrl}/cards/user/list`);
  }

  createCard(card: PaymentCardPostModel) {
    return this.http.post<HttpWrapper<PaymentCardModel>>(`${AppConstants.baseUrl}/cards/user/add`, card);
  }

  removeCard(cardId) {
    return this.http.delete<HttpWrapper<PaymentCardModel>>(`${AppConstants.baseUrl}/cards/user/delete/${cardId}`);
  }

  getAllBillings() {
    return this.http.get<HttpWrapperArray<BillingHistoryModel>>(`${AppConstants.baseUrl}/cards/user/transactions`);
  }

  setPrimary(id: string) {
    return this.http.post<HttpWrapper<PaymentCardModel>>(`${AppConstants.baseUrl}/cards/user/set_default`, { card_id: id });
  }
}
