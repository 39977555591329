import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeLeft'
})
export class TimeLeftPipe implements PipeTransform {

  transform(eventTime: string | number): string {
    const convertedEventTime = typeof eventTime === 'number' ?
      eventTime : new Date(eventTime).getTime();

    const timeNow = moment(Date.now());
    const duration = moment.duration(moment(convertedEventTime).diff(timeNow));

    const days = parseInt(duration.asDays().toString());
    const hours = parseInt((duration.asHours() % 24).toString());
    const minutes = parseInt((duration.asMinutes() % 60).toString());
    const seconds = parseInt((duration.asSeconds() % 60).toString());

    let result = minutes + ' minutes';
    if(hours) result = hours + ' hours, ' + result;
    if(days) result = days + ' days, ' + result;
    if(!days && !hours && !minutes) result = seconds + ' seconds';

    return result;
  }
}
