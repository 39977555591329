import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'atk-onboarding-children',
  templateUrl: './onboarding-children.component.html',
  styleUrls: ['./onboarding-children.component.scss']
})
export class OnboardingChildrenComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
