import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export class DateValidtors {
  public static dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateAray = control.value.split('/');
      if (dateAray.length !== 2) {
        return { invalidDate: { value: 'date' } };
      } else {
        if (isNaN(dateAray[0]) || isNaN(dateAray[1])) {
          return { invalidDate: { value: 'date' } };
        } else if (new Date(+dateAray[1] + 2000, +dateAray[0], new Date().getDay()).getTime() > new Date().getTime()) {
          return null;
        }
        return { invalidDate: { value: 'date' } };
      }
    };
  }
  public static dateTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = moment(control.value);
      const now = moment().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      if (date.diff(now) < 0) {
        return { invalidDate: { value: 'date' } };
      } else {
        return null;
      }
    };
  }
}

