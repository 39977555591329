import { InvitationCodeRoute } from '../invitation-code/invitation-code.route';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import * as fromReducers from 'src/app/store/reducers';
import * as fromSelectors from 'src/app/features/user/store/selectors';
import {first, map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationName} from '../../../../../features/user/store/actions';
import { IInviteInfo } from 'src/app/features/user/store/models/abstract-user.model';
import {UserService} from '../../../../../features/user/user.service';

@Component({
  selector: 'atk-name',
  templateUrl: './name.route.html',
  styleUrls: ['./name.route.scss']
})
export class NameRoute implements OnInit {

  firstName: string;
  lastName: string;
  birthday: moment.Moment;
  gendersList: string[] = ['Female', 'Male', 'Other'];
  gender: string;
  acceptedTerms = false;
  public inviteInfo: IInviteInfo = null;

  constructor(
    private store: Store<fromReducers.State>,
    private router: Router,
    private readonly cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    const inviteCode = this.activatedRoute.snapshot.queryParams.id;
    if (inviteCode) {
      this.checkInvitationCode(inviteCode);
    }
    this.store.pipe(select(fromSelectors.registrationFlow), first()).subscribe(next => {
      this.firstName = next.firstName;
      this.lastName = next.lastName;
      this.birthday = moment(next.birthday);
      this.gender = next.gender;
      this.acceptedTerms = next.acceptedTerms;
    });
  }

  signUpStartClick() {
    const params = {
      firstName: this.firstName,
      lastName: this.lastName,
      birthday: this.birthday.toISOString(true),
      gender: this.gender,
      acceptedTerms: this.acceptedTerms
    };
    if (this.inviteInfo) {
      params['invite_id'] = this.inviteInfo.id;
    }
    if (this.inviteInfo) {
      params['invite_info'] = this.inviteInfo;
    }
    this.store.dispatch(new RegistrationName(params));
    this.router.navigate(['pin'], {relativeTo: this.route}).then();
  }

  public checkDisable() {
    if ((this.firstName && this.lastName && this.gender) === '' || !this.acceptedTerms || this.birthday.toISOString() === null) {
      return true;
    } else {
      return false;
    }
  }

  public openInvintationCodeModal(): void {
    const dialogRef = this.dialog.open(InvitationCodeRoute);
    dialogRef.afterClosed().subscribe((info:IInviteInfo) => info ? this.prepareDataToForm(info) : null);
  }

  private prepareDataToForm(info:IInviteInfo): void {
    this.inviteInfo = info;
    this.firstName = info.first_name;
    this.lastName = info.last_name;
    this.cd.detectChanges();
  }

  private checkInvitationCode(code: string) {
    const params = { code, role_id: 1 };
    this.userService.checkInvintationCode(params)
      .pipe(map((result: any) => result.data.invite_info)).subscribe((result:IInviteInfo) => {
        if (result && !result.user_id) {
          this.prepareDataToForm(result);
        }
      }
    )
  }
}
