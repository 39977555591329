import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {Auth, Amplify} from 'aws-amplify';
import {AWSIoTProvider} from '@aws-amplify/pubsub';
import {AppConstants} from './app/app.constants';

if (environment.production) {
  enableProdMode();
}

Amplify.configure(AppConstants.awsConfig);
Auth.configure(AppConstants.awsConfig);

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: AppConstants.awsRegion,
  aws_pubsub_endpoint: `wss://${AppConstants.awsMqttId}.iot.${AppConstants.awsRegion}.amazonaws.com/mqtt`,
}));
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
