import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import * as fromReducers from './store/reducers';
import {ClinicsEffects} from './store/effects';
import { ChooseClinicComponent } from './choose-clinic/choose-clinic.component';
import {SharedModule} from '../../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {InlineSVGModule} from 'ng-inline-svg';
import {MatRippleModule} from '@angular/material/core';


@NgModule({
  declarations: [ChooseClinicComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducers.clinicsFeatureKey, fromReducers.reducer),
    EffectsModule.forFeature([ClinicsEffects]),
    SharedModule,
    MatButtonModule,
    InlineSVGModule,
    MatRippleModule
  ],
  exports: [
    ChooseClinicComponent
  ]
})
export class ClinicsModule { }
