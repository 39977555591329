import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class TimeChartBuilder extends ChartsBuilder {

  public static chartName = 'Time';
  
  public static axisNames: string[] = ['Time'];

  constructor(element: ElementRef) {
    super(element);
    super.yAxisTickInterval = 0.01;
    super.yLabelFormatter = (input: string) => {
      return input;
    }
  }

  init() {
    return super.initChart('stock');
  }
}
