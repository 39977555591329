import {Action} from '@ngrx/store';

export enum CallActionTypes {
  InitCall = '[Call component] Initialize call',
  InitCallSuccess = '[Call API] Initialize call success',
  InitCallFailure = '[Call API] Initialize call failure',

  EndCall = '[Call component] Finish call',
  EndCallSuccess = '[Call API] Finish call success',
  EndCallFailure = '[Call API] Finish call failure',

  CancelCall = '[Call component] Dismiss call',
  CancelCallSuccess = '[Call API] Dismiss call success',
  CancelCallFailure = '[Call API] Dismiss call failure',

  SetCallRoomId = '[Call] Set call roomId'
}

export class InitCall implements Action {
  readonly type = CallActionTypes.InitCall;

  constructor(public payload: {
    callerId: string,
    calleeId: string,
    videoCall: boolean,
    mediaStream: MediaStream
  }) {
  }
}

export class InitCallSuccess implements Action {
  readonly type = CallActionTypes.InitCallSuccess;

  constructor(public payload: {
    roomId: string,
    isVideo: boolean,
    mediaStream: MediaStream
  }) {
  }
}


export class InitCallFailure implements Action {
  readonly type = CallActionTypes.InitCallFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class EndCall implements Action {
  readonly type = CallActionTypes.EndCall;

  constructor(public payload: {
    roomId: string
  }) {
  }
}

export class EndCallSuccess implements Action {
  readonly type = CallActionTypes.EndCallSuccess;

  constructor(public payload: {
    response: any
  }) {
  }
}

export class EndCallFailure implements Action {
  readonly type = CallActionTypes.EndCallFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}

export class CancelCall implements Action {
  readonly type = CallActionTypes.CancelCall;

  constructor(public payload: {
    roomId: string
  }) {
  }
}

export class CancelCallSuccess implements Action {
  readonly type = CallActionTypes.CancelCallSuccess;

  constructor(public payload: {
    response: any
  }) {
  }
}

export class CancelCallFailure implements Action {
  readonly type = CallActionTypes.CancelCallFailure;

  constructor(public payload: {
    error: any
  }) {
  }
}


export class SetCallRoomId implements Action {
  readonly type = CallActionTypes.SetCallRoomId;

  constructor(public payload: {
    roomId: string,
    isVideo: boolean,
    mediaStream: MediaStream
  }) {
  }
}


export type CallActions =
  InitCall |
  InitCallSuccess |
  InitCallFailure |
  CancelCall |
  CancelCallSuccess |
  CancelCallFailure |
  EndCall |
  EndCallSuccess |
  EndCallFailure |
  SetCallRoomId
  ;
