import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {interval} from 'rxjs';
import {ChatAndCallWebsocketService} from '../../chat-and-call/chat-and-call-websocket.service';
import {NotificationMessageModel} from '../store/models/notifications/notification-message.model';

@Component({
  selector: 'atk-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})

export class NotificationMessageComponent implements OnInit {

  @ViewChild('container', {static: true}) containerElement: ElementRef<HTMLDivElement>;

  @Input('notification') notification: NotificationMessageModel;
  @Output('close') closeEmitter = new EventEmitter<number>();
  @Output('markAsRead') markAsReadEmitter = new EventEmitter<number>();
  @Output('click') clickEmitter = new EventEmitter<MouseEvent>()
  opacity = 0;

  constructor(
    private chatWebsocketService: ChatAndCallWebsocketService
  ) {
  }

  ngOnInit() {
    if (this.notification.timeout) {
      const timeoutSubscription = interval(this.notification.timeout).subscribe(() => {
        timeoutSubscription.unsubscribe();
        this.close()

      })
    }
  }

  close() {
    this.closeEmitter.emit(this.notification.id);
  }

  markAsReadClick() {
    console.log(this.notification);
    this.chatWebsocketService.readMessage({
      room_id: this.notification.roomId,
      message_id: this.notification.messageId
    })
    this.markAsReadEmitter.emit(this.notification.id);
  }
}
