import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStore from 'src/app/features/user/store/reducers';

export const featureSelector = createFeatureSelector<fromStore.UserState>(fromStore.userFeatureKey);

export const userLoggedIn = createSelector(featureSelector, s => s.userLoggedIn);
export const loginError = createSelector(featureSelector, s => s.loginError);
export const userProfile = createSelector(featureSelector, s => s.user);

export const registrationFlow = createSelector(featureSelector, s => s.registrationFlow);
export const registrationSessionId = createSelector(featureSelector, s => s.registrationFlow.registrationSessionId);
export const registrationTelephoneConfirmed = createSelector(featureSelector, s => s.registrationFlow.telephoneConfirmed);
export const registrationSessionError = createSelector(featureSelector, s => s.registrationFlow.registrationError);

export const registrationSuccess = createSelector(featureSelector, s => s.registrationFlow.registrationSuccess);

export const userById = createSelector(featureSelector, s => s.userById);
export const usersByIds = createSelector(featureSelector, s => s.usersByIds);
export const userContacts = createSelector(featureSelector, s => s.userContacts);

export const questionnaireSkipLastDate = createSelector(featureSelector, s => s.questionnaireSkipLastDate);

