import {HighchartsChartModule} from 'highcharts-angular';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './component/header/header.component';
import {RouterModule} from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {UserFullNamePipe} from './pipe/user-full-name.pipe';
import {BreadcrumbsComponent} from './component/breadcrumbs/breadcrumbs.component';
import {CapitalizePipe} from './pipe/capitalize.pipe';
import {CheckboxComponent} from './component/checkbox/checkbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {AppotekDropdownComponent} from './component/dropdown/appotek-dropdown.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {PinComponent} from './component/pin/pin.component';
import {FormatAddressPipe} from './pipe/format-address.pipe';
import {TruncatePipe} from './pipe/truncate.pipe';
import {DurationPipe} from './pipe/duration.pipe';
import {FormatTimePipe} from './pipe/format-time.pipe';
import {SidebarComponent} from './component/sidebar/sidebar.component';
import {FormatDatetimePipe} from './pipe/format-datetime.pipe';
import {SchedulePipe} from './pipe/schedule.pipe';
import {PaginationModule} from '../ui/pagination/pagination.module';
import {AddCardComponent} from './component/add-card/add-card.component';
import {PaymentCardComponent} from './component/payment-card/payment-card.component';
import {TextMaskModule} from 'angular2-text-mask';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SuccessSnackbarComponent} from './modals/success-snackbar/success-snackbar.component';
import {ImageCropperPopupComponent} from './component/image-cropper/image-cropper.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DatepickerComponent} from './component/datepicker/datepicker.component';
import {HiddenEmailPipe} from './pipe/hidden-email.pipe';
import {HiddenPhonePipe} from './pipe/hidden-phone.pipe';
import {ChartComponent} from './component/chart/chart.component';
import {MatInputModule} from '@angular/material/input';
import {CalendarEventsPipe} from './pipe/calendar-events.pipe';
import {ShowTitlesFromArrayPipe} from './pipe/show-titles-from-array';
import {SearchHighlightPipe} from './pipe/search-highlight.pipe';
import {TimeHasComePipe} from './pipe/time-has-come.pipe';
import {TimeLeftPipe} from './pipe/time-left.pipe';
import {SafeImagePipe} from './pipe/safe-image.pipe';
import {PaginatorComponent} from './component/paginator/paginator.component';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {EcgLiveChartComponent} from './component/chart/ecg-live-chart/ecg-live-chart.component';

const components = [
  HeaderComponent,
  BreadcrumbsComponent,
  CheckboxComponent,
  AppotekDropdownComponent,
  PinComponent,
  SidebarComponent,
  AddCardComponent,
  PaymentCardComponent,
  ImageCropperPopupComponent,
  DatepickerComponent,
  ChartComponent,
  EcgLiveChartComponent
]
const pipes = [
  UserFullNamePipe,
  CapitalizePipe,
  FormatAddressPipe,
  TruncatePipe,
  DurationPipe,
  FormatTimePipe,
  FormatDatetimePipe,
  SchedulePipe,
  HiddenEmailPipe,
  HiddenPhonePipe,
  CalendarEventsPipe,
  ShowTitlesFromArrayPipe,
  SearchHighlightPipe,
  CalendarEventsPipe,
  TimeHasComePipe,
  TimeLeftPipe,
  SafeImagePipe
]

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    SuccessSnackbarComponent,
    PaginatorComponent,
  ],
  exports: [
    ...components,
    ...pipes,
    PaginatorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ImageCropperModule,
    MatButtonModule,
    InlineSVGModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatSnackBarModule,
    HighchartsChartModule,
    MatInputModule,
    PaginationModule,
    MatGoogleMapsAutocompleteModule
  ],
})
export class SharedModule { }
