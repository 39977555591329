import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotificationCancelledCallComponent} from './noitfication-cancelled-call/notification-cancelled-call.component';
import {NotificationMessageComponent} from './noitfication-message/notification-message.component';
import {NotificationSystemComponent} from './noitfication-system/notification-system.component';
import { NotificationsContainerComponent } from './notifications-container/notifications-container.component';
import { EffectsModule } from '@ngrx/effects';
import { NotificationsEffects } from './store/effects/notifications.effects';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {StoreModule} from '@ngrx/store';
import * as fromReducer from './store/reducers';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {InlineSVGModule} from 'ng-inline-svg';
import {SharedModule} from '../../shared/shared.module';



@NgModule({
  declarations: [
    NotificationSystemComponent,
    NotificationMessageComponent,
    NotificationCancelledCallComponent,
    NotificationsContainerComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducer.notificationsFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([NotificationsEffects]),
    MatIconModule,
    MatButtonModule,
    SweetAlert2Module,
    InlineSVGModule,
    SharedModule
  ],
  exports: [
    NotificationsContainerComponent
  ]
})
export class NotificationsModule { }
