import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStore from '../reducers/clinics.reducer';
import {clinicsFeatureKey} from '../reducers';

export const featureSelector = createFeatureSelector<fromStore.ClinicsState>(clinicsFeatureKey);

export const clinicsList = createSelector(featureSelector, s => s.clinicsList);
export const searchClinicsField = createSelector(featureSelector, s => s.searchClinicsField);
export const paginationClinics = createSelector(featureSelector, s => s.paginationConfig);
export const pharmaciesList = createSelector(featureSelector, s => s.pharmaciesList);

export const currentClinicLogo = createSelector(featureSelector, s => s.currentClinicLogo);
export const medicalSpecializations = createSelector(featureSelector, s => s.medicalSpecializations);

export const professionals = createSelector(featureSelector, s => s.professionals);
export const filterDoctors = createSelector(featureSelector, s => s.filterDoctors);


