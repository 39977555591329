import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'atk-call-settings-popup',
  templateUrl: './call-settings-popup.component.html',
  styleUrls: ['./call-settings-popup.component.scss'],
})
export class CallSettingsPopupComponent implements OnInit {

  micVolumeDots: { active: boolean; }[] = [
    {active: true},
    {active: true},
    {active: true},
    {active: true},
    {active: true},
    {active: true},
    {active: true},
    {active: false},
    {active: false},
    {active: false},
    {active: false},
    {active: false},
    {active: false},
  ];

  audioInput = '1';
  audioOutput = '1';
  videoInput = '1';

  audioInputSettings: {
    id: string,
    name: string,
  }[] = [{
    id: '1',
    name: 'External Microphone (test microphone setting)'
  }];

  audioOutputSettings: {
    id: string,
    name: string,
  }[] = [{
    id: '1',
    name: 'Headphones'
  }];

  videoInputSettings: {
    id: string,
    name: string,
  }[] = [{
    id: '1',
    name: 'FaceTime HD Camera'
  }];

  constructor() {
  }

  ngOnInit() {
  }

}
