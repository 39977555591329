import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpWrapperArray} from '../../store/model/http';
import {AppConstants} from '../../app.constants';
import {ClinicModel} from './store/models/clinic.model';
import {PharmacyModel} from './store/models/pharmacy.model';
import {TitleModel} from 'src/app/store/model/title.model';
import {AbstractUserModel} from '../user/store/models/abstract-user.model';
import {ProfessionalModel} from './store/models/professional.model';
import * as http from 'http';


@Injectable({
  providedIn: 'root'
})
export class ClinicsService {

  constructor(
    private api: HttpClient
  ) {
  }

  public loadClinics(params: {
    types?: number[],
    specializations?: number[],
    search?: string,
    page?: number,
    count?: number,
    country_iso3_code?: string
  }) {
    return this.api.post<HttpWrapperArray<ClinicModel>>(`${AppConstants.baseUrl}/clinics/list`, params);
  }

  public loadPharmacies(params: {
    id?: number,
    country_iso3_code?: string
    search?: string,
    page?: number,
    count?: number,
  }) {
    return this.api.post<HttpWrapperArray<PharmacyModel>>(`${AppConstants.baseUrl}/pharmacies/list`, params);
  }

  public addFavouriteClinic(params: {
    clinic_id: string[]
  }) {
    return this.api.post<HttpWrapperArray<any>>(`${AppConstants.baseUrl}/clinics/favourites/clinics`, params);
  }

  public addFavouritePharmacy(params: {
    pharmacy_id: number[]
  }) {
    return this.api.post<HttpWrapperArray<any>>(`${AppConstants.baseUrl}/clinics/favourites/pharmacies`, params);
  }

  getClinicsSpecializations() {
    return this.api.get<HttpWrapperArray<TitleModel>>(`${AppConstants.baseUrl}/clinics/specializations`,);
  }

  getClinicsDetails(clinicsId: string) {
    return this.api.get<HttpWrapperArray<any>>(`${AppConstants.baseUrl}/clinics/details/${clinicsId}`);
  }

  getProfessionals(params: {
    clinic_id: string;
    search?: string;
    page?: number;
    count?: number;
  }) {

    let httpParams = new HttpParams().append('clinic_id', params.clinic_id);
    if (params.search){
      httpParams = httpParams.append('search', params.search);
    }
    if (params.page){
      httpParams = httpParams.append('page', params.page.toString(10));
    }
    if (params.count){
      httpParams = httpParams.append('count', params.count.toString(10));
    }

    return this.api.get<HttpWrapperArray<ProfessionalModel>>(
      `${AppConstants.baseUrl}/clinics/professionals`, {
        params: httpParams
      });
  }


  getPatients(params: {
    clinic_id: string
  }) {
    return this.api.post<HttpWrapperArray<AbstractUserModel>>(`${AppConstants.baseUrl}/clinics/patients`, params);
  }

  getClinics(params: {
    types?: number[],
    specializations?: number[],
    search?: string,
    page: number,
    count: number,
    country_iso3_code?: string
  }) {
    return this.api.post<HttpWrapperArray<ClinicModel>>(`${AppConstants.baseUrl}/clinics/list`, params);
  }

  getMedicalSpecializations() {
    return this.api.get<HttpWrapperArray<TitleModel>>(`${AppConstants.baseUrl}/clinics/specializations`);
  }

  getFilterDoctorList(params) {
    return this.api.post<HttpWrapperArray<any>>(`${AppConstants.baseUrl}/journals/health_record/filters/doctors`, params);
  }

  getFilterClinicList(params) {
    return this.api.post<HttpWrapperArray<any>>(`${AppConstants.baseUrl}/journals/health_record/filters/clinics`, params);
  }
}
