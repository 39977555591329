import {Injectable} from '@angular/core';
import {Action, ActionType, Store} from '@ngrx/store';
import * as fromReducers from './store/reducers';
import * as fromActions from './store/actions';
import {Observable, of} from 'rxjs';
import {LoginNeeded, LoginNeededNew} from './store/actions';
import {HttpErrorResponse} from '@angular/common/http';
import {ActionCreator, NotAllowedCheck, TypedAction} from '@ngrx/store/src/models';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() {
  }

  /**
   * Handles unauthorized http status code and fires LoginNeeded action and passes action
   * and payload to repeat action. If there is no unauthorized error, callback will be called.
   * @param error
   * @param action action to repeat
   * @param payload Payload for action
   * @param callback called if there is no 401 error
   */
  catchUnauthorized<T, A>(
    error: HttpErrorResponse,
    action: { new(payload: any): A },
    payload: any,
    callback: () => Observable<T>): Observable<LoginNeeded> | Observable<T> {
    if (error.status === 401) {
      return of(new LoginNeeded({
        action,
        payload
      }));
    } else {
      return callback();
    }
  }

  catchUnauthorizedRequest<T extends string, P extends object, TC extends string, PC extends object>(
    error: HttpErrorResponse,
    action: ActionCreator<T, () => TypedAction<T>> | ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>,
    payload: P,
    defaultAction: TypedAction<TC> | PC & TypedAction<TC>
  ): Observable<LoginNeededNew<T, P>> |
    Observable<TypedAction<TC> | PC & TypedAction<TC>> {
    if (error.status === 401) {
      return of(new LoginNeededNew({
        action,
        payload
      }));
    } else {
      return of(defaultAction);
    }
  }
}
