import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[atkNumbersOnly]',
})
export class OnlyNumbersDirective implements OnInit, OnDestroy {
  @Input() set isTime(time) {
    if (time) {
      this.regValue = /[^0-9:]*/g;
    }
  };

  sub = new Subscription();
  regValue = /[^0-9]*/g;

  constructor(
    private ngControl: NgControl
  ) {}

  ngOnInit() {
    this.sub = this.ngControl.valueChanges.subscribe(value => {
      this.ngControl.control.setValue(
        (value || '').replace(this.regValue, ''),
        { emitEvent: false },
      )
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
