import {AbstractUserModel} from '../features/user/store/models/abstract-user.model';
import {AddressModel} from '../store/model/address.model';

export function getFullName(userModel: AbstractUserModel) {
  if (!userModel || !userModel.firstName || userModel.firstName.length === 0 || !userModel.lastName || userModel.lastName.length === 0) {
    return '';
  }
  // const lastName = userModel.lastName.length > 20 ? userModel.lastName.substr(0, 19) + '…' : userModel.lastName;
  return userModel.firstName + ' ' + userModel.lastName;
}


export function formatAddress(address: AddressModel) {
  const result = [];

  if (address.buildingNumber) {
    result.push(address.buildingNumber);
  } else if (address.building) {
    result.push(address.building);
  }
  result.push(address.street);
  if (address.apartment) {
    result.push('Apt. ' + address.apartment);
  }
  result.push(address.city);
  result.push(address.postal);
  result.push(address.country.name);

  return result.join(' ');
}

export function distinct(input: any[]) {
  return input.filter((value, index, self) => self.indexOf(value) === index);
}
