import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {UserModel} from '../../../user/store/models/user.model';
import {MatButton} from '@angular/material/button';
import {BehaviorSubject, Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {DefaultProjectorFn, select, Store} from '@ngrx/store';
import * as fromStoreSelectors from 'src/app/store/selectors';
import * as fromUserSelectors from 'src/app/features/user/store/selectors';
import * as fromCallSelectors from '../store/selectors/call.selectors';
import {concatMap, skip, switchMap, takeUntil} from 'rxjs/operators';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {Overlay} from '@angular/cdk/overlay';
import {ActivatedRoute} from '@angular/router';
import {DoctorModel} from '../../../../store/model/doctor.model';
import {TwilioCallService} from '../twilio.call.service';
import {CallApiService} from '../call-api.service';
import * as moment from 'moment';
import {Location} from '@angular/common';
import {WaitingRoomsService} from 'src/app/features/waiting-rooms/waiting-rooms.service';
import {IWaitingRoomExtended} from 'src/app/features/waiting-rooms/store/models/waiting-room.model';
import {FetchUserProfile} from 'src/app/features/user/store/actions';
import {NotificationsService} from 'src/app/features/notifications/notifications.service';

@Component({
  selector: 'atk-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('localVideo') localVideo: ElementRef;
  @ViewChild('localVideoBeforeCall', {static: false}) localVideoBeforeCall: ElementRef;
  @ViewChild('remoteVideo', {static: false}) remoteVideo: ElementRef;
  @ViewChild('testAudioStream', {static: false}) testAudioStreamRef: ElementRef;
  @ViewChild('settingsButton', {static: true}) settingsButtonRef: MatButton;


  @Input() mode: 'call' | 'appointment' = null;

  userProfile: UserModel;
  @Input() doctorProfile: DoctorModel = null;
  @Input() isVideo = false;
  @Output('callFinish') callFinishClickEmitter = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  // clinicsLogo: string;
  statusCall = 'idle';
  @Output('statusCall') statusCallEventEmitter = new EventEmitter<string | 'incoming' | 'started' | 'call' | 'idle'>();

  isScreenSharingAvailable = false;
  isScreenSharingEnabled = false;
  isCameraEnabled = false;
  isMicMute = false;

  callTimerString = '';
  showLocalVideo = false;
  inCall = false;
  callEnded = false;
  position: number;
  currentDate = moment(new Date());
  POLLING_INTERVAL = 3000;
  timerCtrl$ = new BehaviorSubject<number>(0);
  close$ = new ReplaySubject<any>(1);
  showCamera = false;
  public waitingRoomInfo: IWaitingRoomExtended;
  private localStream: MediaStream;
  userById: UserModel;


  constructor(
    private store: Store,
    private overlay: Overlay, private waitingRoomsService: WaitingRoomsService,
    private ref: ChangeDetectorRef,private notificationsService: NotificationsService,
    public route: ActivatedRoute, private location: Location,
    public callService: TwilioCallService,
    private callDatabase: CallApiService
  ) { }

  ngOnInit() {
    this.pipeStore(fromUserSelectors.userProfile).subscribe(userProfile => {
      this.userProfile = userProfile;
    });

    this.pipeStore(fromStoreSelectors.inCall).subscribe(inCall => {
      this.inCall = inCall;
    });

    // this.pipeStore(fromCallSelectors.callEnded).pipe(skip(1)).subscribe(callEnded => {
    //   if (callEnded) {
    //     this.callFinishClickEmitter.emit();
    //   }
    // });

    this.callService.callTimer$.pipe(takeUntil(this.destroy$)).subscribe(next => {
      this.callTimerString = next;
      if (this.callTimerString !== '') this.close$.next(0);
      this.ref.detectChanges();
    });

    this.callService.statusCall$.pipe(takeUntil(this.destroy$)).subscribe(next => {
      this.statusCall = next;
      this.statusCallEventEmitter.emit(next);
      this.ref.markForCheck();
    });


    this.callService.localStream$.pipe(takeUntil(this.destroy$)).subscribe(track => {
      if (track) {
        this.localVideo.nativeElement.appendChild(track.attach());
        this.localVideoBeforeCall.nativeElement.appendChild(track.attach());
        this.ref.markForCheck();
      }
    });

    this.callService.remoteStream$.pipe(takeUntil(this.destroy$)).subscribe(track => {
      if (track) {
        this.remoteVideo.nativeElement.appendChild(track.attach());
        this.ref.markForCheck();
      }
    });

    this.callService.showLocalVideo$.pipe(takeUntil(this.destroy$)).subscribe(showLocalVideo => {
      this.showLocalVideo = showLocalVideo;
      this.ref.markForCheck();
    });

    this.callService.isCameraEnabled$.pipe(takeUntil(this.destroy$)).subscribe(isCameraEnabled => {
      this.isCameraEnabled = isCameraEnabled;
      this.ref.markForCheck();
    });

    this.callService.isMicrophoneMuted$.pipe(takeUntil(this.destroy$)).subscribe(isMicMute => {
      this.isMicMute = isMicMute;
      this.ref.markForCheck();
    });

    this.callService.isScreenSharingEnabled$.pipe(takeUntil(this.destroy$)).subscribe(isScreenSharingEnabled => {
      this.isScreenSharingEnabled = isScreenSharingEnabled
      this.ref.markForCheck();
    });

    if (this.route.snapshot.queryParams.roomType === 'open queue' && !this.route.snapshot.queryParams.receive) {
      this.getWaitingRoomInfo(this.route.snapshot.queryParams.roomId);
      this.checkQueuePosition();
    }

    if (this.route.snapshot.queryParams.roomType === 'booking' || this.route.snapshot.queryParams.roomType === 'free') {
      this.showCamera = true;
      this.store.dispatch(new FetchUserProfile({userId: this.doctorProfile.id}));
      this.store.pipe(select(fromUserSelectors.userById)).subscribe(userById => {
        if (this.userProfile.id === userById.id) {
          this.getWaitingRoomInfo(this.route.snapshot.queryParams.roomId);
        } else {
          this.userById = userById;
        }
      });
    }

    this.notificationsService.ignoredCalls$.pipe(takeUntil(this.destroy$)).subscribe(ignoredCall => {
      // console.log('ignoredCall', ignoredCall);
        this.endCall();
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.doctorProfile && changes.doctorProfile.currentValue) {
      // this.clinicsLogo = (changes.doctorProfile.currentValue as DoctorModel).ac
    }
    // if (changes.isVideo && changes.isVideo.firstChange) {
    //   this.isCameraEnabled = changes.isVideo.currentValue;
    //   this.ref.markForCheck();
    //   // this.clinicsLogo = (changes.doctorProfile.currentValue as DoctorModel).ac
    // }
  }

  ngOnDestroy() {
    this.endCall();
    this.destroy$.next(true);
    this.destroy$.complete();
    this.close$.next(0);
  }

  private pipeStore<T>(selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>): Observable<T> {
    return this.store.pipe(select(selector), takeUntil(this.destroy$));
  }

  public startCall(videoCall: boolean) {
    this.callService.startCall(this.userProfile.id, this.doctorProfile.id, videoCall);
  }

  public endCall() {
    this.close$.next(0);
    this.callService.endCall();
    this.callFinishClickEmitter.emit();
  }


  dialButtonClick() {
    if (this.statusCall === 'started' || this.statusCall === 'call') {
      this.endCall();
    } else {
      this.startCall(this.isCameraEnabled || this.isScreenSharingEnabled);
    }
  }

  openChatClick() {
    // const currentUrl = new URL(location.href);
    // const roomId = this.currentRoom.id;
    // const interlocutorName = `${this.currentRoom.interlocutor.firstName} ${this.currentRoom.interlocutor.lastName}`;

    // const chatUrl = `${currentUrl.origin}/chat/standalone-chat/${this.doctorProfile.id}/${roomId}?standalone=true`;
    // const windowFeatures = [
    //   'width=475',
    //   'height=600',
    //   'menubar=0',
    //   'toolbar=0',
    //   'location=0',
    //   'status=0',
    //   'resizable=1',
    //   'scrollbars=0',
    // ];
    // window.open(chatUrl, `Chat with ${interlocutorName}`, windowFeatures.join(','));
  }

  changeSourceClick() {
    // this.startCallTimer();
    // this.playCallingSound();
  }


  screenSharingClick() {
    if (!this.isScreenSharingAvailable) {
      return;
    }
    this.callService.toggleScreenShare();
  }

  toggleCameraClick() {
    this.callService.toggleCamera();
    if (this.route.snapshot.queryParams.roomType === 'open queue' || this.route.snapshot.queryParams.roomType === 'booking') {
      // this.isCameraEnabled = !this.isCameraEnabled;
      // const mediaConstraints = {
      //   audio: true,
      //   video: true,
      // };
      // if (this.showLocalVideo) {
      //   this.StartBeforeCamera(mediaConstraints);
      // } else {
      //   this.localStream.getTracks().forEach(track => {
      //     track.stop();
      //     track.enabled = false;
      //   });
      //   this.localVideoBeforeCall.nativeElement.srcObject = null;
      // }
    }

  }

  private async StartBeforeCamera(mediaConstraints): Promise<void> {
    this.localStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    this.localVideoBeforeCall.nativeElement.srcObject = this.localStream;
    this.localVideoBeforeCall.nativeElement.muted = 'muted';
  }

  toggleMicClick() {
    this.callService.toggleMicrophone();
  }

  // openSettingsClick() {
  //
  //   const position = this.overlay.position()
  //     .flexibleConnectedTo(this.settingsButtonRef._elementRef)
  //     .withPositions([{
  //       originX: 'end',
  //       originY: 'bottom',
  //       overlayX: 'end',
  //       overlayY: 'top'
  //     }])
  //     .withDefaultOffsetX(20)
  //     .withDefaultOffsetY(-22);
  //   const overlayRef = this.overlay.create({
  //     hasBackdrop: true,
  //     backdropClass: ['transparent'],
  //     width: 280,
  //     height: 320,
  //     panelClass: ['default-panel'],
  //     positionStrategy: position,
  //     scrollStrategy: this.overlay.scrollStrategies.close(),
  //
  //   });
  //   const componentPortal = new ComponentPortal(CallSettingsPopupComponent);
  //
  //   overlayRef.attach(componentPortal);
  //   overlayRef.backdropClick().subscribe(() => {
  //     overlayRef.detach();
  //     overlayRef.dispose();
  //   });
  // }
  //

  private getWaitingRoomInfo(waitingRoomId): void {
    const params = {
      waiting_room_ids: [waitingRoomId]
    };
    this.waitingRoomsService.getWaitingRoomsByIds(params)
    .pipe(takeUntil(this.destroy$))
    .subscribe(result => {
      this.waitingRoomInfo =  result.data[0];
      this.ref.detectChanges();
    });
  }

  private checkQueuePosition(): void {
    this.isCameraEnabled = true;
    this.isCameraEnabled = !this.isCameraEnabled;
    this.timerCtrl$.asObservable().pipe(
      switchMap((time: number) =>
        timer(time, this.POLLING_INTERVAL).pipe(
          concatMap(() => this.callDatabase
          .checkPosition(
            this.route.snapshot.queryParams.roomId
              ? +this.route.snapshot.queryParams.roomId
              : +this.route.snapshot.queryParams.receive)),
        )), takeUntil(this.close$)).subscribe({
        next: (PositionResult) => {
          this.position = PositionResult['data'].position;
          this.showCamera = true;
          this.ref.detectChanges();
        if (PositionResult['data'].position === 1) {
          this.close$.next(0);
          this.statusCall = 'started';
          this.ref.detectChanges();
        }
      }, error: () => {
        this.endCall();
        location.href = 'get-help/booking';
      }
    });
  }

  public closeConsultation() {
    this.close$.next(0);
    if (this.statusCall === 'started') {
      this.endCall();
    } else {
      this.location.back();
    }
  }

}
