import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';

import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'atk-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperPopupComponent implements OnInit {

  @ViewChild(ImageCropperComponent, { static: true })
  private imageCropperComponent: ImageCropperComponent;

  imageChangedEvent: Event;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data,
    private readonly dialogRef: MatDialogRef<ImageCropperPopupComponent>
  ) { }

  ngOnInit() {
    this.imageChangedEvent = this.data.event;
  }

  fileChanged(event) {
    this.imageChangedEvent = event;
    const target = (event.target as HTMLInputElement);
    if (target.files && target.files.length) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
    }
  }

  public cropImage(): void {
    const event: ImageCroppedEvent = this.imageCropperComponent.crop();
    this.dialogRef.close(event.base64);
  }

  public get validateImageFormat(): string {
    return this.data.event.target.value.includes('png') ? 'png' : 'jpeg';
  }

}
