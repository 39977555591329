import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {asyncScheduler, combineLatest, of, scheduled} from 'rxjs';
import {AuthTokenChanged, StartHeartbeat, StateActionTypes, StopHeartbeat} from '../../../../store/actions';
import {catchError, concatMap, exhaustMap, finalize, flatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {
  FetchContacts, FetchContactsFailure, FetchContactsSuccess,
  FetchUserProfile,
  FetchUserProfileFailure,
  FetchUserProfileSuccess, FetchUsersByIds, FetchUsersByIdsFailure, FetchUsersByIdsSuccess,
  LoadUserProfile,
  LoadUserProfileFailure,
  LoadUserProfileSuccess,
  LoginUser,
  LoginUserFailure,
  LoginUserSuccess, LogoutUser, LogoutUserSuccess, RegistrationFinalizeSession,
  RegistrationFinalizeSessionFailure, RegistrationFinalizeSessionSuccess,
  RegistrationInitSession,
  RegistrationInitSessionFailure,
  RegistrationInitSessionSuccess,
  SendPhoneConfirmationCode,
  SendPhoneConfirmationCodeFailure,
  SendPhoneConfirmationCodeSuccess, UserActionTypes
} from '../actions';
import {UserService} from '../../user.service';
import {AppService} from '../../../../app.service';
import {select, Store} from '@ngrx/store';

import * as fromRootStore from 'src/app/store/reducers'
import * as fromRootSelectors from 'src/app/store/selectors'
import {lastHeartbeat} from 'src/app/store/selectors';
import {AppConstants} from '../../../../app.constants';
import {Router} from '@angular/router';

@Injectable()
export class UserEffects {

  $init = createEffect(() =>
    this.store.pipe(
      select(fromRootSelectors.lastHeartbeat),
      switchMap(lastHeartbeat => {
        if (moment().valueOf() - lastHeartbeat > AppConstants.INACTIVE_INTERVAL) {
          return scheduled([new LogoutUser()], asyncScheduler)
        } else {
          return scheduled([new LoadUserProfile()], asyncScheduler)
        }
      })
    )
  )

  loginUser$ = createEffect(() => this.actions$.pipe(
    ofType<LoginUser>(UserActionTypes.LoginUser),
    map(action => action.payload),
    exhaustMap(payload =>
      this.userService.login({
        appType: 'patient',
        role: 'patient',
        deviceId: '1010',
        login: payload.login,
        password: payload.password,
      }).pipe(
        concatMap(response => {
          return [
            new LoginUserSuccess({
              ...response.data
            }),
            new StartHeartbeat()
          ]
        }),

        catchError((error: HttpErrorResponse) => of(new LoginUserFailure(error.error)))
      ))
  ));

  logoutUser$ = createEffect(() => this.actions$.pipe(
    ofType<LogoutUser>(UserActionTypes.LogoutUser),
    exhaustMap(payload =>
      this.userService.logout().pipe(
        catchError((error: HttpErrorResponse, input) => {
          sessionStorage.removeItem('auth');
          return of([
            new StopHeartbeat(),
            new LogoutUserSuccess()
          ]);
        }),
        concatMap(() => {
          sessionStorage.removeItem('auth');
          return [
            new StopHeartbeat(),
            new LogoutUserSuccess()
          ]
        })
      )
    ),
    tap(() => this.router.navigate(['user', 'login']).then())
  ));


  userLoggedIn$ = createEffect(() => this.actions$.pipe(
    ofType<LoginUserSuccess>(UserActionTypes.LoginUserSuccess),
    map(action => action.payload),
    exhaustMap(payload => {
      sessionStorage.setItem('auth', payload.access)
      return of(new AuthTokenChanged({token: payload.access}));
    })
  ));


  getUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserProfile>(UserActionTypes.LoadUserProfile),
    exhaustMap(() =>
      this.userService.userProfile().pipe(
        mergeMap(response => [
          new LoadUserProfileSuccess({user: response.data}),
          new StartHeartbeat()
        ]),
        catchError((error: HttpErrorResponse) => of(new LoadUserProfileFailure(error.error))),
      )
    )
  ));


  startRegistrationSession$ = createEffect(() => this.actions$.pipe(
    ofType<RegistrationInitSession>(UserActionTypes.RegistrationInitSession),
    map(action => action.payload),
    exhaustMap(payload =>
      this.userService.initRegistrationSession(payload).pipe(
        map(response => new RegistrationInitSessionSuccess({
          ...response.data,
          telephone: payload.telephone
        })),
        catchError((error: HttpErrorResponse) => of(new RegistrationInitSessionFailure({error: error.error})))
      ))
  ));


  sendPhoneConfirmationCode$ = createEffect(() => this.actions$.pipe(
    ofType<SendPhoneConfirmationCode>(UserActionTypes.SendPhoneConfirmationCode),
    map(action => action.payload),
    exhaustMap(payload =>
      this.userService.checkPhoneRegistration({
        code: payload.code,
        session_id: payload.sessionId
      }).pipe(
        map(() => new SendPhoneConfirmationCodeSuccess()),
        catchError((ignored: HttpErrorResponse) => of(new SendPhoneConfirmationCodeFailure()))
        // catchError((error: HttpErrorResponse) => of(new SendPhoneConfirmationCodeSuccess()))
      ))
  ));


  registrationFinalize$ = createEffect(() => this.actions$.pipe(
    ofType<RegistrationFinalizeSession>(UserActionTypes.RegistrationFinalizeSession),
    map(action => action.payload),
    exhaustMap(payload => this.userService.finalizeRegistrationSession({
      firstName: payload.firstName,
      lastName: payload.lastName,
      gender: payload.gender.toLowerCase(),
      dob: moment(payload.birthday).format('yyyy-MM-DD'),
      enableBiometricSecurity: false,
      password: payload.pin,
      sId: payload.registrationSessionId,
      code: parseInt(payload.telephoneConfirmationCode, 10),
      deviceId: '1010',
      appType: 'patient'
    }).pipe(
      mergeMap(response => {
        sessionStorage.setItem('auth', response.data.access);
        return [
          new AuthTokenChanged({
            token: response.data.access,
          }),
          new RegistrationFinalizeSessionSuccess({
            user: response.data
          })
        ]
      }),
      catchError((error: HttpErrorResponse) => of(new RegistrationFinalizeSessionFailure({error: error.error})))
    ))
  ));


  getUserById$ = createEffect(() => this.actions$.pipe(
    ofType<FetchUserProfile>(UserActionTypes.FetchUserProfile),
    map(action => action.payload),
    exhaustMap(payload => this.userService.fetchUserById({
      userId: payload.userId
    }).pipe(
      map(response => new FetchUserProfileSuccess({user: response.data})),
      catchError((error: HttpErrorResponse) =>
        this.appService.catchUnauthorized(
          error,
          FetchUserProfile,
          payload,
          () => of(new FetchUserProfileFailure({error: error.error}))
        )
      )
    ))
  ));


  getUserByIds$ = createEffect(() => this.actions$.pipe(
    ofType<FetchUsersByIds>(UserActionTypes.FetchUsersByIds),
    map(action => action.payload),
    exhaustMap(payload => this.userService.fetchUserByIds(payload).pipe(
      map(response => new FetchUsersByIdsSuccess({users: response.data})),
      catchError((error: HttpErrorResponse) =>
        this.appService.catchUnauthorized(
          error,
          FetchUsersByIds,
          payload,
          () => of(new FetchUsersByIdsFailure({error: error.error}))
        )
      )
    ))
  ));


  getUserContacts$ = createEffect(() => this.actions$.pipe(
    ofType<FetchContacts>(UserActionTypes.FetchContacts),
    map(action => action.payload),
    exhaustMap(payload => this.userService.getContacts(payload).pipe(
      map(response => new FetchContactsSuccess({users: response.data})),
      catchError((error: HttpErrorResponse) =>
        this.appService.catchUnauthorized(
          error,
          FetchContacts,
          payload,
          () => of(new FetchContactsFailure({error: error.error}))
        )
      )
    ))
  ));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private appService: AppService,
    private store: Store<fromRootStore.State>,
    private router: Router
  ) {
  }

}
