import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {interval} from 'rxjs';
import {NotificationModel} from '../store/models/notifications/notification.model';

@Component({
  selector: 'atk-notification-cancelled-call',
  templateUrl: './notification-cancelled-call.component.html',
  styleUrls: ['./notification-cancelled-call.component.scss'],
})

export class NotificationCancelledCallComponent implements OnInit {

  @ViewChild('container', {static: true}) containerElement: ElementRef<HTMLDivElement>;

  @Input('notification') notification: NotificationModel;
  @Output('close') closeEmitter = new EventEmitter<number>();
  @Output('redial') redialEmitter = new EventEmitter<{
    notificationId: number,
    callType: 'audio' | 'video'
  }>();
  opacity = 0;

  constructor() {
  }

  ngOnInit() {
    if (this.notification.timeout) {
      const timeoutSubscription = interval(this.notification.timeout).subscribe(() => {
        timeoutSubscription.unsubscribe();
        this.close();
      })
    }
  }

  close() {
    this.closeEmitter.emit(this.notification.id);
  }


  redialAudioClick() {
    this.redialEmitter.emit({
      notificationId: this.notification.id,
      callType: 'audio'
    });
  }
  redialVideoClick() {
    this.redialEmitter.emit({
      notificationId: this.notification.id,
      callType: 'video'
    });
  }
}
