import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeHasCome'
})
export class TimeHasComePipe implements PipeTransform {

  transform(eventTime: string | number): boolean {
    const convertedEventTime = typeof eventTime === 'number' ?
      eventTime : new Date(eventTime).getTime();
    const timeNow = Date.now();
    return convertedEventTime >= timeNow;
  }

}
