import * as moment from 'moment';

export interface TimeFrame {
  weight: number;
  type: string;
  avgGroupGap: number; //milliseconds between datasets to group
  timeAroundDataPoint: number; //time in milliseconds around data point to show in graph
  timeUnit?: moment.unitOfTime.Diff;
}

export class TimeFrameImpl {
  private selectedTimeFrame: TimeFrame;
  private readonly timeFrames = {
    hour: HOUR_TIME_FRAME,
    day: DAY_TIME_FRAME,
    week: WEEK_TIME_FRAME,
    month: MONTH_TIME_FRAME,
    year: YEAR_TIME_FRAME
  }

  constructor(defaultTimeFrame: 'hour' | 'day' | 'week' | 'month' | 'year') {
    this.setTimeFrame(defaultTimeFrame);
  }

  get TimeFrame() {
    return this.selectedTimeFrame;
  }

  public setTimeFrame(timeFrame: string) {
    if (this.timeFrames.hasOwnProperty(timeFrame)) {
      this.selectedTimeFrame = this.timeFrames[timeFrame];
    } else {
      throw new Error('Unknown TimeFrame type')
    }
  }

  public setProperTimeFrame(startDate: moment.Moment, endDate: moment.Moment) {
    const properTimeFrame = this.getApproximateTimeFrame(startDate, endDate);
    if (!properTimeFrame) {
      throw new Error('End date is earlier than start date');
    }

    this.selectedTimeFrame = {
      weight: -1,
      type: 'custom',
      avgGroupGap: properTimeFrame.avgGroupGap,
      timeAroundDataPoint: properTimeFrame.timeAroundDataPoint
    }
  }

  public getZoomTimeFrame(zoomIn: boolean, startDate: moment.Moment, endDate: moment.Moment) {
    const sortedTimeFrames = Object.values(this.timeFrames)
      .sort((a, b) =>
        a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0
      );
    let currentTimeFrameIndex = sortedTimeFrames.findIndex(item => item.type === this.TimeFrame.type);
    if (currentTimeFrameIndex === -1){
      const approximateTimeFrame = this.getApproximateTimeFrame(startDate, endDate);
      currentTimeFrameIndex = sortedTimeFrames.findIndex(item => item.type === approximateTimeFrame.type);
    }
    if (currentTimeFrameIndex >= 0 && currentTimeFrameIndex < sortedTimeFrames.length) {
      return sortedTimeFrames[zoomIn ? currentTimeFrameIndex - 1 : currentTimeFrameIndex + 1];
    } else {
      return null
    }
  }

  private getApproximateTimeFrame(startDate: moment.Moment, endDate: moment.Moment) {
    const diffMilliseconds = endDate.diff(startDate, 'ms');
    const sortedTimeFrames = Object.values(this.timeFrames)
      .sort((a, b) =>
        a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0
      );

    let properTimeFrame: TimeFrame;
    for (let i = sortedTimeFrames.length - 1; i > 0; i--) {
      if (diffMilliseconds >= sortedTimeFrames[i].timeAroundDataPoint * 2) {
        properTimeFrame = sortedTimeFrames[i];
        break;
      }
    }
    return properTimeFrame;
  }
}

export const HOUR_TIME_FRAME: TimeFrame = {
  weight: 0,
  type: 'hour',
  avgGroupGap: 0, // 1 minute
  timeAroundDataPoint: 1800000, // half of an hour
  timeUnit: 'h',
}

export const DAY_TIME_FRAME: TimeFrame = {
  weight: 1,
  type: 'day',
  avgGroupGap: 3600000, // 1 hour
  timeAroundDataPoint: 43200000, // half of a day
  timeUnit: 'd',
}

export const WEEK_TIME_FRAME: TimeFrame = {
  weight: 2,
  type: 'week',
  avgGroupGap: 43200000, // half of a day
  timeAroundDataPoint: 302400000, // half of a week
  timeUnit: 'w',
}

export const MONTH_TIME_FRAME: TimeFrame = {
  weight: 3,
  type: 'month',
  avgGroupGap: 86400000, // 1 day
  timeAroundDataPoint: 1296000000, // half of a month
  timeUnit: 'M',
}

export const YEAR_TIME_FRAME: TimeFrame = {
  weight: 4,
  type: 'year',
  avgGroupGap: 604800000, // 7 days(week)
  timeAroundDataPoint: 15768000000, // half of a year
  timeUnit: 'y',
}
