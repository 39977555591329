import { ChartDataModel } from '../models/chart-data.model';
import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class HeartRateChartBuilder extends ChartsBuilder {

  public static chartName = 'Heart rate';
  public static measurementUnits = 'bpm';
  public static axisNames: string[] = ['Heart rate'];
  private readonly maxUserHeartRate: number

  constructor(element: ElementRef, userAge: number = 0) {
    super(element);
    this.maxUserHeartRate = 220 - userAge;
    super.yLabelFormatter = (input: string) => {
      return input + 'bpm';
    }
  }

  init() {
    return super.initChart('stock');
  }

  // addAllZones() {
  //
  //
  //   super.addZones({
  //     value: 40,
  //     color: '#ed4242'
  //   });
  //   super.addZones({
  //     value: 60,
  //     color: '#f2d273'
  //   });
  //   super.addZones({
  //     value: Math.ceil(this.maxUserHeartRate * 0.7),
  //     color: '#42a3ed'
  //   });
  //   super.addZones({
  //     value: Math.ceil(this.maxUserHeartRate * 0.85),
  //     color: '#f2d273'
  //   });
  //   super.addZones({
  //     color: '#ed4242'
  //   });
  // }

  addSeries(chartData: ChartDataModel[], name: string) {
    super.addSeries(chartData, name, 'spline',{
      interval: 60000,
    }, [
      {
        value: 40,
        color: '#ed4242'
      },
      {
        value: 60,
        color: '#f2d273'
      },
      {
        value: Math.ceil(this.maxUserHeartRate * 0.7),
        color: '#42a3ed'
      },
      {
        value: Math.ceil(this.maxUserHeartRate * 0.85),
        color: '#f2d273'
      },
      {
        color: '#ed4242'
      }
    ]);

  }

}
