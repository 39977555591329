import {Injectable} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {AppConstants} from '../app.constants';
import * as moment from 'moment';
import * as fromStore from 'src/app/store/reducers';
import * as fromStoreSelectors from 'src/app/store/selectors';
import {select, Store} from '@ngrx/store';
import {filter, first, map, take} from 'rxjs/operators';
import {lastHeartbeat} from 'src/app/store/selectors';
import {OpenSessionPopup} from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private heartbeatSub: Subscription;

  constructor(private store: Store<fromStore.State>) {

  }

  startHeartBeating() {
    this.heartbeatSub = interval(AppConstants.INACTIVE_CHECK_INTERVAL).subscribe(() => {
      this.store.pipe(map(store => store.lastHeartbeat), first()).subscribe(lastHeartbeat => {
        fromStoreSelectors.lastHeartbeat.release();
        if (moment().valueOf() - lastHeartbeat > AppConstants.INACTIVE_INTERVAL) {
          this.stopHeartBeating()
          this.store.dispatch(new OpenSessionPopup());
        }
      });
    });
  }

  stopHeartBeating() {
    if (this.heartbeatSub) {
      this.heartbeatSub.unsubscribe();
    }
  }

}
