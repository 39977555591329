import { createReducer, on } from '@ngrx/store';
import { PaymentCardModel } from 'src/app/core/models/payment.model';

import * as actions from '../actions';

export const paymentFeatureKey = 'payment';

export interface PaymentState {
  cardList: PaymentCardModel[]
}

export const initialPaymentState: PaymentState = {
  cardList: []
};

export const paymentReducer = createReducer(
  initialPaymentState,
  on(actions.getCardListSuccess, (state, {response}) => {
    return {...state, cardList: response}
  })
)
