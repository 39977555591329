import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class TemperatureChartBuilder extends ChartsBuilder {

  public static chartName = 'Temperature';
  public static measurementUnits = '°C';
  public static axisNames: string[] = ['Temperature'];

  constructor(element: ElementRef) {
    super(element);

    super.yAxisTickInterval = .1;

    super.addZones({
      value: 0,
      color: '#ed4242'
    });
    super.addZones({
      value: 32,
      color: '#f2d273'
    });
    super.addZones({
      value: 35,
      color: '#42a3ed'
    });
    super.addZones({
      value: 37,
      color: '#f2d273'
    });
    super.addZones({
      value: 38.5,
      color: '#ed4242'
    });

    super.yLabelFormatter = (input: string) => {
      return input + '°C';
    }
  }
  init() {
    return super.initChart('stock');
  }
}
