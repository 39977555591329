import {Action} from '@ngrx/store';
import {UserModel} from '../../../user/store/models/user.model';
import {NotificationMessageModel} from '../models/notifications/notification-message.model';
import {NotificationCancelledCallModel} from '../models/notifications/notification-cancelled-call.model';
import {NotificationSystemModel} from '../models/notifications/notification-system.model';

export enum NotificationsActionTypes {
  AddNotificationToQueue = '[NotificationQueue] Add Notification',
  RemoveNotificationFromQueue = '[NotificationQueue] Remove Notification from queue',

  SetShowIncomingCallPopup = '[Notification] Show call popup',
  SetShowIncomingCallAlert = '[Notification] Show alert popup',

}

export class AddNotificationToQueue implements Action {
  readonly type = NotificationsActionTypes.AddNotificationToQueue;

  constructor(public payload:
                NotificationCancelledCallModel |
                NotificationMessageModel |
                NotificationSystemModel
  ) {
  }
}

export class RemoveNotificationFromQueue implements Action {
  readonly type = NotificationsActionTypes.RemoveNotificationFromQueue;

  constructor(public payload: {
    messageId: number;
  }) {
  }
}

export class SetShowIncomingCallPopup implements Action {
  readonly type = NotificationsActionTypes.SetShowIncomingCallPopup;

  constructor(public payload: {
    showCallPopup: {
      show: boolean;
      user: UserModel,
      video: boolean,
      roomId: string
    }
  }) {
  }
}
export class SetShowIncomingCallAlert implements Action {
  readonly type = NotificationsActionTypes.SetShowIncomingCallAlert;
  constructor(public payload: {
    showCallAlerPopup: {
      show: boolean;
      user: UserModel,
      video: boolean,
      roomId: string,
      roomType: string,
      callerId: string
    }
  }) { }
}


export type NotificationsActions =
  AddNotificationToQueue |
  RemoveNotificationFromQueue |
  SetShowIncomingCallPopup |
  SetShowIncomingCallAlert
  ;
