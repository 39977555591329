import {ChartsBuilder} from './charts.builder';
import {ElementRef} from '@angular/core';

export class WeightChartBuilder extends ChartsBuilder {

  public static chartName = 'Weight';
  public static measurementUnits = 'kg';
  public static axisNames: string[] = ['Weight'];


  constructor(element: ElementRef) {
    super(element);
    super.yLabelFormatter = (input: string) => {
      return input + 'kg';
    }
  }

  init() {
    return super.initChart('stock');
  }
  
}
